$primary: #002d87;
$secondary: #0199e7;
$danger: #dd1015;
$success: #198754;

.lp_promotion_autumn {
  font-weight: 400;
  line-height: 1.3;

  .section-1 {
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 10px;

    .logo {
      max-width: 150px;
      width: 100%;
      height: auto;
    }

    @media only screen and (max-width: 768px) {
      .logo {
        max-width: 100px;
      }
    }
  }

  .section-2 {
    width: 100%;

    .section_bg {
      width: 100%;
      height: 680px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @media only screen and (max-width: 930px) {
        &.section_bg-desktop {
          display: none;
        }

        &.section_bg-mobile {
          display: block;
          height: 770px;
          max-width: 390px;
          padding: 10px 0 0;
        }
      }

      @media only screen and (min-width: 930px) {
        &.section_bg-desktop {
          display: block;
        }

        &.section_bg-mobile {
          display: none;
        }
      }
    }

    .section_container {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .content {
        color: #ffffff;
        max-width: 320px;
        width: 100%;

        .content_title {
          text-transform: uppercase;
          font-size: 40px;
          font-weight: 600;
        }

        .content_border {
          height: 2px;
          width: 50px;
          background-color: #ffffff;
          margin: 30px 0 20px;

        }

        .content_text {
          font-weight: 300;
        }
      }
    }

    @media only screen and (max-width: 930px) {
      display: flex;
      justify-content: center;
      background: linear-gradient(180deg,#253677 14%,#171c4d 63%);
      padding: 0 0 40px;
      .section_container {
        flex-direction: column;
        align-items: center;
        text-align: center;

        .content {
          .content_title {
            font-size: 30px;
          }

          .content_border {
            margin: 30px auto 20px;
            width: 70px;
          }
        }
      }
    }
  }

  .section-3 {
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 10px;

    .cards {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .card_s3 {
        max-width: 300px;
        width: 100%;
        font-weight: 400;
        text-align: center;

        margin: 10px;

        .card_icon {
          max-width: 100px;
          width: 100%;
          margin-bottom: 20px;
        }

        .card_title {
          font-size: 22px;
          font-weight: 600;
          color: $primary;
          margin-bottom: 30px;
        }

        .card_border {
          max-width: 50px;
          width: 100%;
          background-color: #cccccc;
          height: 3px;
          margin: 0 auto 20px;
        }

        .card_text {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .cards {
        flex-direction: column;
        align-items: center;

        .card_s3 {
          margin-bottom: 30px;

          .card_title {
            font-size: 20px;
          }

          .card_text {
            font-size: 12px;
          }
        }
      }
    }
  }

  .section-4 {
    padding: 50px 10px;
    background-color: #f3f3f3;

    .row_blocks {
      max-width: 1050px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .form_block {
        position: relative;

        .loader {
          display: none;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &.active {
            display: flex;
          }

          .lds-roller {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
          }

          .lds-roller div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 40px 40px;
          }

          .lds-roller div:after {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: $primary;
            margin: -4px 0 0 -4px;
          }

          .lds-roller div:nth-child(1) {
            animation-delay: -0.036s;
          }

          .lds-roller div:nth-child(1):after {
            top: 63px;
            left: 63px;
          }

          .lds-roller div:nth-child(2) {
            animation-delay: -0.072s;
          }

          .lds-roller div:nth-child(2):after {
            top: 68px;
            left: 56px;
          }

          .lds-roller div:nth-child(3) {
            animation-delay: -0.108s;
          }

          .lds-roller div:nth-child(3):after {
            top: 71px;
            left: 48px;
          }

          .lds-roller div:nth-child(4) {
            animation-delay: -0.144s;
          }

          .lds-roller div:nth-child(4):after {
            top: 72px;
            left: 40px;
          }

          .lds-roller div:nth-child(5) {
            animation-delay: -0.18s;
          }

          .lds-roller div:nth-child(5):after {
            top: 71px;
            left: 32px;
          }

          .lds-roller div:nth-child(6) {
            animation-delay: -0.216s;
          }

          .lds-roller div:nth-child(6):after {
            top: 68px;
            left: 24px;
          }

          .lds-roller div:nth-child(7) {
            animation-delay: -0.252s;
          }

          .lds-roller div:nth-child(7):after {
            top: 63px;
            left: 17px;
          }

          .lds-roller div:nth-child(8) {
            animation-delay: -0.288s;
          }

          .lds-roller div:nth-child(8):after {
            top: 56px;
            left: 12px;
          }

          @keyframes lds-roller {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .form_popup {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: #ffffff;
          font-size: 22px;
          padding: 20px;
          border: 2px solid $danger;
          color: $danger;
          border-radius: 15px;
          z-index: 99;
          display: none;

          &.success {
            border: 2px solid $success;
            color: $success;
          }
        }

        .form {
          max-width: 450px;
          width: 100%;
          position: relative;

          &.loader_active {
            filter: blur(8px);
          }

          .form_title {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 15px;
            color: $primary;
          }

          .form_text {
            font-size: 12px;
            color: #464b4f;
            max-width: 300px;
            margin-bottom: 25px;
            font-weight: 600;
            line-height: 1.1;

            &.form_text-margin {
              margin-bottom: 10px;
            }
          }

          .form_widget {
            position: relative;
            margin-bottom: 22px;
            max-width: 320px;
            width: auto;

            &.form_widget-margin {
              margin-bottom: 10px;
            }

            .form_label {
              color: #6f6f6f;
              font-size: 14px;
              position: absolute;
              top: 6px;
              left: 10px;
              transition: all 0.3s ease-in-out;

              &.label_active {
                left: 0;
                top: -17px;
                font-size: 12px;
                color: $primary;
              }

              .red_star {
                color: $danger;
              }
            }

            .form_label-checkbox {
              color: #464b4f;
              font-size: 12px;
              font-weight: 400;

              .red_star {
                color: $danger;
              }

              .label_link {
                color: $primary;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }

              .label_more_content {
                display: none;

                &.active {
                  display: inline;
                }
              }

              .label_more_btn {
                display: inline-block;
                cursor: pointer;
                color: $primary;
                transition: all 0.3s ease-in-out;

                &:hover {
                  color: $secondary;
                }
              }

              .label_text {
                margin-left: 22px;
                display: block;
              }
            }

            .form_input {
              box-sizing: border-box;
              background-color: transparent;
              outline: none;
              border: 1px solid #6f6f6f;
              border-radius: 5px;
              font-size: 14px;
              padding: 6px 10px;
              max-width: 300px;
              width: 100%;
              display: block;

              &.invalid {
                border-color: $danger;
                color: $danger;
              }

              &.valid {
                border-color: $success;
                color: $success;
              }
            }

            .form_checkbox {
              transform: scale(1.5);
              margin-right: 10px;
              visibility: visible !important;
            }
          }

          .form_footer {
            max-width: 320px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .form_footer-info {
              max-width: 150px;
              font-size: 12px;
              color: #464b4f;

              .red_star {
                color: $danger;
              }
            }

            .form_submit {
              cursor: pointer;
              border-radius: 25px;
              color: #fff;
              padding: 10px 20px;
              width: 160px;
              background-color: $danger;
              border: 3px solid $danger;
              transition: all 0.3s ease-in-out;
              font-size: 16px;
              font-weight: 600;
              text-decoration: none;

              &:hover {
                color: $danger;
                background-color: transparent;
              }
            }
          }

          .form-contact__info {
            display: none;
          }
        }
      }

      .content {
        text-align: center;
        color: #464b4f;

        .content_img {
          max-width: 70px;
          width: 100%;
          height: auto;
        }

        .content_title {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 15px;
        }

        .content_subtitle {
          font-size: 40px;
          font-weight: 700;

          a {
            color: $secondary;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: $primary;
            }
          }
        }

        .content_text {
          font-size: 12px;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .row_blocks {
        flex-direction: column;
        align-items: center;

        .form {
          max-width: 320px;

          .form_footer {
            flex-direction: column;

            .form_footer-info {
              max-width: 300px;
            }

            .form_submit {
              margin: 20px auto 40px;
            }
          }
        }
      }
    }
  }

  .section-5 {
    max-width: 1250px;
    width: 100%;
    padding: 50px 10px;
    margin: 0 auto;

    .row_section {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .section_img {
        display: block;
        max-width: 600px;
        width: 100%;
        height: auto;
      }

      .content {
        max-width: 500px;
        width: 100%;

        &.mobile {
          display: none;
        }

        .content_title {
          font-size: 36px;
          font-weight: 600;
          color: $primary;
          margin-bottom: 40px;
        }

        .content_list {
          .list_element {
            display: flex;
            margin-bottom: 30px;

            .list_element-num {
              color: $secondary;
              font-weight: 700;
              font-size: 22px;
              border: 3px solid $secondary;
              border-radius: 50%;
              min-width: 35px;
              width: 35px;
              max-width: 35px;
              min-height: 35px;
              height: 35px;
              max-height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
            }

            .list_element-text {
              max-width: 300px;
              width: 100%;
              font-size: 14px;
            }
          }
        }

        .content_link {
          border-radius: 10px;
          color: #fff;
          padding: 8px 20px;
          background-color: $danger;
          border: 3px solid $danger;
          transition: all 0.3s ease-in-out;
          margin-left: 55px;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;

          &:hover {
            color: $danger;
            background-color: #fff;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .row_section {
        flex-direction: column;
        align-items: center;

        .section_img {
          max-width: 400px;
        }

        .content {
          text-align: center;
          max-width: 400px;

          &.mobile {
            display: block;

            .content_title {
              margin: 0 auto;
              font-size: 28px;
              max-width: 300px;
            }
          }

          &.desktop {
            .content_title {
              display: none;
            }
          }

          .content_list {
            .list_element {
              flex-direction: column;
              align-items: center;

              .list_element-num {
                margin: 0 auto 15px;
                font-size: 28px;
                min-width: 50px;
                width: 50px;
                max-width: 50px;
                min-height: 50px;
                height: 50px;
                max-height: 50px;
              }

              .list_element-text {
                font-size: 14px;
              }
            }
          }

          .content_link {
            margin-left: 0;
          }
        }
      }
    }
  }

  .section-6 {
    padding: 0 10px;
    height: 820px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .section_position {
      position: absolute;
      left: 56%;
      transform: translateX(-50%);
      max-width: 650px;
      width: 100%;

      .section_title {
        font-size: 27px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        padding: 50px 0;
      }

      .content {
        margin: 50px 0 0;
        color: $primary;
        text-align: center;

        .content_img {
          display: none;
        }

        .content_title {
          font-size: 23px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .content_text {
          font-size: 17px;
          font-weight: 300;
          line-height: 1.4;
          margin-bottom: 40px;
        }

        .content_row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 40px;

          .element {
            max-width: 150px;
            width: 100%;
            margin: 5px;

            .element_img {
              max-width: 60px;
              width: 100%;
              height: auto;
            }

            .element_text {
              font-size: 14px;
              font-weight: 300;
              line-height: 1.2;
            }
          }
        }

        .content_link {
          border-radius: 15px;
          color: #fff;
          padding: 8px 40px;
          background-color: $danger;
          border: 3px solid $danger;
          transition: all 0.3s ease-in-out;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;

          &:hover {
            color: $danger;
            background-color: #fff;
          }
        }
      }
    }

    @media screen and (max-width: 1600px) {
      .section_position {
        left: 57%;
      }
    }

    @media screen and (max-width: 1400px) {
      .section_position {
        left: 58%;
      }
    }

    @media screen and (max-width: 1200px) {
      .section_position {
        left: 59%;
      }
    }

    @media screen and (max-width: 1200px) {
      .section_position {
        left: 61%;
      }
    }

    @media screen and (max-width: 980px) {
      height: auto;
      background: rgb(54,177,210);
      background: radial-gradient(circle, rgba(54,177,210,1) 47%, rgba(8,91,131,1) 99%) !important;
      padding: 30px 10px;

      .section_position {
        margin: 0 auto;
        position: initial;
        transform: none;

        .section_title {
          text-align: right;
          max-width: 400px;
          margin: 0 0 0 auto;
        }

        .content {
          background-color: #ffffff;
          margin: 0;
          padding: 40px;
          border-radius: 40px;
          position: relative;

          .content_img {
            display: block;
            position: absolute;
            top: -165px;
            left: -3px;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .section_position {

        .section_title {
          font-size: 24px;
          max-width: 340px;
        }

        .content {
          padding: 20px;

          .content_title {
            font-size: 18px;
          }

          .content_text {
            font-size: 15px;
            line-height: 1.2;
            margin-bottom: 20px;
          }

          .content_row {
            justify-content: center;
            flex-wrap: wrap;
            flex-basis: 50%;
          }
        }
      }
    }

    @media only screen and (max-width: 480px) {
      .section_position {
        .section_title {
          font-size: 18px;
          max-width: 220px;
          padding-bottom: 70px;
        }
      }
    }

    @media only screen and (max-width: 350px) {
      .section_position {
        .section_title {
          max-width: 180px;
          font-size: 16px;
          padding-bottom: 80px;
        }
      }
    }
  }

  .section-7 {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 10px;
    color: #6B666B;
    font-size: 12px;
    font-weight: 300;
  }

  .section-8 {
    background-color: #f8f8f8;
    padding: 20px 10px;
    width: 100%;

    .links {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .link {
        display: inline-block;
        color: #444a4e;
        text-decoration: none;
        font-size: 14px;
        transition: all 0.3s ease-in-out;

        &:not(:last-child) {
          margin-right: 40px;
        }

        &:hover {
          color: $primary;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .links {
        flex-direction: column;
        text-align: center;

        .link {
          &:not(:last-child) {
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
