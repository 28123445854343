.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto
}

.brick_table {
  font-size: 10px;
  color: #013087;
  font-weight: 400;

  .border {
    border-color: #d7e5fe !important;
  }

  .container {
    &:nth-child(odd) {
      background-color: #fbfcff;
    }
  }

  .table_header {
    font-size: 12px;
    div {
      background-color: #013088;
      color: #ffffff;
      font-weight: 700;
    }
  }

  .pimcore_tag_checkbox {
    label {
      display: flex!important;
    }
  }
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px
  }

  .brick_table {
    font-size: 12px;

    .table_header {
      font-size: 14px;
    }
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px
  }

  .brick_table {
    font-size: 14px;

    .table_header {
      font-size: 16px;
    }
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px
  }

  .brick_table {
    font-size: 16px;

    .table_header {
      font-size: 20px;
    }
  }
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1200px
  }

  .brick_table {
    font-size: 18px;

    .table_header {
      font-size: 22px;
    }
  }
.background-white {
  background-color: #ffffff;
  color:  #013088;
}

.background-lightblue {
  background-color: #dde9ff;
  color: #013088;
}

.value {
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
  padding: 10px;
  }

  .val {
    height: 3.2rem;
    padding: 12px;
  }

  .title {
    font-weight: bold;
    height: 2.8rem;
  }

  .p-1 {
    padding: 1px;
  }

  .p-5 {
    padding: 5px;
  }
}

