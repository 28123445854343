.navigation {
  .hamburger {
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 40px;
    display: none;

    @include below(lg) {
      display: block;
    }

    .line {
      background-color: $resolution-blue;
      border-radius: 5px;
      height: 2px;
      left: 9px;
      position: absolute;
      width: 22px;

      &:first-of-type {
        top: 11px;
        transition: transform $default-transition-time;
      }

      &:nth-of-type(2) {
        top: 18px;
      }

      &:last-of-type {
        top: 25px;
        transition: transform $default-transition-time;
      }
    }

    &.active {
      .line {
        &:first-of-type {
          top: 20px;
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          width: 0;
        }

        &:last-of-type {
          top: 20px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
