.public_information {
  margin: auto;

  &,& > * {
    box-sizing: border-box;
  }

  .public_information_header {
    margin-bottom: 60px;
    .public_information_header-title {
      font-weight: 900;
      color: #013088;
      font-size: 36px;
      margin-bottom: 20px;
    }

    .public_information_header-text {
      font-size: 18px;
      color: #4c4e51;
    }
  }

  .public_information_main {
    display: flex;

    .main_data {
      margin-left: 50px;
      .data {
        max-width: 350px;
        display: flex;

        .data_img {
          margin-right: 20px;
          width: 50px;
          .data_img-img {
            display: block;
            width: 40px;
            max-height: 40px;
            height: auto;
          }
        }

        .data_content {
          color: #4c4e51;

          .data_content-title {
            font-size: 24px;
            margin: 10px 0 15px;
            font-weight: 500;
          }

          .data_content-text {
            font-size: 16px;
            line-height: 1.3;
          }

          .data_content_links {
            margin-top: 10px;
            .data_content_links-link {
              color: #10a7e3;
              transition: all 0.3s ease-in-out;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: 20px;
          .data_content {
            padding: 0 20px 30px 0;
            border-bottom: 2px solid #e4e5e5;
          }
        }
      }
    }

    .main_info {
      margin-left: 200px;
      .info {
        max-width: 500px;
        font-size: 18px;
        border: 1px solid #14a3e6;
        padding: 15px 20px;
        border-radius: 15px;

        &:first-child {
          margin-top: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    &.public_information_main-editmode {
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 1000px) {
    .public_information_main {
      .main_data {
        .data {
          .data_content {
            .data_content-title {
              margin-top: 0;
            }
          }
        }
      }
      .main_info {
        margin-left: 100px;

        .info {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 20px auto;

    .public_information_main {
      flex-direction: column;
      .main_info {
        margin-top: 50px;
        margin-left: 50px;
      }
    }
  }

  @media only screen and (max-width: 550px) {

    .public_information_main {
      .main_info,
      .main_data {
        margin-left: 0;
      }
    }
  }
}