/* list_media */
b, strong {
    font-weight: bold !important;
}

@media only screen and (max-width: 567px) {
    .ms-xs-0 {
        margin-left: 0 !important;
    }

    .ms-xs-1 {
        margin-left: 0.25rem !important;
    }

    .ms-xs-2 {
        margin-left: 0.5rem !important;
    }

    .ms-xs-3 {
        margin-left: 1rem !important;
    }
    .me-xs-3 {
        margin-right: 1rem !important;
    }
    .mx-xs-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mb-xs-3 {
        margin-bottom: 1rem !important;
    }

    .ms-xs-4 {
        margin-left: 1.5rem !important;
    }

    .ms-xs-5 {
        margin-left: 3rem !important;
    }

    .flex-xs-column {
        flex-direction: column;
    }
}

/** {
    box-sizing: border-box;
}*/

/* The grid: Four equal columns that floats next to each other */
/*.column {*/
/*    float: left;*/
/*    width: 25%;*/
/*    padding: 10px;*/
/*}*/
/* Style the images inside the grid */
//.smal-icon-info {
//    opacity: 0.8;
//    cursor: pointer;
//}
//
//.smal-icon-info:hover {
//    opacity: 1;
//}

/* Clear floats after the columns */
/*.row:after {*/
/*    content: "";*/
/*    display: table;*/
/*    clear: both;*/
/*}*/
/* The expanding image container */
/* .containertest{*/
/*    position: relative;*/
/*    display: none;*/
/*}*/
/* Expanding image text */
#imgtext {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: white;
    font-size: 20px;
}

.media-name {
    color: #003087;
}

.department {
    font-size: 14px;
}

.name-replacement, .info-replacement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .name-replacement {
        border-right: 1px solid #e4e5e5;
    }
}

.replacement-all-info-bottom {
    border: 1px solid #e4e5e5;
}

@media only screen and (max-width: 576px) {
    .replacement-all-info-bottom {
        width: 95%;
    }
    .name-replacement {
        text-align: center !important;
    }
}

.name-replacement .replacement-department {
    font-size: 25px;
}

.info-replacement {
    font-size: 20px;
    font-weight: 500;
}

@media only screen and (max-width: 767px) {
    .info-replacement-contact {
        text-align: center;
    }
}

.title-media {
    color: #000;
    font-weight: 400;
}

.logos-media {
    border-top: 1px solid #005E80;
}

/* Closable button inside the expanded image */
.closebtn {
    position: absolute;
    top: 10px;
    right: 15px;
    color: white;
    font-size: 35px;
    cursor: pointer;
}
.container-img-big img#expandedImg {
    width: 348px;
    height: 348px;
}
@media only screen and (max-width: 991px) {
    .container-img-big img#expandedImg {
        width: 300px;
        height: 300px;
    }
}
@media only screen and (max-width: 576px) {
    .container-img-big img#expandedImg {
        width: 280px;
        height: 280px;
    }
}


/*----------------list business--------*/
.enea-blue {
    color: #003087;
}

.tabs-body img {
    max-height: 200px;
}

.articlebox-entry{
    font-weight:600;
}
.m-n1 {
    margin: -0.4rem;
}

.image-tab-img {
    height: 200px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 10px 10px 0 0;
}

.article-datebox-month, .article-datebox-year {
    font-weight: 300;
    color: #003087;
}

.article-box {
    height: 325px;
    background-color: #f2f2f2;
}
.my-rounded{
    border-radius: 10px!important;
}

.articlebox-descripion {
    display: none;
}

.articlebox-descripion a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.article-box:hover > .articlebox-descripion {
    display: block;
    width: 85%;

}

.article-box:hover > .image-tab-img {
    height: 150px;
    transition-duration: 700ms;


}

.articlebox-titles {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/*--------company_authorities----------------*/
.btn-read-more {
    color: #fff;
    background-color: #003087;
    border: 1px solid #003087;
    //box-shadow: 0 0 0 0.25rem rgb(0 48 135 / 0%);
    text-align: center;
    border-radius: 5px;
    min-width: 120px;
}

.btn-read-more:hover {
    color: #003087;
    background-color: #FFFFFF;
    border-color: #003087;
    text-decoration: none;
}

a.btn-read-more-a:hover, a.btn-read-more-a:visited {
    text-decoration: none;
}

/*------------------------*/
/*--------list----------------*/
.img-list-article{
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn{
    visibility: hidden;
}

/*------------------------*/