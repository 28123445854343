
.flex-wytyczne-dla-operatora  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 1rem auto;
}

.flex-wytyczne-dla-operatora .teksty {
  max-width: 100%;
}

div.flex-wytyczne-dla-operatora teksty p {
  margin-right: 3rem;
  text-align: justify;
  margin-bottom: 1rem;
}

.flex-wytyczne-dla-operatora.obrazek {
  max-width: 40%;
  text-align: center;
}

.tytul {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  color: #003087;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  h1{
    margin:0;
  }
}

.flex-wytyczne-dla-operatora a{
  color: #1c1c98;
  cursor: pointer;
}
.flex-wytyczne-dla-operatora a:hover{
  text-decoration: underline;
}

.flex-wytyczne-dla-operatora ul {
  list-style-type: '-';
  margin-top: 1.5rem;
  li {
    margin-bottom: 1.5rem;
    margin-right: 3rem;
    padding-left: 1rem;
    text-indent: -0.5rem;
    margin-left: 1rem;
  }
}

.wytyczne-dla-operatora-img {
  width: 100px;
  height: 100px;
  margin: 10px;
}

@media only screen and (max-width: 768px) {
  .wytyczne-dla-operatora-img {
    display:none;
  }
  .flex-wytyczne-dla-operatora {
    flex-direction: column;
  }

  .flex-wytyczne-dla-operatora h6{
    margin-left: 1rem;
  }

  .flex-wytyczne-dla-operatora .obrazek {
    display: none;
  }
  .flex-wytyczne-dla-operatora .teksty p {
    margin: auto 1rem;
    margin-bottom: 1.5rem;
  }
  .flex-wytyczne-dla-operatora .teksty {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .tytul {
    margin: 0 1rem 1rem 1rem
  }
  .flex-wytyczne-dla-operatora .teksty p {
    margin: auto 1rem;
    margin-bottom: 1.5rem;
  }
  .flex-wytyczne-dla-operatora h6{
    margin-left: 1rem;
  }
  .flex-wytyczne-dla-operatora .teksty {
    max-width: 100%;
  }
}
