input[type='checkbox'] {
  position: absolute;

  + label {
    &::before {
      content: $icon-checkbox-unchecked;
      font-family: 'Icomoon';
      cursor: pointer;
      display: inline-block;
      font-size: size(24, false);
      left: -1px;
      position: relative;
      top: -6px;
      margin-right: 10px;
      background-color: $white;
    }
  }

  &:checked {
    + label {
      &::before {
        content: $icon-checkbox-checked;
        font-family: 'Icomoon';
        color: $malibu;
      }
    }
  }
}
