.practical_tips {
  &,
  & > * {
    box-sizing: border-box;
  }

  .accordion_element {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #87d4f2;
    padding: 20px 0;

    .icon_element {
      display: flex;
      align-items: center;

      .plus {
        display: block;
      }

      .minus {
        display: none;
      }
    }

    .image_element {
      max-width: 80px;
      width: 100%;
      min-width: 80px;
      max-height: 80px;
      margin: 0 30px 0 10px;
    }

    .title_element {
      font-size: 2.25rem;
      line-height: 1.3;
      color: #161521;
      font-weight: 400;
    }

    &.active {
      border-color: #ffffff;

      .icon_element {
        .plus {
          display: none;
        }

        .minus {
          display: block;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .icon_element {
        img {
          max-width: 30px;
          max-height: 30px;
        }
      }

      .image_element {
        max-width: 60px;
        min-width: 60px;
        max-height: 60px;
        margin-right: 15px;
      }

      .title_element {
        font-size: 1.75rem;
      }
    }

    @media only screen and (max-width: 500px) {
      .image_element {
        max-width: 40px;
        min-width: 40px;
        max-height: 40px;
        margin-right: 10px;
        margin-left: 5px;
      }

      .title_element {
        font-size: 1.25rem;
      }
    }
  }

  .accordion_container {
    display: none;
    margin-left: 53px;

    &.active {
      display: block;
    }

    .accordion_question_element {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 15px 20px;
      border: 2px solid #87d4f2;
      border-radius: 15px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .icon_element {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .plus {
          display: block;
        }

        .minus {
          display: none;
        }
      }

      .title_element {
        font-size: 1.5rem;
        line-height: 1.2;
      }

      &.active {
        border-color: #f5f5f5;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

        .icon_element {
          .plus {
            display: none;
          }

          .minus {
            display: block;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        padding: 6px 10px;
        border-radius: 8px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .icon_element {
          img {
            max-width: 30px;
            max-height: 30px;
          }
        }

        .title_element {
          font-size: 1.25rem;
        }
      }

      @media only screen and (max-width: 500px) {
        padding: 5px;
        .title_element {
          font-size: 1rem;
        }
      }
    }

    .accordion_question_container {
      margin-left: 73px;
      margin-bottom: 20px;
      display: none;
      color: #343434;
      font-size: 1.25rem;
      line-height: 1.4;

      &.active {
        display: block;
      }

      @media only screen and (max-width: 768px) {
        margin-left: 53px;
        font-size: 1rem;
        margin-bottom: 12px;
      }

      @media only screen and (max-width: 500px) {
        margin-left: 48px;
        font-size: 0.85rem;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-left: 43px;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 33px;
    }
  }
}