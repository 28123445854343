@import "global";

.form-control {
    border: 1px solid  $white-text;
    background-color: $dark-contrast;
    color: $white-text;
    color-scheme: dark;
    &:focus{
        box-shadow: 0 0 0 0.25rem rgba(255,255,255,.5);
    }

}
form{
    label{
        color: $white-text;
    }

}
input[type=checkbox]+label:before{
    background-color: $contrast;
    border: 1px solid $white-text;
}