.cookies {
  align-items: center;
  display: none;
  font-size: size(14);
  font-weight: $light;
  background-color: $whisper;

  &-noconfirmed {
    display: block;
  }

  .cookies-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 12px;
  }

  .cookies-info {
    a {
      @include link($cerulean);
      @include link-underline();
    }
  }

  .cookies-action {
    text-align: right;
    cursor: pointer;
    min-width: 25vw;
    padding-left: 20px;
    position: relative;
    padding-right: 30px;
    @include link($black);
    &:hover {
      &::after {
        color: $jade;
      }
    }

    &::after {
      content: $icon-circle-success;
      font-family: 'Icomoon';
      color: $manatee;
      font-size: size(20);
      position: absolute;
      right: 0;
      top: -4px;
      transition: color $default-transition-time;
    }
  }
}

.contrast {
  .cookies {
    color: $white;
    background-color: $ebony-clay;
    .cookies-action {
      @include link($white);
      &::after {
        color: $white;
      }
      &:hover {
        &::after {
          color: $jade;
        }
      }
    }
  }
}
