#widget-3 {
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include box-shadow(4);
  border-radius: 16px;
  background-color: $white;
  position: relative;
  @include above(md) {
    height: 288px;
  }
  .text-part {
    position: relative;
    padding: 16px 24px 0 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    @include above(md) {
      padding: 16px 30px 0 30px;
    }
    @include above(lg) {
      padding: 16px 40px 0 40px;
    }
    .list-title {
      color: $resolution-blue;
      font-size: size(32, false);
      @include below(lg) {
        font-size: size(24);
      }
    }
    .list {
      display: inline-grid;
      align-items: center;
      margin-top: 0;
      width: 100%;
      @include above(md) {
        margin-top: 18px;
      }
      .list-item {
        position: relative;
        height: 100%;
        min-height: 38px;
        font-size: size(16, false);
        @include below(md) {
          min-height: 56px;
          font-size: size(16);
        }
        .list-link,
        .list-text {
          @include link($ebony-clay);
          @include link-underline();
          white-space: nowrap;
          font-weight: $regular;
          display: inline-block;
          align-items: center;
          @include below(md) {
            &:hover {
              background-size: 0;
            }
            display: flex;
            width: 100%;
            height: 100%;
          }
          .list-icon,
          .list-arrow {
            font-size: size(16, false);
            margin-right: 12px;
          }
          .list-arrow {
            transition: right $default-transition-time;
            position: absolute;
            right: 0;
            display: block;
            @include above(md) {
              display: none;
            }
          }
          &:hover {
            text-decoration: none;
            .list-arrow {
              right: 10px;
            }
          }
        }
        @include below(md) {
          &:not(:last-child) {
            &:after {
              content: '';
              position: absolute;
              background-color: $arc-light;
              width: 100%;
              height: 1px;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }

  .icon-container {
    background-color: $arc-light;
    position: static;
    @include between(md, xl) {
      display: none;
    }
    @include above(xl) {
      background-color: transparent;
      display: block;
      position: absolute;
      right: 42px;
      top: calc(50% - 20px);
    }
    .icon {
      display: block;
      color: $resolution-blue;
      font-size: size(246, false);
      text-align: right;
      @include below(md) {
        position: relative;
        right: -30px;
        bottom: -29px;
      }
      @include above(xl) {
        font-size: size(90, false);
        color: $arc-light;
      }
    }
  }
}

.contrast {
  #widget-3 {
    background-color: $ebony-clay;
    color: $white;
    .text-part {
      .list-title {
        color: $white;
      }
      .list {
        .list-item {
          .list-link {
            @include link($white);
          }
          @include below(md) {
            &:not(:last-child) {
              &:after {
                content: '';
                background-color: $black;
              }
            }
          }
        }
      }
    }
  }
}
