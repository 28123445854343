$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../fonts/IcoMoon' !default;

$icon-menu-thief: '\e920';
$icon-menu-standards: '\e921';
$icon-menu-socket-plug: '\e922';
$icon-menu-remit: '\e923';
$icon-menu-public-procurement: '\e924';
$icon-menu-press-office: '\e925';
$icon-menu-megaphone: '\e926';
$icon-menu-portal: '\e927';
$icon-menu-map-pin: '\e928';
$icon-menu-instructions: '\e929';
$icon-menu-info-circle: '\e92a';
$icon-menu-headphones: '\e92b';
$icon-menu-home-plug: '\e92c';
$icon-menu-hands: '\e92d';
$icon-menu-flash-lock: '\e92e';
$icon-menu-hands-money: '\e92f';
$icon-menu-eu-flag: '\e930';
$icon-menu-company-plug: '\e931';
$icon-menu-envelope: '\e932';
$icon-menu-checklist: '\e933';
$icon-menu-bulb: '\e934';
$icon-menu-back: '\e935';
$icon-menu-briefcase: '\e936';
$icon-menu-announcements: '\e937';
$icon-calendar: '\e938';
$icon-power-meter: '\e939';
$icon-eco: '\e93a';
$icon-tri-people: '\e93b';
$icon-circle-error: '\e91e';
$icon-circle-success: '\e91f';
$icon-checkbox-unchecked: '\e919';
$icon-radio-inactive: '\e91a';
$icon-radio-active: '\e91b';
$icon-checkbox-multi: '\e91c';
$icon-checkbox-checked: '\e91d';
$icon-label: '\e918';
$icon-arrows-circle: '\e900';
$icon-list: '\e901';
$icon-menu-instructions-plug: '\e902';
$icon-menu-question: '\e903';
$icon-menu-scale: '\e904';
$icon-menu-pricelist: '\e905';
$icon-menu-company: '\e906';
$icon-menu-house: '\e907';
$icon-youtube: '\e908';
$icon-transmitter: '\e909';
$icon-accessibility-print: '\e90a';
$icon-accessibility-contrast: '\e90b';
$icon-accessibility-font: '\e90c';
$icon-bip: '\e90d';
$icon-globe: '\e90e';
$icon-printer: '\e90f';
$icon-search: '\e910';
$icon-arrow-right: '\e911';
$icon-chevron-down: '\e912';
$icon-attention: '\e913';
$icon-question-mark: '\e914';
$icon-envelope: '\e915';
$icon-phone: '\e916';
$icon-flash: '\e917';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?uvs1gf');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?uvs1gf#iefix')
    format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?uvs1gf')
    format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?uvs1gf')
    format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?uvs1gf##{$icomoon-font-family}')
    format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-thief {
  &:before {
    content: $icon-menu-thief;
  }
}

.icon-menu-standards {
  &:before {
    content: $icon-menu-standards;
  }
}

.icon-menu-socket-plug {
  &:before {
    content: $icon-menu-socket-plug;
  }
}

.icon-menu-remit {
  &:before {
    content: $icon-menu-remit;
  }
}

.icon-menu-public-procurement {
  &:before {
    content: $icon-menu-public-procurement;
  }
}

.icon-menu-press-office {
  &:before {
    content: $icon-menu-press-office;
  }
}

.icon-menu-megaphone {
  &:before {
    content: $icon-menu-megaphone;
  }
}

.icon-menu-portal {
  &:before {
    content: $icon-menu-portal;
  }
}

.icon-menu-map-pin {
  &:before {
    content: $icon-menu-map-pin;
  }
}

.icon-menu-instructions {
  &:before {
    content: $icon-menu-instructions;
  }
}

.icon-menu-info-circle {
  &:before {
    content: $icon-menu-info-circle;
  }
}

.icon-menu-headphones {
  &:before {
    content: $icon-menu-headphones;
  }
}

.icon-menu-home-plug {
  &:before {
    content: $icon-menu-home-plug;
  }
}

.icon-menu-hands {
  &:before {
    content: $icon-menu-hands;
  }
}

.icon-menu-flash-lock {
  &:before {
    content: $icon-menu-flash-lock;
  }
}

.icon-menu-hands-money {
  &:before {
    content: $icon-menu-hands-money;
  }
}

.icon-menu-eu-flag {
  &:before {
    content: $icon-menu-eu-flag;
  }
}

.icon-menu-company-plug {
  &:before {
    content: $icon-menu-company-plug;
  }
}

.icon-menu-envelope {
  &:before {
    content: $icon-menu-envelope;
  }
}

.icon-menu-checklist {
  &:before {
    content: $icon-menu-checklist;
  }
}

.icon-menu-bulb {
  &:before {
    content: $icon-menu-bulb;
  }
}

.icon-menu-back {
  &:before {
    content: $icon-menu-back;
  }
}

.icon-menu-briefcase {
  &:before {
    content: $icon-menu-briefcase;
  }
}

.icon-menu-announcements {
  &:before {
    content: $icon-menu-announcements;
  }
}

.icon-power-meter {
  &:before {
    content: $icon-power-meter;
    font-size: larger;
  }
}

.icon-eco {
  &:before {
    content: $icon-eco;
  }
}

.icon-tri-people {
  &:before {
    content: $icon-tri-people;
    font-size: 25px;
  }
}

.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}

.icon-circle-error {
  &:before {
    content: $icon-circle-error;
    color: #e9283d;
  }
}

.icon-circle-success {
  &:before {
    content: $icon-circle-success;
    color: #6ec563;
  }
}

.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked;
  }
}

.icon-radio-inactive {
  &:before {
    content: $icon-radio-inactive;
  }
}

.icon-radio-active {
  &:before {
    content: $icon-radio-active;
    color: #669cff;
  }
}

.icon-checkbox-multi {
  &:before {
    content: $icon-checkbox-multi;
    color: #669cff;
  }
}

.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked;
    color: #669cff;
  }
}

.icon-label {
  &:before {
    content: $icon-label;
  }
}

.icon-arrows-circle {
  &:before {
    content: $icon-arrows-circle;
  }
}

.icon-list {
  &:before {
    content: $icon-list;
  }
}

.icon-menu-instructions-plug {
  &:before {
    content: $icon-menu-instructions-plug;
  }
}

.icon-menu-question {
  &:before {
    content: $icon-menu-question;
  }
}

.icon-menu-scale {
  &:before {
    content: $icon-menu-scale;
  }
}

.icon-menu-pricelist {
  &:before {
    content: $icon-menu-pricelist;
  }
}

.icon-menu-company {
  &:before {
    content: $icon-menu-company;
  }
}

.icon-menu-house {
  &:before {
    content: $icon-menu-house;
  }
}

.icon-youtube {
  &:before {
    content: $icon-youtube;
    color: #a7191b;
  }
}

.icon-transmitter {
  &:before {
    content: $icon-transmitter;
  }
}

.icon-accessibility-print {
  &:before {
    content: $icon-accessibility-print;
  }
}

.icon-accessibility-contrast {
  &:before {
    content: $icon-accessibility-contrast;
  }
}

.icon-accessibility-font {
  &:before {
    content: $icon-accessibility-font;
  }
}

.icon-bip {
  &:before {
    content: $icon-bip;
    color: #e83e4f;
  }
}

.icon-globe {
  &:before {
    content: $icon-globe;
  }
}

.icon-printer {
  &:before {
    content: $icon-printer;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}

.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}

.icon-attention {
  &:before {
    content: $icon-attention;
  }
}

.icon-question-mark {
  &:before {
    content: $icon-question-mark;
  }
}

.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-flash {
  &:before {
    content: $icon-flash;
  }
}
