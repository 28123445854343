@import "global";
.card{
    background-color: $dark-contrast;
    h3{
        color: $white-text;
    }
    .card-category-link{
        color: $white-text;
    }
}
.rounded-my{
    border: 2px solid $white-text;
}
.job-offer-simple-item{
    div{
        color: $white-text;
    }
}
.job-item-button{
    a:hover{
        color: $white-text;
    }
}
.form-search-jobs-container .job-offer-wrapper .job-offer-icon{
    filter: invert(100%) grayscale(1);
}
#form-search-jobs-results-sorting,#form-search-jobs-results-count{
    color: $white-text;
}
.accordionwithborders.accordion-button,.accordion-button{
    background-color: $contrast;
    &.collapsed{
        border-color: $white-text;
        &:before{
            background: linear-gradient($white-text,$white-text),linear-gradient($white-text,$white-text);
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 50% 1px,1px 50%;
        }
    }
    &:before{
        background: linear-gradient($white-text,$white-text);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 50% 1px,0 50%;
    }
}

.accordion-item{
    border-bottom: 1px solid $white-text;
}
.accordion-body {
    p{
        color: $white-text!important;
        span{
            color: $white-text!important;
        }
        a{
            color: $light-blue;
            &:visited {
                color: $light-blue;
            }
            span{
                color: $light-blue!important;
            }
        }
        u{
            &:has(a) {
                color: $light-blue;
            }
            a{
                color: $light-blue;
                &:visited {
                    color: $light-blue;
                }
                span{
                    color: $light-blue!important;
                }
            }
        }
    }
    div{
        color: $white-text;
    }
}
.success-checkmark{
    color: $white-text;
}
.brick_table .border {
    background-color: $dark-contrast;
}
// customer service
.subsidiary-block {
    background-color: $contrast;
    border: 1px solid $white-color;
    div{
        color: $white-text;
    }
    img{
        filter: invert(100%) grayscale(1);
    }
}
.btn-navy{
    background-color: $dark-blue;
}
// customer service end
.widget-1{
    h2{
        color: $dark-text;
        span{
            color: $dark-text!important;
        }
    }
    ul,ol{
        li{
            color: $dark-text!important;
            a{
                color: $dark-text!important;
            }
        }
    }
}