.work_under_pressure {
  &,
  & > * {
    box-sizing: border-box;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .header_content {
      max-width: 800px;
      width: 100%;
      margin-right: 20px;

      .header_title {
        color: #013088;
        font-size: 36px;
        margin-bottom: 30px;
        font-weight: 700;
      }

      .header_subtitle {
        color: #2a2e70;
        font-size: 24px;
        line-height: 1.4;
        font-weight: 400;
        margin-bottom: 40px;
      }

      .header_text {
        color: #6b6a71;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 100;
      }
    }

    .header_img {
      max-width: 330px;
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    @media only screen and (max-width: 1000px) {
      margin-bottom: 20px;

      .header_content {
        .header_title {
          margin-bottom: 15px;
        }

        .header_subtitle {
          margin-bottom: 15px;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .header_content {
        margin-right: 0;

        .header_title {
          font-size: 28px;
        }

        .header_subtitle {
          font-size: 20px;
        }
      }

      .header_img {
        display: none;
      }
    }
  }

  .accordion_section {
    .accordion_section-title {
      color: #1e1c27;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 25px;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    .accordion_elements {
      .accordion_question_element {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 15px 20px;
        border: 2px solid #87d4f2;
        border-radius: 15px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .icon_element {
          display: flex;
          align-items: center;
          margin-right: 10px;

          .plus {
            display: block;
          }

          .minus {
            display: none;
          }
        }

        .title_element {
          font-size: 24px;
          line-height: 1.2;
          font-weight: 400;
        }

        &.active {
          border-color: #f5f5f5;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

          .icon_element {
            .plus {
              display: none;
            }

            .minus {
              display: block;
            }
          }
        }

        @media only screen and (max-width: 768px) {
          padding: 6px 10px;
          border-radius: 8px;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          .icon_element {
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }

          .title_element {
            font-size: 20px;
          }
        }

        @media only screen and (max-width: 500px) {
          padding: 5px;
          .title_element {
            font-size: 16px;
          }
        }
      }

      .accordion_question_container {
        margin-left: 73px;
        margin-bottom: 20px;
        display: none;
        color: #343434;
        font-size: 20px;
        line-height: 1.4;

        &.active {
          display: block;
        }

        @media only screen and (max-width: 768px) {
          margin-left: 53px;
          font-size: 16px;
          margin-bottom: 12px;
        }

        @media only screen and (max-width: 500px) {
          margin-left: 48px;
          font-size: 13.6px;
        }
      }
    }
  }

  .text_section {
    margin: 40px 0;
    font-size: 16px;
    color: #6b6a71;
    font-weight: 400;
    line-height: 1.4;

    @media only screen and (max-width: 768px) {
      margin: 20px 0;
    }
  }

  .files_section {
    margin-bottom: 50px;

    .files_section-title {
      margin-left: 30px;
      color: #1e1c27;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 25px;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 15px;
        margin-left: 0;
      }
    }

    .files_section-links {
      .files_section-link {
        margin-left: 50px;
        display: flex;
        align-items: center;


        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .files_section-link-img {
          width: 40px;
          height: auto;
          margin-right: 30px;
        }

        .files_section-link-title {
          color: #4c4e51;
          font-size: 17px;
          font-weight: 400;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          .files_section-link-title {
            color: #013088;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .files_section-link {
          margin-left: 20px;

          .files_section-link-img {
            margin-right: 10px;
          }

          .files_section-link-title {
            font-size: 16px;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  .coordinators_section {
    .coordinators_section-title {
      color: #1e1c27;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 25px;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    .coordinators_cards {
      display: flex;
      flex-wrap: wrap;

      .coordinator_card {
        max-width: 330px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-right: 10px;

        .coordinator_card-img {
          width: 130px;
          height: 130px;
          border-radius: 50%;
          margin-right: 15px;

          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }

          &.background {
            background-color: #a3a3a3;
          }
        }

        .coordinator_card-text {
          color: #4c4e51;
          font-size: 17px;
        }
      }

      @media only screen and (max-width: 768px) {
        .coordinator_card {
          flex-direction: column;
          max-width: 200px;

          .coordinator_card-img {
            margin-right: 0;
            margin-bottom: 15px;
          }

          .coordinator_card-text {
            text-align: center;
          }
        }
      }

      @media only screen and (max-width: 424px) {
        justify-content: center;
      }
    }
  }
}