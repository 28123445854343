.contrast{
    @import "briks";
    @import "global";
    @import "homePage";
    @import "menuBreadcrumbs";
    @import "form";
    @import "press";
    @import "search";
}
body {
    &.contrast {
        background-color: #000000;
    }
}