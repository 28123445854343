@import "global";
article{
    div{
        color: $white-text;
        a{
            color: $light-blue;
        }
    }
    time{
        color: $light-blue!important;
    }
    .text-start,.text-end{
        a{
            color: $light-blue;
        }
    }

    .article-box{
        .article-title,.article-date{
            color: $light-blue!important;
        }
    }


    .tab_buttons{
        .btn{
            border-color: $white-text;
            background-color: $dark-contrast;
            color: $white-text;
        }
    }
    #more_img{
        color: $dark-blue;
        &:hover{
            color: $white-text;
        }
    }
}
.article-card{
    .article-box{
        background-color: $dark-contrast!important;
        .date-box {
            background-color: $dark-contrast!important;
        }
    }
}
.btn.btn-primary.collapsed{
    background-color: $dark-contrast-blue!important;
    color: $white-text;
    border-color: $white-text;
    &.active{
        background-color: $dark-blue!important;
        border-color: $dark-blue;
        color: #fff;
    }
}
.navyline{
    &:before {
        background: $white-text;
    }
}
.article-title,.article-date{
    color: $light-blue!important;
}
.omega-pagination{
    .omega-filter-button-grown{
        background-color: $dark-contrast;
    }
    .btn{
        color: $white-text;
    }
}
.omega-icon-twitter, .omega-icon-instagram, .omega-icon-facebook{
    filter: invert(100%) grayscale(1);
}