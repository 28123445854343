.text-accordion {
  position: relative;

  .accordion-header,
  .accordion-body {
    a {
      text-decoration: underline;
    }
  }

  .accordion-body,
  .fold-button {
    display: none;
  }

  .fold-button {
    cursor: pointer;
    font-weight: bold;
    &::before {
      display: inline-block;
      font-family: 'Icomoon';
      content: $icon-arrow-right;
      transform: rotate(90deg);
      font-size: size(10, false);
      margin-right: 7px;
    }
  }

  &.expanded {
    .accordion-body {
      display: block;
    }
    .fold-button {
      &::before {
        transform: rotate(270deg);
      }
    }
  }

  &.foldable {
    .fold-button {
      display: block;
    }
  }
}
