.instrukcja-ruchu {

		.flex-header-with-image {
			display: flex;
			align-items: center;
			margin-bottom: 1.5rem;
		}

		.flex-header-title {
			display: inline-flex;
			font-size: 2.5rem;
			align-items: center;
			font-weight: bold;
			color: #003087;
			text-indent: 2rem;
		}

		.prawo-energetyczne-header-img {
			max-width: 90px;
			width: 100%;
			height: auto;
			margin-right: 30px;
		}

		.editable {
			margin: 10px;
			border: 1px solid blue;
			padding: 10px;
			flex: 1 100% !important;
			align-items: inherit !important;

			&:focus {
				background-color: burlywood;
			}
		}

		.editable-img {
			width: 100px;
			height: 100px;
			margin: 10px;
			border: 1px solid blue;
		}

		.pdfLink {
			display: flex;
			align-items: center;
			text-decoration: unset;

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			.pdfLink-img {
				max-width: 40px;
				width: 100%;
				height: auto;
				margin-right: 20px;
			}

			.pdfLink-paragraph {
				color: #4c4e51;
				font-size: 1.125rem;
				margin: 0;
				font-weight: bold;
			}
		}

		.operator-grey-info-box {
			font-size: 1.125rem;
			line-height: 1.5rem;
			color: #4c4e51;
			font-weight: 300;
			background-color: #eaebf1;
			padding: 1.5rem;
			margin-top: 4rem;
		}

		.operator_navigation_container {
			max-width: 1300px;
			width: 100%;
			margin: 0 auto;

			.operator_navigation_nav {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.operator_navigation-button {
					text-align: center;
					border: 1px solid #013087;
					color: #013087;
					font-size: 20px;
					transition: all 0.3s ease-in-out;
					border-radius: 10px;
					cursor: pointer;
					padding: 5px 20px;
					max-width: 150px;
					width: 100%;
					font-weight: 300;
					background-color: #ffffff;

					&:hover {
						background-color: #013087;
						color: #ffffff;
					}
				}

				.operator_navigation-button.active {
					background-color: #013087;
					color: #ffffff;
					transform: scale(1.1);
				}

				.operator_navigation-line {
					background-color: #013087;
					height: 1px;
					width: 100%;
				}
			}

			.operator_navigation_pages {
				.operator_navigation_page {
					display: none;
				}

				.operator_navigation_page.active {
					display: block;
					margin-top: 1rem;
					margin-bottom: 0rem;
				}
			}
		}

		// page specific //
		.flex-instrukcja-ruchu h3 {
			margin: 1.25rem 0;
		}

		.flex-tabka-subheader-text {
			margin-bottom: 2rem;
		}

		.operator_navigation_container .operator_navigation_nav .operator_navigation-button {
			max-width: 186px;
		}

		.operator_navigation_nav {
			margin: 3rem 0;
		}

		@media only screen and (max-width: 1400px) {
			.top-bar {
				justify-content: center;
			}

			.flex-instrukcja-ruchu {
				margin: 0 0.75rem;
				padding: 5px 10px;
			}

			.operator_navigation_container {
				margin: 0 0.5rem;

				.operator_navigation_nav {
					.operator_navigation-button {
						padding: 5px 10px;

						&:not(:last-child) {
							margin-right: 10px;
						}
					}
				}

				.pdfLink {
					margin: auto 1rem 0.5rem;
				}
			}
		}

		@media only screen and (max-width: 1200px) {
			.flex-header-title {
				text-indent: 0;
			}
		}

		@media only screen and (max-width: 500px) {
			.flex-header-title {
				font-size: 1.5rem;
			}
			.prawo-energetyczne-header-img {
				max-width: 50px;
			}

			.operator_navigation_container {
				margin: 0 0.35rem;

				.operator_navigation_nav {
					flex-direction: column;
					align-items: center;

					.operator_navigation-button {
						margin-right: 0 !important;

						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}

					.operator_navigation-line {
						display: none;
					}
				}
			}

		}
	// }
}