.navyline {
    position: relative;
    align-items: center;

    &:before {
        content: "";
        background: $navybluecolor;
        height: 99.8%;
        width: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
        z-index: -1;

        @media (min-width: 768px) {
            height: 2px;
            width: 99.8%;
            transform: translate(-50%, 0);
        }
    }
}

.btn.btn-primary {
    font-size: 18px;
    font-weight: 200;
    line-height: 15px;
    min-height: 47px;
}

.btn.btn-primary {
    border-radius: 13px !important;

    &.collapsed {
        color: $navybluecolor;
        background-color: #fff;
        border-color: $navybluecolor;
        padding: 5px !important;
        box-shadow: 0 0 0 0.25rem rgba(0, 48, 135, 0);
        transition: transform 0.5s;
    }

    & {
        color: #fff;
        background-color: $navybluecolor;
        border-color: $navybluecolor;
        box-shadow: 0 0 0 0.25rem rgba(0, 48, 135, 0);
        transition: transform 0.5s;
    }

    &.active,
    &:hover {
        box-shadow: 0 0 0 0.25rem rgba(0, 48, 135, 0.25);
        background: $navybluecolor;
        color: #fff;
        transform: scale(1.1);
    }

    &:active,
    &.active {
        font-size: 20px;
        padding: 10px 0;
    }

    &.active,
    &:focus {
        color: #fff;
        background-color: #003087;
        border-color: #003087;
        padding: 10px 0;
    }
    &:focus{
        box-shadow: 0 0 0 0.25rem rgba(0, 48, 135, 0.25);
    }
}

.btn-secondary {
    color: $navybluecolor;
    background-color: #fff;
    border-color: $navybluecolor;
    box-shadow: 0 0 0 0.25rem rgba(0, 48, 135, 0);
    font-size: 20px;
}

.btn.btn-secondary {
    &:hover {
        font-size: 20px;
        color: #fff;
        background-color: $navybluecolor;
        border-color: $navybluecolor;
        box-shadow: 0 0 0 0.25rem rgba(0, 48, 135, 1);
    }
}

.x-btn-wrap {
    max-height: 25px !important;
}

.article-list-container {
    .btn-more {
        border: 1px solid #003087;
        background: #003087;
        color: #FFFFFF;
        padding: 5px 20px;
    }

    .btn-more:hover {
        background: #FFFFFF;
        color: #003087;
    }
}