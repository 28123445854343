@import "global";
.gsc-completion-container{
    background: $dark-contrast;
    border: 1px solid $dark-contrast;
}
.gsc-completion-selected{
    background: $dark-contrast-hover;
}
.gsc-results-wrapper-overlay{
    background: $dark-contrast;
}
.gsc-webResult.gsc-result, .gsc-results .gsc-imageResult{
    border-color: $dark-contrast;
    background-color: $dark-contrast;
}
.gsc-webResult.gsc-result:hover {
    border-color: $dark-contrast-hover;
    background-color: $dark-contrast-hover;
}
.gs-webResult.gs-result a.gs-title:link{
    color: $white-text;
}
.gs-webResult:not(.gs-no-results-result):not(.gs-error-result) .gs-snippet, .gs-fileFormatType{
    color: $white-text;
}
.gsc-modal-background-image-visible{
    opacity: 0.3;
}
.gs-result{
    .gsc-url-top{
        .gs-visibleUrl-breadcrumb{
            color: $light-blue;
        }
    }
    &-info{
        .gsc-orderby-label, .gsc-results-close-btn-visible{
            color: $white-text;
        }
    }
}
.gsc-results{
    .gsc-cursor-box .gsc-cursor-current-page {
        border-color: $white-text;
        background-color: $dark-contrast;
        color: $white-text;
    }
    .gsc-cursor-box .gsc-cursor-page{
        background-color: $dark-contrast;
        color: $white-text;
    }
}

.gcsc-find-more-on-google-magnifier {
    fill: $light-blue;
}
.gsc-selected-option-container{
    background-color: $contrast;
}
.gsc-selected-option{
    color: $white-text;
}
.gs-webResult.gs-result a.gs-title:visited b{
    color: $light-blue;
}