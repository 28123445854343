.omega-wide-block {
    width: 100%;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.omega-wide-block-ad {
    /* width: 100%; */
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 0px;
    padding:0px;
}
.omega-wide-block-ad:hover, .omega-wide-block:hover {
    box-shadow: -4px 5px 6px rgba(0, 0, 0, 0.15);
}

.omega-header-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #003087;
}
.omega-header-subtitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #003087;
    padding-bottom: 20px;
}
.omega-block-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.omega-block-subtitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.omega-icon-sm {
    width: 32px;
    height: 32px;
}
.omega-icon-xs {
    width: 16px;
    height: 16px;
}
.omega-icon-container {
    margin-left: 20px;
    margin-right: 20px;
}
.omega-link-blue, .omega-link-blue:active, .omega-link-blue:hover {
    color: rgb(32, 185, 255) !important;
}

.omega-separator {
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.1);
}

.omega-main-image {
    margin-left: 50px;
}

.omega-button {
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
    border: 1px solid #003087;
    color: #003087 !important;
    font-size: 17px;
}

.omega-button-grown,
.omega-button:hover {
    background-color: #003087;
    color: white !important;
}

.omega-button-grown,
.omega-button-growing:hover {
    font-size: 22px;
}

.omega-filter-button {
    background-color: $grey-bg;
    padding: 0px 20px;
    text-align: center;
    color: black !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 300;
    &:hover {
        text-decoration: none;
        background-color: $resolution-blue;
        color: white !important;
    }
}

.omega-filter-button-grown,
 .omega-filter-button-grown:hover {
     background-color: $resolution-blue;
     color: white !important;
 }

.omega-pagination {
    .btn {
        line-height: unset;
        padding:0 6px 0 6px;
        font-weight: 300;
        color: black;
    }
    a:hover {
        text-decoration: none;
    }
}

.omega-toolbar-growing {
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    z-index: 1;
}

.omega-toolbar-line-wrap {
    text-align: center;
    margin: 0;
    position: relative;
}
.omega-toolbar-line-wrap:before {
    content: '';
    position: absolute;
    top: 14px;
    left: 0;
    border-top: 1px solid #003087;
    width: 100%;
    transform: translateY(-50%);
}

.omega-image-left-rounded {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.omega-text {
    max-width: 100vw;
    text-align:justify; 
    color:rgba(0,0,0,0.7)
}

.contrast .omega-page {
    color:white
}

.contrast .omega-text {
    color:white
}

.contrast .breadcrumbs__link {
    /* color: #1c1c28; */
    color: white;
}
@media only screen and (min-width: 992px) {
    .txt-in-main-context {
        border-right: 1px solid #eee;
    }
}
@media only screen and (max-width: 991px) {
    .main-context{
        flex-direction: column;
    }
}

.contact-media-name{
    color: $resolution-blue;
    font-size: 25px;
    font-weight: bold;

}
.contact-media-phone{
    color: #808080;
}
.description-company-governing-bodies{
    display: flex;
    flex-direction: column;
    align-self: end;
}
.name-company-governing-bodies{
    color: $resolution-blue;
}
@media only screen and (max-width: 575px) {
    .main-company-governing-bodies{
        flex-direction: unset!important;
    }
}
.content-snippet{
    border-top: 1px solid #eee;
}