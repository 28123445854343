.galleryImage {
  transition: 0.2s all;
  position: relative;
  &:hover:after {
    opacity: 1;
  }
  &:after {
    content: '';
    background: url("../images/customicons/zoom.png") no-repeat center center, linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));;
    width: 94%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: all .2s ease-in-out;
  }
}