.collision_or_connection {
  &,
  & > * {
    box-sizing: border-box;
  }

  .header {
    display: flex;
    align-items: center;
    margin: 20px 0 30px;

    .header-img {
      max-width: 90px;
      width: 100%;
      height: auto;
      margin-right: 40px;
    }

    .header-text {
      font-size: 2.25rem;
      line-height: 1.3;
      color: #013088;
      font-weight: 700;
    }

    @media only screen and (max-width: 768px) {
      .header-img {
        max-width: 80px;
        margin-right: 20px;
      }

      .header-text {
        font-size: 1.75rem;
      }
    }

    @media only screen and (max-width: 500px) {
      .header-img {
        max-width: 60px;
        margin-right: 10px;
      }

      .header-text {
        font-size: 1.25rem;
      }
    }
  }

  .section_text {
    font-size: 16px;
    line-height: 1.2;
    color: #6b6a71;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .section_pdf {
    margin-left: 30px;

    .link {
      display: flex;
      align-items: center;
      text-decoration: unset;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .link-img {
        max-width: 40px;
        width: 100%;
        height: auto;
        margin-right: 20px;
      }

      .link-title {
        color: #4c4e51;
        font-size: 1.125rem;
        margin: 0;
        font-weight: 700;
      }
    }

    @media only screen and (max-width: 500px) {
      margin-left: 0;
    }
  }
}