.illegal_energy_take {
  &,
  & > * {
    box-sizing: border-box;
  }

  .accordion_section {
    .accordion_section-title {
      color: #013088;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 30px;

      @media only screen and (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }

    .accordion_elements {
      .accordion_element {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 15px 20px;
        border: 1px solid #87d4f2;
        border-radius: 15px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .icon_element {
          display: flex;
          align-items: center;
          margin-right: 10px;

          .plus {
            display: block;
          }

          .minus {
            display: none;
          }
        }

        .title_element {
          font-size: 18px;
          line-height: 1.2;
          font-weight: 400;
        }

        &.active {
          border-color: #f5f5f5;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
          margin-bottom: 20px;

          .icon_element {
            .plus {
              display: none;
            }

            .minus {
              display: block;
            }
          }
        }

        @media only screen and (max-width: 768px) {
          padding: 6px 10px;
          border-radius: 8px;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          .icon_element {
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }

          .title_element {
            font-size: 16px;
          }
        }

        @media only screen and (max-width: 500px) {
          padding: 5px;
          .title_element {
            font-size: 16px;
          }
        }
      }

      .accordion_container {
        margin-left: 73px;
        margin-bottom: 20px;
        display: none;
        color: #000000;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.4;

        &.active {
          display: block;
        }

        @media only screen and (max-width: 768px) {
          margin-left: 50px;
          font-size: 16px;
        }

        @media only screen and (max-width: 500px) {
          font-size: 13.6px;
        }
      }
    }
  }
}

.illegal_energy_take-main {
  padding: 20px 0 40px;
}