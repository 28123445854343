.operator-wwk {

    .table {

        tr {
            border-bottom: 1px solid #d7e5fe;

            *:not(th) {
                padding-bottom: 1.5rem;
            }
        }

        thead {
            border-top: 1px solid #d7e5fe;
            background-color: #013088;
            color: #fff;
            font-weight: 700;

        }

        tr:nth-child(2n+1)>td {
            background-color: #fbfcff;
        }
    }

    .page-item.active .page-link{
        background-color: #013088 !important;
    }
}