.mobile-nav.slinky-menu {
  background-color: $white;
  display: none;
  height: 100vh !important;
  left: 0;
  max-width: 320px;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform $default-transition-time;
  width: 100%;
  z-index: 2;

  &.active {
    transform: translateX(0);
  }

  @include below(lg) {
    display: block;
  }

  .mobile-nav-content {
    padding: 20px;

    .subcategory {
      padding: 20px;

      .header {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        margin-bottom: 40px;
        position: static;

        .back {
          padding: 0;
        }
      }

      .category-name {
        color: $resolution-blue;
        display: inline-block;
        font-size: size(18, false);
        font-weight: normal;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 60px);
      }

      .close {
        display: inline-block;
        vertical-align: top;
      }
    }

    .category,
    .category li {
      border-top: 1px solid $arc-light;

      &:first-of-type {
        border: none;
      }

      > a {
        &:not(.link) {
          color: $resolution-blue;
          font-size: size(18, false);
          font-weight: bold;
          padding: 1em;
        }

        &.next {
          &:after {
            background: none;
            color: $resolution-blue;
            content: $icon-chevron-down;
            font-family: 'Icomoon';
            font-size: size(14, false);
            opacity: 1;
            transform: rotate(-90deg);
          }
        }

        &.back {
          &:before {
            background: none;
            color: $resolution-blue;
            content: $icon-chevron-down;
            font-family: 'Icomoon';
            font-size: size(14, false);
            opacity: 1;
            transform: rotate(90deg);
          }
        }
      }
    }

    .close {
      display: block;

      &:after {
        color: $cerulean;
        content: '\002B';
        display: block;
        font-size: 40px;
        font-weight: bold;
        transform: rotate(45deg);
      }
    }
  }

  .login-section {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin-bottom: 25px;

    .dropdown-menu {
      display: block;

      .button {
        padding-bottom: 12px;
        margin-bottom: 0;
      }
    }
  }

  .top-phones-list {
    flex-direction: column;
    height: auto;
    padding: 20px 0;
    border-top: 1px solid $arc-light;

    .item {
      .link {
        padding: 0;
      }

      & + .item {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
