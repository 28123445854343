.breadcrumbs-wrapper .my-breadcrumbs
{
    color: #005E80;
    font-weight: 300;
}
.breadcrumbs-wrapper .my-breadcrumbs .active{
    background-image: linear-gradient(currentColor,currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    transition: background-size .3s cubic-bezier(0,.5,0,1);
}


.breadcrumbs-wrapper .my-breadcrumbs a:visited{
    color: #003087;
}
.breadcrumbs-wrapper .my-breadcrumbs .active:hover{
    text-decoration: none;
    color: #005E80;
    background-size: 100% 1px;
}