#widget-4 {
  display: flex;
  flex-direction: column;
  background-color: $white;
  @include box-shadow(4);
  border-radius: 16px;
  height: 192px;
  @include below(md) {
    height: auto;
  }
  .banner-part {
    height: 180px;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    img {
      width: auto;
      height: 100%;
    }
    @include below(md) {
      height: auto;
      img {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
  }
  .list-wrapper {
    display: grid;
    position: relative;
    padding: 0 24px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    @include above(md) {
      padding: 0 18px;
    }
    .list {
      display: inline-grid;
      height: 100%;
      align-items: center;
      .list-item {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        min-height: 56px;
        font-size: size(18);
        border-bottom: 1px solid $arc-light;
        &:last-of-type {
          border-bottom: none;
        }
        @include above(xl) {
          font-size: size(20, false);
        }
        .list-link {
          @include link($black);
          font-weight: $regular;
          display: flex;
          height: 100%;
          padding-right: 26px;
          align-items: center;
          line-height: 1.2;
          @include below(md) {
            &:hover {
              background-size: 0;
            }
            width: 100%;
            height: 100%;
          }
          .list-arrow {
            font-size: size(16, false);
            color: $resolution-blue;
            margin-right: 0;
            transition: right $default-transition-time;
            position: absolute;
            right: 0;
            display: block;
          }
          &:hover {
            text-decoration: none;
            .list-arrow {
              right: 10px;
            }
          }
        }
      }
    }
  }
}

.contrast {
  #widget-4 {
    background-color: $ebony-clay;
    color: $white;
    .list-wrapper {
      .list {
        .list-item {
          border-color: $steel-gray;
          .list-link {
            @include link($white);
          }
          .list-arrow {
            color: $white;
          }
        }
      }
    }
  }
}
