.row.widget-1 {
  margin: 0;
}
.list-bg-img{
  background-repeat: no-repeat;
  background-position-y: 70%;
  background-position-x: 90%;
}
@media only screen and (max-width: 991px){
  .list-bg-img {
    background-image: none!important;
  }
}
.widget-1 {
  background-color: $white;
  height: auto;
  @include box-shadow(4);


.slider-part {
.splide__track {
  @include above(md) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.splide__pagination {
  justify-content: center;
  @include above(md) {
    justify-content: flex-end;
  }
}

.slider-content {
  position: absolute;
  top: 0;
  padding: 40px;
  text-align: center;

  .slider-title {
    font-size: size(26, false);
    line-height: 1.2;
    margin: 24px 0;
    color: $white;
  }

  .slider-link {
    display: inline-block;
  }

  @include below(md) {
    width: 100%;
  }
  @include above(lg) {
    padding-right: 44%;
  }
  @include above(md) {
    padding: 40px 20% 40px 40px;
    text-align: left;
    .slider-title {
      font-size: size(32, false);
    }
  }
}
}

.text-part {

.list {
  display: grid;
  align-items: center;

  .list-item {
    color: #003087;
    position: relative;
    height: 100%;
    min-height: 56px;
    .color-white{ color: #FFFFFF}
    .color-grey{ color: #28293d}
    @include below(md) {
      font-size: size(14);
    }

    .list-text-right {
      position: absolute;
      right: 12px;
      font-size: size(14, false);
    }
    .list-text-right a{
      color: $alizarin-crimson;
    }

    .list-link,
    .list-text {
      @include link($resolution-blue);
      font-weight: $regular;
      display: flex;
      //width: 100%;
      align-items: center;
      font-size: size(16, false);
      @include below(md) {
        font-size: size(16);
      }

      .list-icon,
      .list-arrow {
        font-size: size(16, false);
        margin-right: 12px;
      }

      .list-arrow {
        transition: right $default-transition-time;
        position: absolute;
        right: 0;
      }



      &:hover {
        text-decoration: none;

        .list-arrow {
          right: 10px;
        }
      }
    }
    a.list-link.test {
        background-image: linear-gradient(currentColor,currentColor);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 0 1px;
        transition: background-size .3s cubic-bezier(0,.5,0,1);

        &:hover {
            background-size: 100% 1px;
        }
    }
  }
    .list-item.line-bottom{
        &:after {
            content: '';
            position: absolute;
            background-color: $arc-light;
            width: 95%;
            height: 1px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .list-item.color-white{
        color: #FFFFFF!important;
        .list-link{ color: #FFFFFF!important; }
        .icon-arrow-right{ color: #FFFFFF!important; }
    }
    .list-item.color-grey{
        color: #4c4e51!important;
        .list-link{ color: #4c4e51!important; }
        .icon-arrow-right{ color: #4c4e51!important; }
    }
  li.list-item::before {
    content: "●";
    margin-left: -15px;
    margin-right: 1em;
  }
}
}
}
.contrast {
.widget-1 {
background-color: $ebony-clay;
color: $white;

.text-part {
  .list {
    .list-item {
      .list-link,
      .list-text {
        @include link($white);
      }

      &:not(:last-child) {
        &:after {
          background-color: $steel-gray;
        }
      }
    }
  }
}
}
}
.list .pimcore_block_entry{
  border: 2px solid #0d6efd;
  padding: 10px;
  margin: -10px -10px 20px -10px;
}

.carousel-indicators [data-bs-target] {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #ccc;
}
.carousel-caption.slider-content{ padding: 0 40px }

@media only screen and (max-width: 991px){  h2.slider-title { font-size: 23px; } }
@media only screen and (max-width: 768px){
  .my-slider { background-position: center;
      background-size: contain!important; }
    .button.additional { font-size: 12px; padding: 8px; }
    .rounded-md-0 { border-radius: 0!important; }
}
@media only screen and (max-width: 515px) {
    h2.slider-title {
        font-size: 13px;
        padding: 10px 0;
    }
    .ribbon.blue {
        font-size: 12px;
    }
    .button-slider a.additional.button {
        font-size: 10px;
    }
}
.img-instead-txt-checkbox:before{
  display: none; }
.img-instead-txt-checkbox{
  .list-my-icon {
    display: none!important;
  }
  .link-with-img {
    padding: 0 15px 0 0!important;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    text-decoration: none;
    color: inherit;

    img {
      width: inherit;
      height: auto;
    }
    i.list-arrow.icon-arrow-right {
      position: absolute!important;
      right: -25px!important;
      transition: right .34s!important;
    }
  }
}
.img-instead-txt-checkbox .link-with-img:hover i.list-arrow.icon-arrow-right{ right: -15px!important; }
.carousel-caption.slider-content .additional:hover:focus{
    background-color: #e93d4e !important;
    color: #FFFFFF !important;
}

.carousel-control-next, .carousel-control-prev{
  &:focus span{
    border: 1px solid #003087
  }
}