.omega-downloads-header {
  font-size: 16px;
  color: #003087;
}
.download_files_link {
  display: block;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid transparent;

  &:hover {
    color: $secondlightbluecolor;
    border-color: $secondlightbluecolor;
  }
}