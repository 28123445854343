main {
  min-height: 50vh;
}

header {
  &.shadow {
    box-shadow: 0 12px 10px -15px $manatee;
  }
}
.section{
  border-top:0.0625rem solid #aeaeae;
  background-color:#f1f1f1 ;
  margin-top: 2rem;
  padding: 2rem 1rem;
}
h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  color: #003087;
  width: 100%;
  margin-left:0!important;
}
h2 {
    font-weight: 300;
    color: #4c4e51;
}
h3 {
    font-weight: 300;
    color: #4c4e51;
}
.title{

}
.wrapper {
  margin-right: auto;
  margin-left: auto;
  @include clearfix;
  @include above(xl) {
    max-width: 1200px + $grid-gutter;
  }
}