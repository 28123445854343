.header {
  &.navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 24px;

    @include below(md) {
      padding-top: 24px;
    }

    .logo {
      .image {
        display: block;
        height: 70px;
        width: 130px;

        @include below(xxxlg) {
          height: auto;
          width: 100px;
        }
      }
    }

    .dropdown-menu {
      position: static;

      &.active {
        .dropdown-list {
          display: flex;
        }
      }

      @include below(lg) {
        display: none;
      }

      .button {
        margin-bottom: -15px;
        padding-bottom: 20px;
        white-space: nowrap;

        @include below(xlg) {
          font-size: size(16, false);
        }
      }

      .dropdown-list {
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 47rem;
        flex-direction: row;
        flex-wrap: wrap;
        left: 0;
        max-width: 1280px;
        min-height: 320px;
        right: 0;
        top: 70px;
        width: 100%;

        @include below(xxxlg) {
          top: 65px;
        }

        .link {
          @include link($ebony-clay, $cerulean);
          @include link-underline();
        }
      }

      .categories-list {
        margin: 0 8px;
        min-height: 250px;
        width: calc(25% - 18px);

        i {
          margin-right: 10px;
        }

        .item {
          font-size: size(14);
          margin-bottom: 20px;

          &.headline {
            align-items: flex-start;
            color: $resolution-blue;
            display: flex;
            font-size: size(18, false);
            font-weight: normal;
            line-height: 18px;
            margin-bottom: 25px;

            &:hover {
              .icon,
              .link {
                color: $cerulean;
              }
            }

            .link {
              color: $resolution-blue;
            }
          }
        }
      }

      .links-list {
        color: $resolution-blue;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 8px;
        min-height: 250px;
        width: calc(25% - 18px);

        .item {
          align-items: center;
          display: flex;
          margin-bottom: 25px;

          &:hover {
            .icon {
              color: $cerulean;
            }
          }
        }

        .link {
          @include link($resolution-blue, $cerulean);
          font-weight: normal;
        }

        .icon {
          margin-right: 20px;
        }
      }
    }
  }

  &.searchbar {
    align-items: center;
    border-radius: 8px;
    border: 1px solid $ghost;
    display: flex;
    height: 40px;
    padding: 0 15px;
    position: relative;
    width: 218px;

    @include below(xxlg) {
      border: none;
      width: auto;
    }

    .search-button {
      background-color: transparent;
      border: none;
      color: $resolution-blue;
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }

    .input {
      border: none;
      height: 100%;
      margin: 0 15px;
      width: 100%;

      @include below(xxlg) {
        display: none;
      }

      &:focus {
        outline: none;
      }
    }

    .clear {
      background-color: $dusty-gray;
      border-radius: 50%;
      border: none;
      color: $white;
      cursor: pointer;
      height: 16px;
      min-height: 16px;
      min-width: 16px;
      position: relative;
      width: 16px;

      @include below(xxlg) {
        display: none;
      }

      &::after {
        content: '\002B';
        font-size: size(18, false);
        font-weight: bold;
        height: 100%;
        left: 12px;
        position: absolute;
        top: 50%;
        transform: translate(-42%, -58%) rotate(45deg);
        width: 100%;
      }
    }
  }
}

.contrast {
  .navigation {
    .dropdown-menu {
      &.active > .button.dropdown {
        background-color: $ebony-clay;
      }

      &:not(.active) {
        .button {
          color: $white;
        }
      }

      .dropdown-list {
        .categories-list,
        .links-list {
          .headline {
            color: $white;
          }

          .link {
            @include link($white, $cerulean);
            @include link-underline();
          }
        }
      }
    }

    .logo {
      .image {
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .header {
    &.searchbar {
      background-color: $ebony-clay;
      border-color: $ebony-clay;

      .input {
        background-color: $ebony-clay;
        color: $ghost;
      }

      .search-button {
        color: $white;
      }

      .clear {
        color: $ebony-clay;
      }
    }
  }
}
