.report_network_characteristics {
  &,
  & > * {
    box-sizing: border-box;
  }

  .operator_navigation_container {

    .operator_navigation_nav {
      margin-bottom: 50px;
      align-items: stretch;
      position: relative;
      justify-content: space-between;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #013088;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      .operator_navigation-button {
        z-index: 2;
        font-size: 24px;
        min-width: 260px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.2;
      }

      @media only screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: center;

        &:before {
          display: none;
          width: 0;
          height: 0;
        }

        .operator_navigation-button {
          max-width: 600px;
          width: 100%;
          padding: 10px;

          &:not(:last-child) {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .operator_navigation-button {
          max-width: 320px;
          width: 100%;
          font-size: 20px;
        }
      }

      @media only screen and (max-width: 400px) {
        .operator_navigation-button {
          &.active {
            transform: scale(1);
          }
        }
      }
    }

    .operator_navigation_pages {
      .operator_navigation_page {
        .page_title {
          font-size: 28px;
          color: #4c4e51;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .page_text {
          font-size: 18px;
          color: #013087;
          font-weight: 400;

          table,
          tr,
          td,
          th {
            border: 1px solid #d7e5fe;
            vertical-align: middle;
            text-align: center;
          }

          td {
            color: #013088;
            font-weight: 400;
            padding: 20px 5px;
          }

          tr {
            &:nth-child(2n+1) {
              td {
                background-color: #fbfcff;
              }
            }

            &:nth-child(1) {
              td {
                background-color: #013088;
                color: #ffffff;
                font-size: 22px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .operator_navigation_nav {
        margin-bottom: 30px;
      }
      .operator_navigation_pages {
        .operator_navigation_page {
          .page_title {
            font-size: 24px;
          }

          .page_text {
            td {
              font-size: 16px;
            }

            tr {
              &:nth-child(1) {
                td {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 460px) {
      .operator_navigation_pages {
        .operator_navigation_page {
          .page_title {
            font-size: 20px;
          }

          .page_text {
            td {
              font-size: 14px;
            }

            tr {
              &:nth-child(1) {
                td {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 400px) {
      .operator_navigation_pages {
        .operator_navigation_page {
          .page_text {
            td {
              font-size: 12px;
              padding: 10px 2px;
            }

            tr {
              &:nth-child(1) {
                td {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}