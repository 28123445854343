@mixin above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
}

// Center horizontaly
@mixin centerer-X {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

// Center verticaly
@mixin centerer-Y {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

// Center both
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin link(
  $link: $base-font-color,
  $hover: $link,
  $visit: $link,
  $active: $link
) {
  color: $link;
  text-decoration: none;

  &:visited {
    color: $visit;
  }
  &:hover,
  &:focus {
    color: $hover;
    text-decoration: none;
  }
  &:active {
    color: $active;
  }
}

@mixin link-underline() {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size cubic-bezier(0, 0.5, 0, 1) 0.3s;
  &:hover,
  &:focus {
    text-decoration: none;
    background-size: 100% 1px;
  }
}

@mixin box-shadow($number) {
  --box-shadow-color: rgba(96, 97, 112, 0.16);
  @if $number == 1 {
    --box-shadow-spec: 0px 0.5px 2px;
  } @else if $number == 2 {
    --box-shadow-spec: 0px 2px 4px;
  } @else if $number == 3 {
    --box-shadow-spec: 0px 4px 8px;
  } @else if $number == 4 {
    --box-shadow-spec: 0px 8px 16px;
  } @else if $number == 5 {
    --box-shadow-spec: 0px 16px 24px;
  } @else if $number == 6 {
    --box-shadow-spec: 0px 20px 32px;
  }
  box-shadow: var(--box-shadow-spec) var(--box-shadow-color);
}
