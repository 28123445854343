.step_by_step {
  &,
  & > * {
    box-sizing: border-box;
  }

  .step_by_step_header {
    margin-bottom: 40px;

    .step_by_step_header-title {
      color: #013088;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 40px;
    }

    .step_by_step_header_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .step_by_step_header-text {
        max-width: 850px;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        color: #6b6a71;
        margin-right: 20px;
      }

      .step_by_step_header-img {
        max-width: 320px;
        width: 100%;

        img {
          max-width: 320px;
          width: 100%;
          height: auto;
        }
      }
    }

    @media only screen and (max-width: 900px) {
      .step_by_step_header_box {
        .step_by_step_header-img {
          display: none;
        }
      }
    }
  }

  .section_video {
    display: flex;
    justify-content: space-between;

    .video_element {
      max-width: 650px;
      width: 100%;
      margin-bottom: 50px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      iframe {
        border-radius: 15px;
      }
    }

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      .video_element {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  .horizontal_nav {
    .horizontal_nav-title {
      color: #1e1c27;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .operator_navigation_container {
      .operator_navigation_pages {
        .operator_navigation_page {
          padding-bottom: 40px;
          margin: 40px auto;
          border-bottom: 2px solid #dce8ff;

          .box_with_img {
            display: flex;

            .box_with_img-img {
              max-width: 100px;
              width: 100%;
              margin-right: 60px;

              img {
                max-width: 100px;
                width: 100%;
                height: auto;
              }
            }

            .box_with_img_content {
              .box_with_img_content-title {
                font-size: 46px;
                font-weight: 700;
                color: #212236;
                margin-bottom: 40px;
              }

              .box_with_img_content-text {
                font-size: 24px;
                font-weight: 400;
                color: #616272;
                margin-bottom: 15px;
              }

              .link {
                display: block;
                color: #10a7e3;
                font-size: 24px;
                font-weight: 400;
                max-width: fit-content;
                border-bottom: 2px solid transparent;
                transition: all 0.3s ease-in-out;

                &:hover {
                  color: #013087;
                  border-color: #013087;
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 900px) {
      .operator_navigation_container {
        .operator_navigation_pages {
          .operator_navigation_page {
            .box_with_img {
              .box_with_img-img {
                max-width: 60px;
                margin-right: 20px;

                .img {
                  max-width: 60px;
                }
              }

              .box_with_img_content {
                .box_with_img_content-title {
                  font-size: 30px;
                  margin-bottom: 25px;
                }

                .box_with_img_content-text,
                .link {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 500px) {
      .operator_navigation_container {
        .operator_navigation_pages {
          .operator_navigation_page {
            .box_with_img {
              .box_with_img-img {
                max-width: 40px;
                min-width: 40px;
                margin-right: 10px;

                .img {
                  max-width: 40px;
                }
              }

              .box_with_img_content {
                .box_with_img_content-title {
                  font-size: 24px;
                  margin-bottom: 15px;
                }

                .box_with_img_content-text,
                .link {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  .section_boxes_with_list {
    .box_with_img {
      display: flex;
      margin-bottom: 40px;

      .box_with_img-img {
        max-width: 100px;
        width: 100%;
        margin-right: 60px;

        img {
          max-width: 100px;
          width: 100%;
          height: auto;
        }
      }

      .box_with_img_content {
        .box_with_img_content-title {
          font-size: 46px;
          font-weight: 700;
          color: #212236;
          margin-bottom: 40px;
        }

        .box_with_img_content_list {
          margin-bottom: 15px;

          .box_with_img_content_list-element {
            display: flex;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            .element-img {
              min-width: 30px;
              margin-right: 20px;

              img {
                width: 100%;
                height: auto;
              }
            }

            .element-text {
              font-size: 24px;
              font-weight: 400;
              color: #616272;

            }
          }
        }

        .link {
          display: block;
          color: #10a7e3;
          font-size: 24px;
          font-weight: 400;
          max-width: fit-content;
          border-bottom: 2px solid transparent;
          transition: all 0.3s ease-in-out;
          margin-left: 50px;

          &:hover {
            color: #013087;
            border-color: #013087;
          }
        }
      }
    }

    @media only screen and (max-width: 900px) {
      .box_with_img {
        .box_with_img-img {
          max-width: 60px;
          margin-right: 20px;

          .img {
            max-width: 60px;
          }
        }

        .box_with_img_content {
          .box_with_img_content-title {
            font-size: 30px;
            margin-bottom: 25px;
          }

          .box_with_img_content_list {
            .box_with_img_content_list-element {
              .element-img {
                min-width: 20px;
                margin-right: 15px;
              }

              .element-text {
                font-size: 18px;
              }
            }
          }

          .link {
            font-size: 18px;
            margin-left: 35px;
          }
        }
      }
    }

    @media only screen and (max-width: 500px) {
      .box_with_img {
        .box_with_img-img {
          max-width: 40px;
          min-width: 40px;
          margin-right: 10px;

          .img {
            max-width: 40px;
          }
        }

        .box_with_img_content {
          .box_with_img_content-title {
            font-size: 24px;
            margin-bottom: 15px;
          }

          .box_with_img_content_list {
            .box_with_img_content_list-element {
              &:not(:last-child) {
                margin-bottom: 10px;
              }

              .element-img {
                min-width: 15px;
                margin-right: 10px;
              }

              .element-text {
                font-size: 16px;
              }
            }
          }

          .link {
            font-size: 16px;
            margin-left: 25px;
          }
        }
      }
    }
  }

  .section_rates {
    .section_rates-title {
      color: #003085;
      font-size: 46px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .section_rates_container {
      border-radius: 15px;
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
      padding: 40px;
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;

      .box_element {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .box_element-img {
          max-width: 80px;
          width: 100%;
          margin-bottom: 20px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .box_element-text_img {
          font-size: 26px;
          color: #7e7d8a;
          font-weight: 400;
          margin-bottom: 20px;
        }

        .box_element-title {
          color: #204895;
          font-weight: 400;
          margin-bottom: 10px;
          font-size: 28px;
        }

        .box_element-subtitle {
          font-size: 22px;
          color: #7e7d8a;
        }
      }
    }

    @media only screen and (max-width: 900px) {
      .section_rates-title {
        font-size: 36px;
      }

      .section_rates_container {
        flex-direction: column;
        align-items: center;

        .box_element {
          &:not(:last-child) {
            margin-bottom: 40px;
          }

          .box_element-img {
            max-width: 60px;
          }

          .box_element-text_img {
            font-size: 22px;
          }

          .box_element-title {
            font-size: 24px;
          }

          .box_element-subtitle {
            font-size: 16px;
          }
        }
      }
    }

    @media only screen and (max-width: 900px) {
      .section_rates-title {
        font-size: 28px;
      }

      .section_rates_container {
        .box_element {
          .box_element-img {
            max-width: 40px;
          }

          .box_element-text_img {
            font-size: 20px;
          }

          .box_element-title {
            font-size: 22px;
          }

          .box_element-subtitle {
            font-size: 16px;
          }
        }
      }
    }
  }

  .horizontal_nav_2 {
    .operator_navigation_container {
      .operator_navigation_pages {
        .operator_navigation_page {
          .hn2_page-title {
            font-size: 36px;
            color: #00257e;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .hn2_page-text {
            font-size: 24px;
            font-weight: 400;
            color: #616272;

            table,
            tr,
            td,
            th {
              border: 1px solid #d7e5fe;
              vertical-align: middle;
              text-align: center;
            }

            td {
              color: #013088;
              font-weight: 700;
              padding: 20px 5px;
            }

            tr {
              &:nth-child(2n) {
                td {
                  background-color: #fbfcff;
                }
              }

              &:nth-child(2) {
                td {
                  background-color: #013088;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 900px) {
      .operator_navigation_container {
        .operator_navigation_pages {
          .operator_navigation_page {
            .hn2_page-title {
              font-size: 28px;
            }

            .hn2_page-text {
              font-size: 18px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 500px) {
      .operator_navigation_container {
        .operator_navigation_pages {
          .operator_navigation_page {
            .hn2_page-title {
              font-size: 24px;
            }

            .hn2_page-text {
              font-size: 16px;

              table {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 380px) {
      .operator_navigation_container {
        .operator_navigation_pages {
          .operator_navigation_page {
            .hn2_page-text {
              table {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}