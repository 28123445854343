$primary: #2a2e70;
$secondary: #005aab;
$info: #00aeef;
$gray: #464b4f;
$danger: #e72235;
$success: #198754;
body {
  .container_zgody {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;

    &.hide {
      display: none;
    }

    &.loader_active {
      filter: blur(10px);
    }

    &,
    & > * {
      box-sizing: border-box;
    }

    .header {
      position: relative;
      display: flex;

      @media only screen and (min-width: 770px) and (max-width: 980px) {
        margin-left: -142px;
      }

      .header-img {
        max-width: 1920px;
        width: 100%;
        height: auto;

        &.header-img-mobile {
          display: none;
        }

        @media only screen and (max-width: 770px) {
          &.header-img-mobile {
            display: block;
          }

          &.header-img-desktop {
            display: none;
          }
        }
      }

      h1 {
        position: absolute;
        top: 6%;
        left: 20.5%;
        // top: 127px;
        // left: 389px;
        // left: 24.5rem;
        font-weight: 600;
        color: $primary;
        font-size: 40px;
        letter-spacing: -1px;
        line-height: 65px;
        color: #2a2e70;
        font-weight: 600;
        font-family: "Signika";

        /* 1640 - 36px
1400 - 32px
1300 - 26px
1080 - 23px + top: 5%
900  - 20px
780  - 17px

640  - 40px top: 20% + left 7% */
        @media only screen and (max-width: 1770px) {
          font-size: 32px;
        }
        @media only screen and (max-width: 1300px) {
          font-size: 26px;
        }
        @media only screen and (max-width: 1080px) {
          font-size: 23px;
          top: 5%;
        }
        @media only screen and (max-width: 900px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 780px) {
          font-size: 17px;
        }
        @media only screen and (max-width: 770px) {
          // font-size: 40px;
          // top: 20%;
          // left: 7%;
          font-size: 52px;
          top: 21%;
          left: 7%;
        }
      }

      h3 {
        position: absolute;
        top: 11%;
        left: 20.5%;
        color: $primary;
        width: 17%;

        @media only screen and (max-width: 770px) {
          // top: 32%;
          // left: 11%;
          // width: 76%;
          top: 35%;
          left: 7%;
          width: 76%;
        }
        @media only screen and (max-width: 570px) {
          top: 44%;
          left: 7%;
          width: 72%;
        }
        @media only screen and (max-width: 470px) {
          top: 50%;
          left: 7%;
          width: 72%;
        }

        p {
          font-size: 30px;
          line-height: 30px;
          color: #2a2e70;
          font-weight: 600;
          font-family: "Signika";

          @media only screen and (max-width: 1690px) {
            font-size: 23px;
            line-height: 24px;
          }
          @media only screen and (max-width: 1300px) {
            font-size: 20px;
            line-height: 23px;
          }
          @media only screen and (max-width: 1080px) {
            font-size: 19px;
            top: 11%;
            line-height: 21px;
          }
          @media only screen and (max-width: 900px) {
            font-size: 17px;
            line-height: 19px;
          }
          @media only screen and (max-width: 780px) {
            font-size: 16px;
            line-height: 17px;
          }
          @media only screen and (max-width: 770px) {
            font-size: 35px;
            line-height: 40px;
            width: 83%;
          }
          @media only screen and (max-width: 570px) {
            font-size: 33px;
            line-height: 33px;
            width: 72%;
          }
          @media only screen and (max-width: 450px) {
            font-size: 24px;
            line-height: 25px;
            width: 67%;
          }
        }

        .subtext {
          margin-top: 26px;

          p {
            font-size: 20px;
            line-height: 44px;
            color: #2a2e70;
            font-weight: 600;
            font-family: "Signika";

            @media only screen and (max-width: 1690px) {
              font-size: 19px;
              line-height: 20px;
            }
            @media only screen and (max-width: 1300px) {
              font-size: 18px;
              line-height: 19px;
            }
            @media only screen and (max-width: 1080px) {
              font-size: 17px;
              top: 22%;
              line-height: 18px;
            }
            @media only screen and (max-width: 900px) {
              font-size: 17px;
              line-height: 19px;
            }
            @media only screen and (max-width: 780px) {
              font-size: 16px;
              line-height: 17px;
            }
            @media only screen and (max-width: 770px) {
              // font-size: 20px;
              // line-height: 21px;
              display: none;
            }
          }
        }
      }

      h4 {
        position: absolute;
        top: 23%;
        left: 20.5%;
        color: $primary;

        @media only screen and (max-width: 1690px) {
          top: 26%;
          left: 20.5%;
        }
        @media only screen and (max-width: 1300px) {
          top: 28%;
          left: 20.5%;
        }
        @media only screen and (max-width: 1080px) {
          top: 32%;
          left: 20.5%;
        }
        @media only screen and (max-width: 900px) {
          top: 31%;
          left: 20.5%;
        }
        @media only screen and (max-width: 780px) {
          top: 39%;
          left: 20.5%;
        }
        @media only screen and (max-width: 770px) {
          top: 33%;
          left: 20.5%;
        }

        p {
          font-size: 20px;
          line-height: 44px;
          color: #2a2e70;
          font-weight: 600;
          font-family: "Signika";

          @media only screen and (max-width: 1690px) {
            font-size: 19px;
            line-height: 20px;
          }
          @media only screen and (max-width: 1300px) {
            font-size: 18px;
            line-height: 19px;
          }
          @media only screen and (max-width: 1080px) {
            font-size: 17px;
            top: 22%;
            line-height: 18px;
          }
          @media only screen and (max-width: 900px) {
            font-size: 17px;
            line-height: 19px;
          }
          @media only screen and (max-width: 780px) {
            font-size: 16px;
            line-height: 17px;
          }
          @media only screen and (max-width: 770px) {
            font-size: 20px;
            line-height: 21px;
          }
        }
      }

      .enrollmentSteps {
        position: absolute;
        left: 20%;
        top: 25%;
        z-index: 2;
        overflow: hidden;
        @media only screen and (max-width: 1840px) {
          transform: scale(0.8);
          left: 18%;
          top: 25%;
        }
        @media only screen and (max-width: 1690px) {
          transform: scale(0.65);
          left: 15.5%;
          top: 18%;
        }
        @media only screen and (max-width: 1430px) {
          top: 21%;
          left: 14%;
        }
        @media only screen and (max-width: 1200px) {
          top: 22%;
          left: 14%;
        }
        @media only screen and (max-width: 1100px) {
          top: 23%;
          left: 13%;
        }
        @media only screen and (max-width: 980px) {
          top: 26%;
          left: 13%;
        }

        @media only screen and (max-width: 770px) {
          display: none;
        }
      }

      ul.stepsList.vertical li.step-1 span {
        margin-top: 0 !important;
        padding-top: 0 !important;
      }

      ul.stepsList.vertical li:last-child span {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }

      .stepsList {
        list-style: none;
      }

      .stepsList div.stepInner {
        // position: absolute;
        // left: 60px;
        // top: 20px;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
      }

      .stepsList a {
        color: #2a2e70;
        text-decoration: none;
      }

      .stepsList p,
      .stepsList div {
        color: #2a2e70;
        text-decoration: none;
        // position: absolute;
        // left: 60px;
        // top: 60px;
      }

      .stepsList div.stepTitle {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 23px;
        color: #2a2e70;
        font-weight: 600;
        font-family: Signika;
        display: flex;
        align-items: center;
        min-height: 3rem;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .stepsList div.stepDescription {
        font-size: 19px;
        letter-spacing: 0px;
        line-height: 20px;
        color: #2a2e70;
        // font-weight: 400;
        font-family: "Signika";
        margin: 27px 0;
      }

      .stepsList li {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .stepsList li span {
        display: inline-block;
        font-weight: 800;
        width: 2em;
        height: 2em;
        text-align: center;
        line-height: 2em;
        border-radius: 1.5em;
        background: #2a2e70;
        color: #fff;
        position: relative;
        text-indent: 5px;
        font-size: 26px;
      }

      .stepsList li::before {
        content: "";
        position: absolute;
        background: #2099cd;
        z-index: -1;
      }

      .stepsList.vertical {
        padding: 0;
        margin: 0;
      }

      .stepsList.vertical li {
        list-style-type: none;
        text-align: left;
      }

      .stepsList.vertical li span {
        margin: 1.4em 0;
        margin-top: 0;
      }

      .stepsList.vertical li::before {
        top: -550%; // hack
        left: 25px;
        width: 0.05em;
        height: 600%; // max 5 elements
      }

      .stepsList li:first-child::before {
        display: none;
      }

      .benefits {
        position: absolute;
        left: 20%;
        top: 51%;

        @media only screen and (max-width: 1690px) {
          transform: scale(0.8);
          left: 16%;
          top: 40%;
        }
        @media only screen and (max-width: 1550px) {
          transform: scale(0.65);
          left: 12%;
          top: 38%;
        }
        @media only screen and (max-width: 1430px) {
          top: 43%;
          left: 11%;
        }
        @media only screen and (max-width: 1200px) {
          transform: scale(0.5);
          left: 5.5%;
          top: 40%;
        }
        @media only screen and (max-width: 1100px) {
          transform: scale(0.5);
          left: 5.5%;
          top: 42%;
        }
        @media only screen and (max-width: 1100px) {
          transform: scale(.45);
          left: 1.5%;
          top: 43%;
        }
        @media only screen and (max-width: 980px) {
          transform: scale(.4);
          left: 1.5%;
          top: 43%;
        }
        @media only screen and (max-width: 770px) {
          display: none;
        }

        // z-index: 2;
        // overflow: hidden;
        .description {
          font-size: 30px;
          line-height: 35px;
          color: #2a2e70;
          font-weight: 600;
          font-family: "Signika";
        }

        .pros {
          display: flex;
          flex-wrap: wrap;
          /* justify-content: space-between; */
          max-width: 700px;
          justify-self: flex-start;
          justify-content: center;
          // grid-template-columns: 400px 400px;
          .value {
            max-width: 270px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 40px 0 40px;

            .title {
              font-size: 20px;
              letter-spacing: 0px;
              line-height: 26px;
              color: #d82626;
              font-weight: 600;
              font-family: "Signika";
              text-align: center;

              &.blue {
                color: #47a0da;
              }

              &.grey {
                color: #767879;
              }

              &.darkblue {
                color: #0f3081;
              }
            }

            .description {
              font-size: 16px;
              letter-spacing: 0px;
              line-height: 18px;
              color: #a1a1a1;
              // font-weight: 600;
              font-family: "Signika";
              text-align: center;
              font-weight: normal;
              max-width: 220px;
            }
          }
        }
      }
    }

    .section {
      background-size: cover;

      @media only screen and (max-width: 1100px) {
        font-size: 32px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 26px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 24px;
      }

      &.section-1 {
        display: none;
        margin-top: 26px;

        @media only screen and (max-width: 770px) {
          display: flex;
          justify-content: center;
          flex-direction: column;

          .subtext {
            text-align: center;

            p {
              font-size: 25px;
              line-height: 44px;
              color: #2a2e70;
              font-weight: 600;
              font-family: "Signika";
            }
          }

          .enrollmentSteps {
            margin: 20px 0;

            .step {
              justify-content: center;
              text-align: center;
              align-items: center;

            }

            div.stepInner {
              margin: 25px;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            a {
              color: #2a2e70;
              text-decoration: none;
            }

            p,
            div {
              color: #2a2e70;
              text-decoration: none;
            }

            div.stepTitle {
              font-size: 20px;
              letter-spacing: 0;
              line-height: 23px;
              color: #2a2e70;
              font-weight: 600;
              font-family: Signika;
              display: flex;
              align-items: center;
              // min-height: 3rem;
              flex-direction: column;
              justify-content: space-evenly;
            }

            div.stepDescription {
              font-size: 19px;
              letter-spacing: 0px;
              line-height: 20px;
              color: #2a2e70;
              font-family: "Signika";
              align-items: center;
              // margin: 27px 0;
            }

            div {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }

            div span {
              display: inline-block;
              font-weight: 800;
              width: 2em;
              height: 2em;
              text-align: center;
              line-height: 2em;
              border-radius: 1.5em;
              background: #2a2e70;
              color: #fff;
              position: relative;
              text-indent: 5px;
              font-size: 26px;
            }
          }
        }
      }

      &.section-2 {
        position: absolute;
        max-width: 680px;
        top: 8%;
        left: 55.5%;
        width: 400px;
        color: #ffffff;
        background-color: #47a0da;
        font-family: "Signika";

        @media only screen and (max-width: 2100px) {
          left: 56.5%;
          top: 7%;
        }
        @media only screen and (max-width: 1690px) {
          transform: scale(0.8);
          top: -2%;
          left: 54.5%;
        }
        @media only screen and (max-width: 1320px) {
          transform: scale(0.7);
          top: -8%;
          left: 52%;
        }
        @media only screen and (max-width: 1100px) and (orientation: portrait) {
          transform: scale(0.6);
          top: -15%;
          left: 45%;
        }
        @media only screen and (max-width: 1100px) and (orientation: landscape) {
          transform: scale(0.6);
          top: -20%;
          left: 49%;
        }
        @media only screen and (max-width: 980px) {
          left: 43%;
        }
        /* ----------- iPad Pro ----------- */
        // /* Portrait and Landscape */
        // @media only screen 
        //   and (min-width: 1024px) 
        //   and (max-height: 1366px) 
        //   and (-webkit-min-device-pixel-ratio: 1.5) {
        // }

        /* Portrait */
        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
          transform: scale(.8);
          top: -4%;
          left: 50%;
        }

        // /* Landscape */
        // @media only screen 
        //   and (min-width: 1024px) 
        //   and (max-height: 1366px) 
        //   and (orientation: landscape) 
        //   and (-webkit-min-device-pixel-ratio: 1.5) {
        // }

        @media only screen and (max-width: 770px) {
          transform: initial;
          position: relative;
          display: flex;
          top: 0%;
          left: 0%;
          width: 100%;
        }

        .form {
          padding: 30px;

          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #2a2e70;
            opacity: 1; /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #2a2e70;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #2a2e70;
          }

          .form-popup {
            display: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
            border: 2px solid $danger;
            color: $danger !important;
            background-color: lighten($danger, 40%);
            box-shadow: rgba($danger, 0.25) 0px 50px 100px -20px, rgba($danger, 0.3) 0px 30px 60px -30px;
            padding: 20px;
            left: 50%;
            width: 90%;
            font-size: 18px;
            border-radius: 10px;

            .popup-title {
              font-weight: 700;
            }
          }

          .form-title {
            font-size: 30px;
            margin-top: 10px;
          }

          .form-subtitle {
            font-size: 17px;
            font-weight: 100;
            margin: 13px 0;
          }

          form#client_form {
            .red-star {
              color: red;
            }

            .form_text {
              // .rodo {
              font-size: 12px;
              letter-spacing: 0px;
              color: #ffffff;
              font-weight: 300;
              font-family: "Signika";
              margin-bottom: 30px;
              margin-top: 15px;

              &.flat {
                margin-bottom: 0;
                margin-top: 0;
              }

              // }
            }

            .form_group {
              margin: 10px 0 7px;

              .form_widget {
                position: relative;

                .label_input {
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  transform: translateY(-50%);
                  color: $primary;
                  font-weight: 400;
                  font-size: 18px;
                  transition: all 0.3s ease-in-out;

                  &.focusOnInput {
                    color: #fff;
                    font-size: 16px;
                    top: 0;
                    left: 0;
                  }
                }

                input {
                  width: 100%;
                  padding: 10px;
                  background-color: #fff;
                  color: #47a0da;
                  font-family: "Signika";
                  height: 40px;
                  border: 3px solid #84d0f1;
                  margin: 12px 0;
                  outline: none;
                  font-size: 18px;

                  &.invalid {
                    border-color: $danger;
                    color: $danger !important;
                    background-color: lighten($danger, 40%);
                  }

                  &.valid {
                    border-color: $success;
                    color: $success !important;
                  }
                }

                .help_text_block {
                  visibility: hidden;
                  position: absolute;
                  right: 45px;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: #888;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 400;
                  border-radius: 8px;
                  padding: 5px 10px;
                  width: calc(100% - 45px);
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                  line-height: 1.2;
                  z-index: 99;
                }

                .icon_help {
                  font-weight: 400;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: $primary;
                  color: #ffffff;
                  font-size: 12px;
                  border-radius: 50%;
                  width: 25px;
                  height: 25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  opacity: 0.7;
                  transition: all 0.3s ease-in-out;
                  cursor: pointer;

                  &:hover {
                    opacity: 1;
                  }

                  &:hover ~ .help_text_block {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }

              input[type="checkbox"] {
                position: static;
                transform: scale(1.2);
                margin-right: 5px;
                visibility: visible !important;
              }

              input[type="checkbox"] + label:before {
                content: none;
              }

              &.form_group-checkbox {
                .form_widget_checkbox-label {
                  font-size: 12px;
                  letter-spacing: 0px;
                  color: #ffffff;
                  font-weight: 300;
                  font-family: "Signika";
                  margin-bottom: 30px;
                  margin-top: 15px;
                  display: flex;
                  align-items: flex-start;
                }
              }

              .form_widget-submit {
                margin: 10px 10px;
                background: #d82626;
                padding: 20px;
                font-family: "Signika";
                width: 200px;
                border: 1px solid #d82626;
                border-radius: 10px;
                color: #fff;
                font-size: 30px;
                font-weight: 600;
                cursor: pointer;

                &.disabled {
                  filter: grayscale(100%);
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }

      &.section-3 {
        display: none;
        margin-top: 50px;
        @media only screen and (max-width: 770px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .benefits {
          .description {
            font-size: 35px;
            line-height: 40px;
            color: #2a2e70;
            font-weight: 600;
            font-family: "Signika";
            text-align: center;
            width: 75%;
            margin: auto;
          }

          .pros {
            display: flex;
            flex-wrap: wrap;
            /* justify-content: space-between; */
            max-width: 680px;
            justify-self: flex-start;
            justify-content: center;
            // grid-template-columns: 400px 400px;
            .value {
              max-width: 270px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 20px 40px 0 40px;

              .title {
                font-size: 20px;
                letter-spacing: 0px;
                line-height: 26px;
                color: #d82626;
                font-weight: 600;
                font-family: "Signika";
                text-align: center;

                &.blue {
                  color: #47a0da;
                }

                &.grey {
                  color: #767879;
                }

                &.darkblue {
                  color: #0f3081;
                }
              }

              .description {
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #a1a1a1;
                font-family: "Signika";
                text-align: center;
                font-weight: 600;
                max-width: 400px;
              }
            }
          }
        }
      }
    }
  }

  .lp_agreements {
    .lp_agreements_modal {
      width: 100%;

      .modal_variant {
        display: none;
        background-repeat: no-repeat;
        background-position: top left;
        background-size: cover;
        position: relative;
        width: 100%;
        min-height: 1200px;

        .modal_content {
          margin: 20px 20px 0;

          @media only screen and (min-width: 800px) {
            position: absolute;
            top: 20px;
            left: 330px;
            margin: 0 !important;
          }
          max-width: 350px;
          width: 100%;

          &,
          & > * {
            line-height: 1.2;
          }

          img {
            margin-bottom: 60px;
          }

          .modal_content-title {
            font-size: 42px;
            margin-bottom: 20px;
            font-weight: 700;
          }

          .modal_content-subtitle {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 15px;
          }

          .modal_content-text {
            font-size: 16px;
            font-weight: 400;
          }
        }

        &.modal_variant-success {
          .modal_content-title {
            &,
            & > * {
              color: $info !important;
            }
          }

          .modal_content-subtitle {
            &,
            & > * {
              color: $primary !important;
            }
          }

          .modal_content-text {
            &,
            & > * {
              color: $gray !important;
            }
          }
        }

        &.modal_variant-fail {
          .modal_content-title {
            &,
            & > * {
              color: $danger !important;
            }
          }

          .modal_content-subtitle {
            &,
            & > * {
              color: $primary !important;
            }
          }

          .modal_content-text {
            &,
            & > * {
              color: $gray !important;
            }
          }
        }
      }

      &.success {
        .modal_variant {
          &.modal_variant-success {
            display: block;
          }
        }
      }

      &.fail {
        .modal_variant {
          &.modal_variant-fail {
            display: block;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-around;
      background-color: #fff;
      padding: 20px;
      font-weight: 400;
      font-size: 16px;

      &.loader_active {
        filter: blur(10px);
      }

      .footer_block {
        a {
          border-bottom: 2px solid transparent;
          transition: all 0.3s ease-in-out;
          text-decoration: none !important;
        }

        &.block-left {
          a {
            color: $primary;

            &:hover {
              border-color: $primary;
            }
          }
        }

        &.block-right {
          a {
            color: $gray;

            &:hover {
              border-color: $gray;
            }
          }
        }
      }
    }

    .loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: none;
      z-index: 99;

      &.active {
        display: block;
      }

      .loader_content {
        z-index: 99;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .lds-ellipsis {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;

          div {
            position: absolute;
            top: 33px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $primary;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);

            &:nth-child(1) {
              left: 8px;
              animation: lds-ellipsis1 0.6s infinite;
            }

            &:nth-child(2) {
              left: 8px;
              animation: lds-ellipsis2 0.6s infinite;
            }

            &:nth-child(3) {
              left: 32px;
              animation: lds-ellipsis2 0.6s infinite;
            }

            &:nth-child(4) {
              left: 56px;
              animation: lds-ellipsis3 0.6s infinite;
            }

            @keyframes lds-ellipsis1 {
              0% {
                transform: scale(0);
              }
              100% {
                transform: scale(1);
              }
            }
            @keyframes lds-ellipsis3 {
              0% {
                transform: scale(1);
              }
              100% {
                transform: scale(0);
              }
            }
            @keyframes lds-ellipsis2 {
              0% {
                transform: translate(0, 0);
              }
              100% {
                transform: translate(24px, 0);
              }
            }
          }
        }

        .title {
          font-size: 24px;
          color: $primary;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
}

body {
  max-width: 100% !important;
}
