// headings
h1 {
  font-size: size(40);
  font-weight: $bold;
}
h2 {
  font-size: size(32);
  font-weight: $bold;
}
h3 {
  font-size: size(28);
  font-weight: $bold;
}
h4 {
  font-size: size(24);
  font-weight: $bold;
}
h5 {
  font-size: size(20);
  font-weight: $bold;
}
h6 {
  font-size: size(16);
  font-weight: $bold;
}
