$navybluecolor: #003087;
$lightbluecolor: #87d4f2;
$secondlightbluecolor: #005E80;


a {
  color: $navybluecolor
}

a:hover {
  color: $secondlightbluecolor;
  text-decoration: underline;
}
a:visited{
  color: #253552;
  text-decoration: underline;
}

//@media (min-width: 576px) {
//  li {
//    margin-left: 25px;
//  }
//}

h2 .accordion-button {
  color: black;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem;
  color: $eneaFontColor;
}

.accordion-item {
  background-color: unset;
  border: 0;
  border-bottom: 1px solid $secondlightbluecolor;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px); /*usunięte ramki*/
}

.accordion-button:focus {
  z-index: 3;
  border-color: #fff;
  outline: 1px solid $secondlightbluecolor;
  box-shadow: 0 0 0 0 rgba(13, 110, 253, .25);
}

.accordion-button:not(.collapsed) {
  // color: #0c63e4;
  color: $eneaFontColor;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, .125);
}

.accordion-button::after {
  width: 0;
  height: 0;
}

.accordion-button::before {
  content: ' ';
  display: inline-block;
  width: 50px !important;
  height: 50px;
  min-width: 50px;
  background: linear-gradient($secondlightbluecolor, $secondlightbluecolor);
  background-position: center;
  background-size: 50% 1px, 0 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}

.accordion-button.collapsed::before {
  content: '';
  width: 50px !important;
  height: 50px;
  min-width: 50px;
  background: linear-gradient($secondlightbluecolor, $secondlightbluecolor),
  linear-gradient($secondlightbluecolor, $secondlightbluecolor);
  background-position: center;
  background-size: 50% 1px, 1px 50%;
  background-repeat: no-repeat;
}

.accordionwithborders.accordion-button {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3);
  border: none;
  border-radius: 15px !important;
  margin-bottom: 1rem;
  padding: 0.3rem;
}

.accordionwithborders.accordion-button.collapsed {
  border: 2px solid $lightbluecolor;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0);
  border-radius: 15px !important;
  margin-bottom: 1rem;
  padding: 0.3rem;
}

.accordionwithborders.accordion-button.collapsed:hover, .accordionwithborders.accordion-button.collapsed:focus{
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3);
}

/* resize plus - version mini*/
.accordionplusmini.accordion-button.collapsed::before {
  content: '';
  width: 35px !important;
  height: 35px;
  min-width: 35px;
  background: linear-gradient($secondlightbluecolor, $secondlightbluecolor),
  linear-gradient($secondlightbluecolor, $secondlightbluecolor);
  background-position: center;
  background-size: 50% 1px, 1px 50%;
  background-repeat: no-repeat;
}


.accordionwithborders .accordion-item {
  border: 0;
}

/* accordionplusmini*/

.accordionplusmini.accordion-button {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3);
  border: none;
  border-radius: 15px !important;
  margin-bottom: 1rem;
  padding: 0.3rem;
}

.accordionplusmini.accordion-button.collapsed {
  border: none;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0);
  border-radius: 15px !important;
  margin-bottom: 1rem;
  padding: 0.3rem;
}

.accordionplusmini.accordion-button.collapsed:hover {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3);
}

/* resize minus- version mini*/
.accordionplusmini.accordion-button::before {
  content: ' ';
  display: inline-block;
  width: 35px !important;
  height: 35px;
  min-width: 35px;
  background: linear-gradient($secondlightbluecolor, $secondlightbluecolor);
  background-position: center;
  background-size: 50% 1px, 0 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}


.accordionplusmini .accordion-item {
  border: 0;
}

.m-accordion-plus {
  margin-left: 165px
}

.m-description-left {
  max-width: 55px
}

.mminiplus-description-left {
  max-width: 35px
}