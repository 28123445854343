.section_rates {
  .section_rates-title {
    color: #003085;
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .section_rates_container {
    .box_element {
      .box_element-img {
        max-width: 80px;
        width: 100%;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .box_element-text_img {
        font-size: 26px;
        color: #7e7d8a;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .box_element-title {
        color: #204895;
        font-weight: 400;
        margin-bottom: 10px;
        font-size: 28px;
      }

      .box_element-subtitle {
        font-size: 22px;
        color: #7e7d8a;
      }
    }
  }
    .line-between-img{
        border-right: 3px solid #ccdfff;
    }
    @media (max-width: 901px) {
        .line-between-img{
            border-right: none;
            border-bottom: 3px solid #ccdfff;
            padding-bottom: 35px!important;
        }

    }
  @media only screen and (max-width: 900px) {
    .section_rates-title {
      font-size: 36px;
    }

    .section_rates_container {
      flex-direction: column;
      align-items: center;

      .box_element {
        &:not(:last-child) {
          margin-bottom: 20px!important;
        }
        &:last-child {
          margin-top: 20px!important;
        }

        .box_element-img {
          max-width: 60px;
        }

        .box_element-text_img {
          font-size: 22px;
        }

        .box_element-title {
          font-size: 24px;
        }

        .box_element-subtitle {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .section_rates-title {
      font-size: 28px;
    }

    .section_rates_container {
      .box_element {
        .box_element-img {
          max-width: 40px;
        }

        .box_element-text_img {
          font-size: 20px;
        }

        .box_element-title {
          font-size: 22px;
        }

        .box_element-subtitle {
          font-size: 16px;
        }
      }
    }
  }
}