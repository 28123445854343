.prawo-energetyczne {

	margin-left: auto;
	margin-right: auto;

	.flex-container {
		display: flex;
		flex-wrap: wrap;
		flex-flow: column;
	}

	.flex-header-with-image {
		display: flex;
		align-items: center;
	}

	.flex-header-title {
		display: inline-flex;
		font-size: 2.5rem;
		align-items: center;
		font-weight: bold;
		color: #013088;
		text-indent: 2rem;
	}

	.editable {
		margin: 10px;
		border: 1px solid blue;
		padding: 10px;

		&:focus {
			background-color: burlywood;
		}
	}

	.editable-img {
		width: 100px;
		height: 100px;
		margin: 10px;
		border: 1px solid blue;
	}

	.pdfLink {
		display: flex;
		align-items: center;
		text-decoration: unset;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		.pdfLink-img {
			max-width: 40px;
			width: 100%;
			height: auto;
			margin-right: 20px;
		}

		.pdfLink-paragraph {
			color: #4c4e51;
			font-size: 1.125rem;
			margin: 0;
			font-weight: bold;
		}
	}

	.operator-grey-info-box {
		font-size: 1.125rem;
		line-height: 1.5rem;
		color: #4c4e51;
		font-weight: 300;
		background-color: #eaebf1;
		padding: 1.5rem;
		margin-top: 1rem;
		margin-bottom: 3rem;
	}

	.operator_navigation_container {
		max-width: 1300px;
		width: 100%;
		margin: 0 auto;

		.operator_navigation_nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 3.5rem 0 3.5rem 2rem;

			.operator_navigation-button {
				text-align: center;
				border: 1px solid #013088;
				color: #013088;
				font-size: 20px;
				transition: all 0.3s ease-in-out;
				border-radius: 10px;
				cursor: pointer;
				padding: 5px 20px;
				max-width: 150px;
				width: 100%;
				font-weight: 300;
				background-color: #ffffff;

				&:hover {
					background-color: #013088;
					color: #ffffff;
				}
			}

			.operator_navigation-button.active {
				background-color: #013088;
				color: #ffffff;
				transform: scale(1.2);
			}

			.operator_navigation-line {
				background-color: #013088;
				height: 1px;
				width: 100%;
			}
		}

		.operator_navigation_pages {
			.operator_navigation_page {
				display: none;
			}

			.operator_navigation_page.active {
				display: block;
				margin-top: 1rem;
			}
		}
	}

	@media only screen and (max-width: 1600px) {
		body {
			width: 100vw !important;
		}

		nav {
			.dropdown-menu:last-child {
				margin-right: 1.75rem;
			}
		}

		.flex-header-with-image {
			margin-left: 0.5rem;
		}

		.operator_navigation_container {
			// margin: auto 1.75rem;

			.operator_navigation_nav {
				.operator_navigation-button {
					padding: 5px 10px;
				}
			}

			.pdfLink {
				margin: auto 1rem 0.5rem;
			}
		}
	}

	@media only screen and (max-width: 1400px) {
		.top-bar {
			justify-content: center;
		}
	}

	@media only screen and (max-width: 768px) {
		.flex-header-with-image {
			margin: auto;
		}

		.operator_navigation_container {
			margin: 0 0.5rem;

			.operator_navigation_nav {
				.operator_navigation-button {
					padding: 5px 10px;

					&:not(:last-child) {
						margin-right: 10px;
					}
				}

				.operator_navigation-line {
					display: none;
				}
			}

			.pdfLink {
				margin: auto 1rem 0.5rem;
			}
		}
	}

	@media only screen and (max-width: 500px) {
		.operator_navigation_container {
			margin: 0 0.35rem;

			.operator_navigation_nav {
				flex-direction: column;
				align-items: center;

				.operator_navigation-button {
					margin-right: 0 !important;

					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}
		}
	}

}