.form {
  .flex-row {
    display: block;
    &.hidden {
      display: none;
    }
  }

  .flex-column {
    width: 100%;
    @include below(md) {
      @for $i from 1 through 9 {
        &.w-#{$i}0 {
          width: $i * 10%;
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  &-headline {
    margin-left: 10px;
    font-size: size(24);
  }

  &-container {
    position: relative;
  }

  .form-page {
    flex-wrap: wrap;
    border: 0;
    display: none;
    outline: 0;
    width: 100%;

    &.show {
      display: block;
    }
  }

  .label {
    color: $black;
    display: block;
    padding-bottom: 8px;

    &.required {
      &::after {
        content: '*';
        margin-left: 5px;
        color: $alizarin-crimson;
      }
    }

    &.centered {
      text-align: center;
    }

    &.inline {
      display: inline-block;
    }
    &.flex {
      display: flex;
    }
  }

  .textarea {
    background-color: $white;
    border: 1px solid $alto;
    border-radius: 8px;
    color: $manatee;
    font-size: size(14);
    line-height: 1.6;
    min-height: 100px;
    padding: 8px;
    width: 100%;
    resize: vertical;
    font-family: 'Signika';

    &-wrapper {
      margin-top: 10px;
      outline: 0;
      padding: 0 10px 20px;
      position: relative;
    }
  }

  .remove-button-container {
    margin: 0 auto;
  }

  .remove-button {
    position: absolute;
    right: 0;
    bottom: 13px;
    left: 0;
    margin: 0 auto;
    border-color: $alizarin-crimson;
    background-color: $white;
    color: $alizarin-crimson;
    font-size: size(14);
    padding: 0.5rem 2rem;

    &:hover {
      background-color: $alizarin-crimson;
      color: $white;
    }
  }

  .input-group {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 6px solid #f1f1f1;
  }

  .input-group-button {
    margin: 0 10px 20px 10px;
  }

  .checkbox-wrapper {
    padding: 0 10px 20px;
    margin-top: 10px;
    position: relative;
    &.bottom-border {
      border-bottom: 1px solid #e4e5e6;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    .error-message {
      position: static;
    }
  }

  .input-wrapper {
    margin-top: 10px;
    max-height: 132px;
    outline: 0;
    padding: 0 10px 20px;
    position: relative;
    width: 100%;

    .radio-wrapper {
      margin-bottom: 8px;
    }

    .horizontal {
      .radio-wrapper {
        margin-bottom: 0 !important;
      }
    }

    &.centered {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .input-icon {
      position: relative;
      display: table;
      border-collapse: separate;

      input {
        display: table-cell;
        padding-right: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      i {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 6px 12px;
        font-size: size(14);
        font-weight: 400;
        line-height: 1;
        color: #555;
        text-align: center;
        background-color: #eee;
        border: 1px solid #ccc;
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
        display: table-cell;
        border-right: 0;
        font-style: normal;
      }
    }

    .input {
      font-family: 'Signika';
      background-color: $white;
      border: 1px solid $alto;
      border-radius: 8px;
      color: $manatee;
      font-size: size(14);
      padding: 8px 12px;
      width: 100%;
      height: 40px;
    }

    .row {
      .radio-wrapper {
        margin-bottom: 8px;
      }
    }
  }

  .fine-uploader {
    padding: 10px;
  }

  .input-wrapper,
  .textarea-wrapper,
  .checkbox-wrapper,
  .fine-uploader {
    &.error {
      .input,
      .textarea {
        border-color: $alizarin-crimson;
        color: $alizarin-crimson;
      }
      .error-message {
        display: block;
      }
    }
  }

  .error-message {
    bottom: -2px;
    color: $alizarin-crimson;
    display: none;
    font-size: size(14);
    margin-top: 5px;
    position: absolute;
    &::before {
      content: $icon-circle-error;
      font-family: 'Icomoon';
      display: inline-block;
      font-size: size(12);
      left: 0;
      position: relative;
      top: 0;
      margin-right: 4px;
    }
  }

  &-navigation {
    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-navigation {
    margin: 50px 10px 0;
  }

  .selectric-wrapper {
    display: block;
    .selectric {
      @include box-shadow(2);
      background-color: $whisper;
      border: none;
      border-radius: 8px;
      height: 40px;
      .label {
        color: $manatee;
        margin: 0 15px;
        color: $black;
        font-size: size(14);
      }
      .button {
        background: none;
        &:hover {
          box-shadow: none;
        }
        &:after {
          border-top-color: $black;
        }
      }
    }
    .selectric-items {
      @include box-shadow(5);
      background-color: $white;
      border: none;
      border-radius: 8px;
      overflow: hidden;
      li {
        padding: 10px 15px;
        font-size: size(14);
        &.selected {
          &.highlighted {
            color: $cerulean;
            background-color: $white;
          }
        }
        &:hover {
          background-color: $resolution-blue;
          color: $white;
        }
      }
    }
  }

  .form-info {
    padding: 10px;
    font-weight: 300;
    display: block;
    width: 100%;
    font-size: size(14);

    .asterix {
      color: $alizarin-crimson;
    }
  }
}

.form-summary {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 400px;
  z-index: 4;
  background: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  button {
    display: none;
    margin-top: 10px;
    &.button-retry,
    &.button-home {
      &.show {
        display: inline-block;
      }
    }
  }
  .icon {
    width: 56px;
    height: 56px;
    display: inline-block;
  }

  &.success {
    .icon {
    }
  }
  &.error {
    .icon {
    }
  }
  &.show {
    display: flex;
  }
}

@include below(md) {
  .form {
    .flex-row {
      display: flex;
    }
  }

  .form-contact__container--en {
    .form-contact__item {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .form-contact__label {
      min-width: 9rem;
    }

    .form-contact__info,
    .form-contact__checkbox__container {
      margin-left: 9rem;
    }

    .form-contact__errotext {
      margin-left: 9rem;
      width: 100%;
    }
  }
}

.form-contact__info {
  font-size: 0.625rem;
  margin-bottom: 0.75rem;
  text-align: left;
  padding: 0 10px;

  p {
    margin-bottom: 0.5rem;
  }

  .star {
    color: $alizarin-crimson;
    margin: 0 0.25rem;
  }
}
