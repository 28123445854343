.css-loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;

  .container {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: fixed;
    overflow: hidden;
    top: calc(50vh - 180px);
    left: calc(50vw - 60px);

    &.big {
      width: 120px;
      height: 120px;
    }

    .element {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: $resolution-blue;
        border-radius: 100%;
        animation: loader-circleBounceDelay 1.2s infinite ease-in-out both;
      }
    }

    @for $i from 1 through $loaderDotsNumber {
      .dot#{$i} {
        transform: rotate(($i - 1) * 30deg);

        &:before {
          animation-delay: -(1.3s - $i/10);
        }
      }
    }
  }
}

@keyframes loader-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
