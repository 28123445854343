.remit {
  font-size: 1.063rem;
  line-height: 1.2;
  &, & > * {
    box-sizing: border-box;
  }
  .remit_body {
    margin-bottom: 60px;
    .remit_body-title {
      font-size: 2.25rem;
      line-height: normal;
      color: #2a2e70;
      margin-bottom: 20px;
    }
  }

  .remit_footer-title {
    color: #005E80;
    font-weight: 400;
    font-size: 1.25rem;
  }
  @media only screen and (max-width: 768px) {
    .remit_body {
      margin-bottom: 40px;
    }
  }

  &.editmode {
    .remit_body,
    .remit_footer {
      border: 2px solid #2a2e70;
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 10px;
      .editmode_element {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .editmode_element-label {
          font-weight: 700;
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
    }
  }
}