/* ---------------------------------------
/* Fine Uploader Styles
/* ---------------------------------------

/* Buttons
------------------------------------------ */
.qq-btn {
  margin-right: 10px;
}
.qq-upload-delete,
.qq-upload-pause,
.qq-upload-continue {
  display: inline;
}
.qq-upload-delete {
  background-color: #e65c47;
  color: #fafafa;
  border-color: #dc523d;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.55);
}
.qq-upload-delete:hover {
  background-color: #f56b56;
}
.qq-upload-cancel {
  background-color: #f5d7d7;
  border-color: #e6c8c8;
}
.qq-upload-cancel:hover {
  background-color: #ffe1e1;
}
.qq-upload-retry {
  background-color: #ebf6e0;
  border-color: #d2ddc7;
}
.qq-upload-retry:hover {
  background-color: #f7ffec;
}
.qq-upload-pause,
.qq-upload-continue {
  background-color: #00abc7;
  color: #fafafa;
  border-color: #2dadc2;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.55);
}
.qq-upload-pause:hover,
.qq-upload-continue:hover {
  background-color: #0fbad6;
}

/* Upload Button
------------------------------------------ */
.qq-upload-button {
  display: inline;
  width: 105px;
  margin-bottom: 10px;
  padding: 7px 10px;
  text-align: center;
  float: left;
  background: #00abc7;
  color: #ffffff;
  border-radius: 2px;
  border: 1px solid #2dadc2;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.37) inset,
    1px 0 1px rgba(255, 255, 255, 0.07) inset, 0 1px 0 rgba(0, 0, 0, 0.36),
    0 -2px 12px rgba(0, 0, 0, 0.08) inset;
}
.qq-upload-button-hover {
  background: #33b6cc;
}
.qq-upload-button-focus {
  outline: 1px dotted #000000;
}

/* Drop Zone
------------------------------------------ */
.qq-uploader {
  position: relative;
  min-height: 200px;
  max-height: 490px;
  overflow-y: hidden;
  width: inherit;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: 1px dashed #cccccc;
  padding: 20px;
  margin: 0 10px 20px;
}
.qq-uploader:before {
  content: attr(qq-drop-area-text) ' ';
  position: absolute;
  font-size: size(32);
  left: 0;
  width: 100%;
  text-align: center;
  top: 38%;
  opacity: 0.25;
}
.qq-upload-drop-area,
.qq-upload-extra-drop-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 30px;
  z-index: 2;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px dashed #cccccc;
  text-align: center;
}
.qq-upload-drop-area span {
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -8px;
  font-size: size(16);
}
.qq-upload-extra-drop-area {
  position: relative;
  margin-top: 50px;
  font-size: size(16);
  padding-top: 30px;
  height: 20px;
  min-height: 40px;
}
.qq-upload-drop-area-active {
  background: #fdfdfd;
  border-radius: 4px;
  border: 1px dashed #cccccc;
}
.qq-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 450px;
  overflow-y: auto;
  box-shadow: 0px 1px 0px rgba(15, 15, 50, 0.14);
  clear: both;
}

/* Uploaded Elements
------------------------------------------ */
.qq-upload-list li {
  margin: 0;
  padding: 9px;
  line-height: 15px;
  font-size: size(16);
  color: #424242;
  border-bottom: 1px solid #e0e0e0;
  margin: 5px 0;
  display: flex;
  align-items: center;
}
.qq-upload-list li:first-child {
  border-top: none;
}
.qq-upload-list li:last-child {
  border-bottom: none;
}

.qq-upload-file,
.qq-upload-spinner,
.qq-upload-size,
.qq-upload-cancel,
.qq-upload-retry,
.qq-upload-failed-text,
.qq-upload-delete,
.qq-upload-pause,
.qq-upload-continue {
  margin-right: 12px;
  display: inline;
}
.qq-upload-file {
  display: inline-block;
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  height: 18px;
  font-size: size(14);
}
.qq-upload-spinner {
  background: url(../images/fineuploader/three-dots.svg) no-repeat;
  display: inline-block;
  background-size: 30px 18px !important;
  height: 20px;
  width: 30px;
}
.qq-drop-processing {
  display: block;
}
.qq-drop-processing-spinner {
  background: url(../images/fineuploader/three-dots.svg) no-repeat;
  display: inline-block;
  background-size: 30px 18px !important;
  height: 20px;
  width: 30px;
}
.qq-upload-size,
.qq-upload-cancel,
.qq-upload-retry,
.qq-upload-delete,
.qq-upload-pause,
.qq-upload-continue {
  font-size: size(14);
  height: 18px;
  font-weight: normal;
  cursor: pointer;
}
.qq-upload-size {
  min-width: 60px;
}
.qq-upload-status-text {
  font-size: size(14);
  font-weight: bold;
  display: block;
}
.qq-upload-failed-text {
  display: none;
  font-style: italic;
  font-weight: bold;
}
.qq-upload-failed-icon {
  display: none;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
}
.qq-upload-fail .qq-upload-failed-text {
  display: inline;
}
.qq-upload-retrying .qq-upload-failed-text {
  display: inline;
}
.qq-upload-list li.qq-upload-success {
  &:before {
    font-family: 'Icomoon';
    content: $icon-circle-success;
    display: inline-block;
    margin-right: 10px;
    color: $green-haze;
  }
}
.qq-upload-list li.qq-upload-fail {
  &:before {
    font-family: 'Icomoon';
    content: $icon-circle-error;
    display: inline-block;
    margin-right: 10px;
    color: $amaranth;
  }
}
.qq-progress-bar {
  display: block;
  display: block;
  background: #00abc7;
  width: 0%;
  height: 15px;
  border-radius: 6px;
  margin-bottom: 3px;
}

.qq-total-progress-bar {
  height: 25px;
  border-radius: 9px;
}

.qq-total-progress-bar-container {
  margin-left: 9px;
  display: inline;
  float: right;
  width: 500px;
}

input.qq-edit-filename {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}

.qq-upload-file.qq-editable {
  cursor: pointer;
  margin-right: 4px;
}

.qq-edit-filename-icon.qq-editable {
  display: inline-block;
  cursor: pointer;
}

input.qq-edit-filename.qq-editing {
  position: static;
  height: 28px;
  padding: 0 8px;
  margin-right: 10px;
  margin-bottom: -5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: size(16);

  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

.qq-edit-filename-icon {
  display: none;
  background: url('../images/fineuploader/edit.gif');
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  margin-right: 16px;
}

.qq-hide {
  display: none !important;
}

/* Thumbnail
------------------------------------------ */
.qq-thumbnail-selector {
  vertical-align: middle;
  margin-right: 12px;
  display: none;
}

/* <dialog> element styles */
.qq-uploader dialog {
  display: none;
}

.qq-uploader dialog[open] {
  margin: 0 auto;
  padding: 20px;
  border: 0;
  border-radius: 5px;
  text-align: center;
}

.qq-uploader dialog {
  display: none;
}

.qq-uploader dialog[open] {
  display: block;
}

.qq-uploader dialog .qq-dialog-buttons {
  text-align: center;
  padding-top: 10px;
  display: inline-block;
}

.qq-uploader dialog .qq-dialog-buttons button {
  margin-left: 5px;
  margin-right: 5px;
}

.qq-uploader dialog .qq-dialog-message-selector {
  padding-bottom: 10px;
}

.qq-uploader dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
