.button {
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  display: block;
  font-size: size(16);
  outline: none;
  padding: 12px;
  display: inline-block;
  text-align: center;
  transition: color $default-transition-time,
    background-color $default-transition-time,
    border-color $default-transition-time;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.16),
      0px 0px 1px rgba(40, 41, 61, 0.04);
    text-decoration: none;
  }

  &:active {
    box-shadow: 0px 0.5px 2px rgba(96, 97, 112, 0.16),
      0px 0px 1px rgba(40, 41, 61, 0.08);
  }

  &.accept {
    background-color: $jade;
    color: $white;

    &:active {
      background-color: $green-haze;
    }
  }

  &.decline {
    background-color: $amaranth;
    color: $white;

    &:active {
      background-color: $carmine;
    }
  }

  &.main {
    background-color: $resolution-blue;
    color: $white;

    &:active {
      background-color: $big-stone;
    }

    &.bordered {
      background-color: $white;
      border-color: $resolution-blue;
      color: $resolution-blue;

      &:active {
        background-color: $resolution-blue;
        color: $white;
      }
    }

    &.dropdown {
      &.login {
        i {
          font-size: size(12, false);
          margin-left: 10px;
        }
      }
    }
  }

  &.additional {
    color: $amaranth;
    background-color: $white;

    &:active, &:hover, &:focus {
      background-color: $amaranth !important;
      color: $white !important;
    }
  }

  &.text {
    color: $amaranth;
    background-color: transparent;

    &:hover {
      box-shadow: none;
    }

    &.second {
      color: $cerulean;

      &.dropdown {
        color: $resolution-blue;
        font-size: size(18, false);
        font-weight: 600;
        line-height: 22px;

        i {
          font-size: size(14, false);
          margin-left: 10px;
        }
      }
    }
  }

  &.extra-small {
    padding: 7px;
    font-size: size(14);
  }

  &.disabled,
  &:disabled {
    background-color: $whisper;
    color: $manatee;

    &:hover,
    &:active {
      box-shadow: none;
    }
  }
}
