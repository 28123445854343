.omega-icons {
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
}

.omega-icon-facebook {
  background: url("../images/customicons/facebook.png") no-repeat;
}

.omega-icon-instagram {
  background: url("../images/customicons/instagram.png") no-repeat;
}

.omega-icon-twitter {
  background: url("../images/customicons/twitter.png") no-repeat;
}