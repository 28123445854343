.formbuilder {
    .invalid-feedback {
        margin-top: -0.75rem;
    }

    .buttonsend {
        visibility: inherit !important;
    }

    .custom-checkbox {
        min-height: 2.5em;
        display: flex;
        margin: 0.5em 0;
        align-items: center;
    }

    label {
        &.custom-control-label {
            min-height: 2.5em;
            display: flex;
            margin: 0.5em 0;
            align-items: center;

            &:before {
                border: 1px solid #005E80;
                padding: 20px;
                width: 1.35em;
                height: 1.35em;
                border-radius: 0.15em;
                transform: translateY(-0.075em);
                font-family: Icomoon;
                cursor: pointer;
                display: inline-block;
                font-size: 24px;
                content: "";
                margin-top: 11px;
            }
        }
    }

    input[type="checkbox"] {
        display: none;

        &:checked + label:before {
            content: "";
            background-color: #fff;
        }
    }
}

.require--asterisk {
    *:not([type="radio"]) ~ .required .checkbox_radio_label::before, label.required:first-child::before, legend.required::before {
        content: '*';
        color: red;
        margin-right: 5px;
    }
    .double-star+label .checkbox_radio_label:before{
        content: "**"!important;
    }
}

strong {
    font-weight: 600 !important;
}

select {
    .form-control {
        font-weight: 300;
    }
}

.dz-preview {
    float: left;
    margin: 10px;
}

.dropzone-container {
    .dz-default {
        .dz-button {
            outline: none;
            background-color: #fff;
            color: #e30613;
            border: 1px solid #e30613;
            width: 100%;
            max-width: 190px;
            border-radius: 5px;
            padding: 7px 14px;
            margin: 15px 0;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: #e30613;
                color: #fff;
            }

            //display: flex;
            //justify-content: center;
            //align-content: center;
            //background: #fff;
            //color: #e30613;
            //border: 1px solid #e30613;
            //height: 45px;
            //max-width: 190px;
            //width: 100%;
            //border-radius: 5px;
            //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2);
            //padding: 10px;
            //margin: 15px 0;
            //transition: all 0.3s ease-in-out;
            //
            //&:hover {
            //  background-color: #e30613;
            //  color: #fff;
            //}
        }
    }

    .dz-preview {
        font-weight: 400;
        border: 1px solid #003087;
        padding: 10px;
        border-radius: 5px;
        margin: 0;
        box-shadow: 0 12px 10px -15px #8f90a6;

        &:not(:last-child) {
            margin: 0 10px 10px 0;
        }

        .dz-success-mark {
            display: none;
        }

        .dz-error-mark {
            @extend .dz-success-mark;
        }

        .dz-filename {
            margin-bottom: 15px;
        }

        .dz-remove {
            background-color: #fff;
            color: #e30613;
            border: 1px solid #e30613;
            border-radius: 5px;
            padding: 3px 10px;
            transition: all 0.3s ease-in-out;
            text-decoration: none;

            &:hover {
                background-color: #e30613;
                color: #fff;
            }
        }
    }
}