.pimcore_wysiwyg {
  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    list-style: outside;
    padding-left: 1rem !important;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    border: 1px solid #000;
  }

  span {
    &.marker {
      background-color: rgba(255, 255, 0, 0.6);
    }
  }
}

.cke_dialog_background_cover {
  z-index: 100300 !important;
}
.cke_dialog_container {
  z-index: 100400 !important;
}

.pimcore_editable {
  &.pimcore_tag_checkbox {
    cursor: pointer;
    input[type="checkbox"] + label::before {
      top: unset;
      margin-right: 10px;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}