.choose-form-path {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  width: 100%;

  .form-path {
    display: none;
    width: 100%;
  }

  > input[type='radio'] {
    + label {
      font-size: 30px;
      margin-bottom: 30px;
      &::before {
        font-size: size(36, false);
        left: -10px;
      }
    }

    &:nth-of-type(2) {
      + label {
        margin-left: 40px;
      }
    }

    &#firstPath {
      &:checked {
        ~ .form-path.first {
          display: block;
        }
      }
    }

    &#secondPath {
      &:checked {
        ~ .form-path.second {
          display: block;
        }
      }
    }
  }
}
