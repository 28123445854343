.operator_navigation_container {
  .operator_navigation_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .operator_navigation-button {
      text-align: center;
      border: 1px solid #2a2e70;
      color: #013088 ;
      font-size: 20px;
      transition: all 0.3s ease-in-out;
      border-radius: 10px;
      cursor: pointer;
      padding: 5px 20px;
      max-width: 150px;
      width: 100%;
      font-weight: 300;
      background-color: #ffffff;

      &:hover,
      &.active {
        background-color: #013088 ;
        color: #ffffff;
      }

      &.active {
        transform: scale(1.1);
      }
    }

    .operator_navigation-line {
      background-color: #013088;
      height: 1px;
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      .operator_navigation-button {
        padding: 5px 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      .operator_navigation-line {
        display: none;
      }
    }

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      .operator_navigation-button {
        margin-right: 0 !important;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .operator_navigation_pages {
    .operator_navigation_page {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}