.lightbox {
  background-color: $black;
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0.2;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;

  &.active {
    display: block;
  }
}
