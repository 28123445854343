input[type='radio'] {
  display: none;

  + label {
    cursor: pointer;
    padding-left: 30px;
    position: relative;
    font-family: 'Signika' !important;

    &::before {
      font-size: size(24, false);
      content: $icon-radio-inactive;
      font-family: 'Icomoon';
      left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:checked {
    + label {
      &::before {
        color: $malibu;
        content: $icon-radio-active;
      }
    }
  }
}
