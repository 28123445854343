.accordion-shadow {
  margin: 30px auto;
  padding: 0 12px;
  font-family: inherit;
  max-width: 1212px;
  width: 100%;

  &,& > * {
    box-sizing: border-box;
  }

  .accordion_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .accordion_header-img {
      max-width: 90px;
      width: 100%;
      height: auto;
      margin-right: 30px;
    }

    .accordion_header-title {
      font-size: 2rem;
      color: #2a2e70;
    }
  }

  .accordion_elements {
    .accordion_element {
      &:not(:first-child) {
        margin-top: 20px;
      }

      .accordion_element-title-shadow {
        border-radius: 15px;
        border: 1px solid #005E80;
        background-color: #ffffff;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        font-size: 1.5rem;
        line-height: 1.2;
        color: #000000;
        padding: 15px;

        .title_icon {
          text-align: center;
          color: #005E80;
          margin-right: 10px;
          max-width: 20px;
          width: 100%;

          &.title_icon-minus {
            display: none;
          }
        }
      }

      .accordion_element-content {
        display: none;
        flex-direction: column;
        padding: 20px 0 0 45px;

        .content_link {
          display: flex;
          align-items: center;
          text-decoration: unset;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .content_link-img {
            max-width: 40px;
            width: 100%;
            height: auto;
            margin-right: 20px;
          }

          .content_link-paragraph {
            color: #4c4e51;
            font-size: 1.125rem;
            margin: 0;
          }
        }

        &.editable {
          display: flex;
        }
      }

      .accordion_element-input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked ~ .accordion_element-content {
          display: flex;
        }

        &:checked ~ .accordion_element-title-shadow {
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
          border: none;

          &:before {
            content: "";
            margin: 0;
          }

          .title_icon {
            &.title_icon-plus {
              display: none;
            }

            &.title_icon-minus {
              display: block;
            }
          }
        }

        & ~ .accordion_element-title-shadow {

          &:before {
            content: "";
            margin: 0;
          }

          .title_icon {
            &.title_icon-plus {
              display: block;
            }

            &.title_icon-minus {
              display: none;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .accordion_header {
      .accordion_header-img {
        max-width: 60px;
        margin-right: 20px;
      }

      .accordion_header-title {
        font-size: 1.75rem;
      }
    }

    .accordion_elements {
      .accordion_element {
        .accordion_element-title-shadow {
          font-size: 1.125rem;
        }

        .accordion_element-content {
          .content_link {
            .content_link-img {
              max-width: 30px;
            }

            .content_link-paragraph {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .accordion_header {
      .accordion_header-img {
        max-width: 50px;
        margin-right: 15px;
      }

      .accordion_header-title {
        font-size: 1.5rem;
      }
    }

    .accordion_elements {
      .accordion_element {

        &:not(:first-child) {
          margin-top: 10px;
        }

        .accordion_element-title-shadow {
          font-size: 1rem;
        }

        .accordion_element-content {
          .content_link {
            .content_link-img {
              max-width: 20px;
            }

            .content_link-paragraph {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
  &.editmode {
    .editmode-alert {
      font-size: 14px;
      font-weight: 700;
      color: #f03c28;
      margin-bottom: 5px;
    }
    .accordion_header {
      border: 2px solid #2a2e70;
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .editmode_element {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .editmode_element-label {
          font-weight: 700;
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
    }

    .accordion_elements {
      border: 2px solid #2a2e70;
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 10px;
      .accordion_element {
        border: 2px solid #9A94BC;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 10px;
        .accordion_element-content {
          .edtimode {
            border: 2px solid #8FB8DE;
            margin-bottom: 20px;
            padding: 10px;
            border-radius: 10px;

            .edtimode_element {
              &:not(:last-child) {
                margin-bottom: 20px;
              }

              .edtimode_element-label {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 14px;
              }
            }
          }
        }
        .edtimode_element-label {
          font-weight: 700;
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
    }
  }
}