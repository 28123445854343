@import 'reset';
@import 'fonts';
@import 'typography';
@import 'icons';
@import "omega-icons";
@import 'images';
@import 'omega-pages';
@import 'admin-panel';
@import 'pimcore-wysiwyg';
@import 'main_menu';

html,
body {
  height: 100%;
}

html {
  font-size: 100%;
}

body {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
  color: $base-font-color;
  font-size: size($base-font-size);
  display: flex;
  flex-flow: column;


  #content {
    flex: 1 1 auto;

    &,
    & > * {
      box-sizing: border-box;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  @include link();
}

.font-weight-bold {
  font-weight: bold;
}

.text-justify {
  text-align: justify;
}

.icon {
  margin-right: 10px;
  min-height: 45px;
  min-width: 45px;
  display: inline-block;
  background-size: contain !important;

  &_small {
    min-height: 20px;
    min-width: 20px;
  }

  &_big {
    min-height: 60px;
    min-width: 60px;
  }

  &.pdf-icon {
    // background: url("../../images/bok/ico-cursor.png") no-repeat;
    background: url("../images/pdf.png") no-repeat;
  }
}

.rounded-my {
  border: 2px solid #87d4f2;
  border-radius: 15px !important;
}
em{font-style: italic;}