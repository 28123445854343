#___gcse_0 > *:not(i) {
  font-family: 'Signika', sans-serif !important;
}

#___gcse_0 .gsc-control-cse {
  padding: 0 !important;
}

#___gcse_0 .gsc-search-box {
  max-width: 250px !important;
}

#___gcse_0 .gsc-search-box.gsc-search-box-tools {
  margin-bottom: 0 !important;
}

#___gcse_0 .gsc-input-box {
  border-radius: 8px;
}

#___gcse_0 button.gsc-search-button {
  border-radius: 8px;
  padding: 9px 27px;
}

#___gcse_0 .gsc-input-box {
  padding: 0;
  border-width: 1px;
}

@media only screen and (min-width: 940px) {
  #___gcse_0 .gsc-input-box {
    min-width: 150px;
  }
}

#___gcse_0 .gisb_a {
  padding: 5px 9px 8px 1px;
}

#___gcse_0 .gsc-results-wrapper-overlay {
  border-radius: 8px;
  border: 1px solid #003087;
}

#___gcse_0 .gsc-above-wrapper-area {
  border-bottom-color: #003087;
}

#___gcse_0 .gcsc-find-more-on-google {
  color: #003087;
}

#___gcse_0 .gsc-adBlock {
  display: none;
}

#___gcse_0 .gsc-search-box.gsc-search-box-tools {
  margin-left: auto !important;
}

#___gcse_0 .gsc-search-button.gsc-search-button-v2 {
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    background-color: #d82626;
    border-color: #d82626;
  }
}

#___gcse_0 .gs-webResult div.gs-visibleUrl {
  color: #003087;
}

#___gcse_0 .gs-title {
  font-weight: 600;
}

.login-dropdown {
  background-color: #d82626 !important;
  border: 1px solid #d82626 !important;
  font-size: 1rem;

  &:hover, &:focus {
    background-color: #ffffff !important;
    color: #d82626 !important;
  }
}

.dropdown-menu {
  &.active {
    .login-dropdown {
      color: #d82626 !important;
      background-color: #ffffff !important;
    }
  }
}

.font-size-base {
  font-size: 16px !important;
}

#dropdownMenuButton1 {
  border-radius: 8px !important;
}
