.flex-uwarunkowania-prawne  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.flex-uwarunkowania-prawne .teksty {
  max-width: 60%;
}

.flex-uwarunkowania-prawne .teksty p {
  margin-right: 3rem;
  text-align: justify;
  margin-bottom: 1rem;
}

.flex-uwarunkowania-prawne .obrazek {
  max-width: 40%;
  text-align: center;
}

.tytul {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  color: #003087;
  width:100%;
}

.flex-uwarunkowania-prawne ul {
  li {
    margin-bottom: 1.5rem;
    margin-right: 3rem;
    margin-left: 1rem;
    padding-left: 1.3rem;
    text-indent: -1.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .flex-uwarunkowania-prawne {
    flex-direction: column;

  }
  .flex-uwarunkowania-prawne .obrazek {
    display: none;
  }
  .flex-uwarunkowania-prawne .teksty p {
    margin: auto 1rem;
    margin-bottom: 1.5rem;
  }
  .flex-uwarunkowania-prawne .teksty {
    max-width: 100%;
  }
  .tytul {
    margin: auto 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .tytul {
    margin: auto 1rem;
  }
  .flex-uwarunkowania-prawne .teksty p {
    margin: auto 1rem;
    margin-bottom: 1.5rem;
  }
  .flex-uwarunkowania-prawne .pdf {
    margin: 0 1rem 1rem 1rem;
  }
  .flex-uwarunkowania-prawne .teksty {
    max-width: 100%;
  }
}