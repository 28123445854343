.operator_navigation_container .operator_navigation_pages .operator_navigation_page.active {
  margin-bottom: 3rem;
}

.procedure_change_vendor {
  &, & > * {
    box-sizing: border-box;
  }

  .pcv-title {
    font-size: 2.25rem;
    color: #013088;
    font-weight: 700;
    margin: 20px 0;
  }

  .pcv-text_gray {
    color: #6b6a71;
    font-size: 1rem;
    max-width: 850px;
  }

  .pcv-subtitle {
    font-size: 1.5rem;
    color: #1e1c27;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 2.5rem;
  }

  .pdf_container + .pcv-subtitle {
    margin-top: 4rem;
  }

  .pdf_container {
    margin-bottom: 20px;
    .pcv_pdf_link {
      display: flex;
      align-items: center;
      text-decoration: none;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .pcv_pdf-img {
        max-width: 40px;
        width: 100%;
        height: auto;
        margin-right: 20px;
      }

      .pcv_pdf-text {
        color: #4c4e51;
        font-size: 1.125rem;
        margin: 0;
        font-weight: 700;
      }

      @media only screen and (max-width: 768px) {
        .pcv_pdf-img {
          max-width: 30px;
          margin-right: 10px;
        }
        .pcv_pdf-text {
          font-size: 1rem;
        }
      }
    }
  }

  .text_container {
    margin-bottom: 20px;
    width: 100%;
    font-size: 1.125rem;
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    border-color: #2a2e70 !important;
    border-width: 1px;
  }

  th {
    font-weight: 700;
  }

  th,
  td {
    padding: 10px;
  }
}








