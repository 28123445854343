#widget-7 {
  @include box-shadow(4);
  border-radius: 1rem;
  overflow: hidden;
  @include above(md) {
    height: 384px;
  }

  .video-wrapper {
    &:not(.splide) {
      .splide__list {
        display: block;
      }
    }
    .splide__track {
      @include above(md) {
        height: 384px;
      }
    }
    .splide__pagination {
      width: auto;
    }
  }

  .video-item {
    position: relative;
    padding-bottom: 51.6%;
    padding-top: 25px;
    height: 0;
    background-size: cover;
    background-repeat: no-repeat;

    @include above(md) {
      height: 384px;
      padding: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
