.splide {
  &:hover {
    .splide__arrow {
      display: block;
    }
  }
}

.splide__pagination {
  bottom: 0.9em;
  .splide__pagination__page {
    background-color: rgba($white, 0.5);
    margin: 2px;
    &.is-active {
      transform: none;
      background-color: $white;
      opacity: 1;
    }
  }
}
.splide__arrow {
  display: none;
  background-color: transparent;
  svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
}

.splide__slide {
  .slide-desktop,
  .slide-mobile {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: inherit;
  }
  .slide-desktop {
    display: block;
    @include below(md) {
      display: none;
    }
  }
  .slide-mobile {
    display: block;
    @include above(md) {
      display: none;
    }
  }
  &:focus {
    outline: none;
  }
}
.carousel-item{
    .slide-desktop,
    .slide-mobile {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: inherit;
    }
}
.carousel-caption.slider-content{
    text-align: left;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: fit-content;
    left: 0;
    right: 0;
}
@media (min-width: 769px) {
    .carousel-item .slide-mobile {
        display: none;
    }
}
@media (max-width: 768px) {
    .carousel-item .slide-desktop{
        display: none;
    }
}
.carousel-control-next, .carousel-control-prev{
    width: 5%;
}
