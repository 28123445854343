.ribbon {
  border-radius: 8px;
  padding: 4px 10px;
  display: inline-block;
  color: $white;
  text-transform: uppercase;
  font-size: 12px;
  &.blue {
    background-color: $resolution-blue;
  }
}
