.enea-slider {
    .carousel-indicators [data-bs-target] {
        width: 14px;
        height: 14px;
        &:hover {
            background-color: #fff;
            opacity: 1;
        }
    }

    .carousel-caption {
        margin: auto;
        width: 90%;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
    }
}
