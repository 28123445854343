$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xlg: 1024px,
  xxlg: 1060px,
  xxxlg: 1160px,
  xl: 1200px,
  1160: 1160px,
  1060: 1060px,
) !default;

$grid-columns: 12 !default;
$grid-gutter: 12px !default;

// font weights
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// colors
$white: #fff;
$black: #111;
$resolution-blue: #003087;
$malibu: #669cff;
$big-stone: #182845;
$cerulean: #005E80;
$whisper: #fafafc;
$ghost: #c7c9d9;
$ebony-clay: #28293d;
$steel-gray: #1c1c28;
$grey-bg: #e5e5e5;
$alto: #d0d0d0;
$manatee: #8f90a6;
$carmine: #a60516;
$alizarin-crimson: #af1222;
$amaranth: #e93d4e;
$green-haze: #05a660;
$jade: #06c270;
$shamrock: #39d98a;
$dusty-gray: #979797;
$arc-light: #ccdeff;
$comet: #555777;
$transparent: transparent;
$eneaFontColor: #4c4e51;

// font base
$base-font-size: 16;
$base-line-height: 1.5;
$base-font-weight: $light;
$base-font-family: 'Signika', sans-serif;
$base-font-color: $eneaFontColor;

// links
$link-color: $cerulean;
$link-decoration: underline;

//transitions
$default-transition-time: 0.34s;

//Loader
$loaderDotsNumber: 12;
