#widget-2 {
  height: auto;
  display: flex;
  flex-direction: column;
  @include box-shadow(4);
  border-radius: 16px;
  @include above(md) {
    height: 504px;
  }
  .text-part {
    min-height: 288px;
    display: grid;
    position: relative;
    background-color: $resolution-blue;
    padding: 16px 24px 24px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    @include above(md) {
      padding: 16px 30px 30px 30px;
    }
    @include above(lg) {
      padding: 16px 40px 40px 40px;
    }
    .list-title {
      color: $white;
      font-size: size(32, false);
      @include below(lg) {
        font-size: size(24);
      }
    }
    .icon {
      position: absolute;
      top: calc(50% - 20px);
      right: 42px;
      font-size: size(90, false);
      color: $white;
      @include below(xl) {
        display: none;
      }
    }
    .list {
      display: inline-grid;
      align-items: center;
      margin-top: 0;
      @include above(md) {
        margin-top: 18px;
      }
      .list-item {
        position: relative;
        height: 100%;
        min-height: 38px;
        font-size: size(16, false);
        @include below(md) {
          min-height: 56px;
          font-size: size(16);
        }
        .list-link,
        .list-text {
          @include link($white);
          @include link-underline();
          white-space: nowrap;
          font-weight: $regular;
          display: inline-block;
          align-items: center;
          @include below(md) {
            &:hover {
              background-size: 0;
            }
            display: flex;
            width: 100%;
            height: 100%;
          }
          .list-icon,
          .list-arrow {
            font-size: size(16, false);
            margin-right: 12px;
          }
          .list-arrow {
            transition: right $default-transition-time;
            position: absolute;
            right: 0;
            display: block;
            @include above(md) {
              display: none;
            }
          }
          &:hover {
            text-decoration: none;
            .list-arrow {
              right: 10px;
            }
          }
        }
        @include below(md) {
          &:not(:last-child) {
            &:after {
              content: '';
              position: absolute;
              background-color: $arc-light;
              width: 100%;
              height: 1px;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
  .banner-part {
    overflow: hidden;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    img {
      width: auto;
      height: 100%;
    }
    @include below(md) {
      img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }
  }
}

.contrast {
  #widget-2 {
    background-color: $ebony-clay;
    color: $white;
    .text-part {
      .list {
        .list-item {
          @include below(md) {
            &:not(:last-child) {
              &:after {
                content: '';
                background-color: $black;
              }
            }
          }
        }
      }
    }
  }
}
