footer {
  height: auto;
  background-color: $whisper;
  @include above(md) {
    min-height: 284px;
  }

  .footer-primary,
  .footer-secondary {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    @include clearfix;
    @include above(xl) {
      max-width: 1200px + $grid-gutter;
    }
    ul {
      list-style: none!important;
    }
  }

  .footer-primary {
    flex-direction: row;
    padding-top: 40px;
    margin-bottom: 12px;
    position: relative;
    min-height: 123px;
    border-bottom: 1px solid $ghost;
    @include above(md) {
      padding-bottom: 40px;
      margin-bottom: 0;
    }

    .left-content {
      position: relative;
      justify-content: flex-end;
      @include above(md) {
        &:after {
          content: '';
          position: absolute;
          background-color: $ghost;
          width: 1px;
          height: 100%;
          bottom: 0;
          right: 0;
        }
      }

      .footer-menu {
        list-style: inside;
        padding-left: 12px;

        .footer-menu-column {
          margin: 0;
          padding: 0;
          line-height: 1.2;

          .footer-menu-row {
            padding-left: 0;
            padding-bottom: 12px;
            line-height: 1.4;
            @include above(sm) {
              &:last-child {
                padding-bottom: 0;
              }
            }

            .footer-menu-link {
              font-size: size(16);
              @include link-underline();
            }
          }
        }
      }
    }

    .right-content {
      .footer-contact {
        justify-content: flex-end;
        padding-top: 12px;

        .footer-contact-address {
          justify-content: flex-end;

          .address-title {
            padding-bottom: 12px;
          }

          .address-row {
            display: block;

            &:not(:last-child) {
              padding-bottom: 12px;
            }
          }

          @include below(sm) {
            .address-row {
              &:last-child {
                padding-bottom: 20px;
              }
            }
          }
        }

        .footer-contact-phone {
          @include above(sm) {
            padding-top: 40px;
          }

          .phone-row,
          .fax-row {
            font-weight: $regular;
            position: relative;
            padding-bottom: 12px;
            color: $alizarin-crimson;

            a {
              @extend .phone-row
            }

            i {
              color: $steel-gray;
              padding-right: 20px;
              @include above(md) {
                padding-right: 0;
                position: absolute;
                left: -30px;
                top: 2px;
              }
            }
          }
        }

        @include above(md) {
          padding-top: 0;
        }
      }
    }
  }

  .footer-secondary {
    height: auto;
    flex-direction: column-reverse;
    @include above(md) {
      height: 80px;
      flex-direction: row;
    }

    .left-content {
      justify-content: center;
      color: $alto;
      font-size: size(10);
      padding-top: 12px;
      padding-bottom: 12px;
      @include above(md) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .right-content {
      justify-content: center;

      .footer-language,
      .footer-external-services {
        display: flex;
        align-items: center;
        flex-direction: row;
        position: relative;
      }

      .footer-language {
        i {
          padding-right: 6px;
          font-size: size(20);
        }

        &:hover {
          text-decoration: none;
        }

        @include above(md) {
          &:after {
            content: '';
            position: absolute;
            background-color: $ghost;
            width: 1px;
            height: 100%;
            bottom: 0;
            right: 0;
          }
        }
      }

      .footer-external-services {
        padding-top: 10px;
        @include above(md) {
          justify-content: flex-end;
        }
        @include above(sm) {
          padding-top: 0;
        }

        .footer-bip {
          text-decoration: none;
          padding: 0 12px;

          i {
            font-size: size(25);
            color: $amaranth;
          }
        }

        .accesibility-list {
//           position: relative;
//           top: -2px;
//           padding-right: 12px;
        }

        .footer-youtube {
          text-decoration: none;
          padding-left: 32px;
          position: relative;

          i {
            font-size: size(26);
            color: $carmine;
          }

          &:before {
            content: '';
            position: absolute;
            background-color: $ghost;
            width: 1px;
            height: 24px;
            bottom: 0;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}

.operator-footer{
    align-items: inherit;
    justify-content: space-between;

    .operator-footer-linki{
        align-items: end;
    }
    .my-footer-contact-details{
        flex-direction: row!important;
        align-items: center;
        justify-content: end;
    }
}
@media(max-width: 991px) {
    .operator-footer .my-footer-contact-details{
        justify-content: start;
    }
    .operator-footer-linki{
        align-items: center;
    }
}
@media(min-width: 768px) {
    .footer-lines {
        border-left: 2px solid #c7c9d9;
    }
}

        .footer-menu-link > a {
            color: #003087;
        }

        .footer-menu-link > a:hover {
            color: #45AED7;
            text-decoration: underline;
        }


        .footer-copyright {
            font-size: 12px;
            color: #545454;
        }

        .fax-row > a {
            color: $alizarin-crimson;
            font-weight: 400;
        }

        .phone-row > a {
            color: $alizarin-crimson;
            font-weight: 400;
        }

        .footer-bg-color-btn {
            background-color: #C7C9D9;
        }

        .footer-icon-color {
            color: #555777;
        }



        .icon-youtube, .icon-bip {
            font-size: 1.5625rem;
            background-color: transparent;
        }

.contrast {
  footer {
    color: white;

    a {
      color: white;
    }

    .footer-primary {
      border-bottom-color: $black;

      .left-content {
        &:after {
          background-color: $black;
        }
      }

      .right-content {
        .footer-contact {
          .footer-contact-phone {
            .phone-row,
            .fax-row {
              i {
                color: $white;
              }
            }
          }
        }
      }
    }

    .footer-secondary {
      .left-content {
        color: $white;
      }

      .right-content {
        .footer-language {
          &:after {
            content: '';
            background-color: $black;
          }
        }

        .footer-external-services {
          .footer-youtube {
            &:before {
              background-color: $black;
            }
          }
        }
      }
    }
  }
}
