.accesibility-list {
//   display: flex;
//   height: 24px;
//   margin-left: 50px;
//   max-width: 204px;
//   width: 100%;

  .item {
    align-items: center;
    background-color: $ghost;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    transition: transform $default-transition-time;
    width: 24px;

    @include above(md) {
      &:hover {
        transform: translateY(-4px);
      }
    }

    i {
      color: $comet;
      font-size: size(16, false);
    }

    & + .item {
      margin-left: 12px;
    }
  }
}
