.article {
  color: $resolution-blue;
  &-box {
    background-color: $grey-bg;
    border: none;
    border-radius: unset;
    .article-list-img {
      width: 100%;
    }
  }
  &-title, &-date {
    margin: 16px;
  }
  &-title {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 90px;
  }
  .next-prev {
    a {
      font-weight: bold;
    }
  }
}

.title-bottom-img-article {
    color: #4e4e4e;
}
@media (max-width: 767px) and (min-width: 577px) {
    article .img-in-bok-article{
        width: 50% !important;
    }
}
@media only screen and (max-width: 576px) {
    article .img-in-bok-article{
        width: 100% !important;
    }
}