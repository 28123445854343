@import "global";
.my-breadcrumbs{
    color: $light-blue;
    .active-trail{
        color: $white-text;
    }
    a{
        color: $white-text!important;
        &:visited {
            color: $white-text!important;
        }
    }
}
.my-menu-navbar{
    .nav-link{
        color: $white-text!important;
    }
    .general-main-menu{
        background-image: none!important;
        background-color: $dark-contrast;
        .my-menu-item{
            a{
                color: $light-blue;
                &:visited {
                    color: $light-blue;
                }
            }
        }
        .icon{
            color: $light-blue;
        }
    }

}
.top-nav-bar{
    color: $white-text!important;
}
.gsc-control-cse{
    border-color: $contrast;
    background-color: $contrast;
}
.dropdown{
    .dropdown-menu{
        background-color: $dark-contrast;
    }
}
