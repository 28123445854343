.clearfix {
  @include clearfix;
}
.ta-right {
  text-align: right;
}
.ta-left {
  text-align: left;
}
.ta-center {
  text-align: center;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}
.hidden {
  display: none;
}
