.changing_force {
  &,
  & > * {
    box-sizing: border-box;
  }

  .changing_force_header {
    margin-bottom: 25px;

    .changing_force_header-title {
      color: #013088;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 15px;

      @media only screen and (max-width: 768px) {
        font-size: 28px;
      }
    }

    .changing_force_header-text {
      color: #6b6a71;
      font-size: 16px;
      line-height: 1.4;
      font-weight: 300;
    }
  }

  .accordion_section {
    margin-bottom: 50px;

    .accordion_element {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 15px 20px;
      border: 2px solid #87d4f2;
      border-radius: 15px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .icon_element {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .plus {
          display: block;
        }

        .minus {
          display: none;
        }
      }

      .title_element {
        font-size: 1.5rem;
        line-height: 1.2;
      }

      &.active {
        border-color: #f5f5f5;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

        .icon_element {
          .plus {
            display: none;
          }

          .minus {
            display: block;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        padding: 6px 10px;
        border-radius: 8px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .icon_element {
          img {
            max-width: 30px;
            max-height: 30px;
          }
        }

        .title_element {
          font-size: 1.25rem;
        }
      }

      @media only screen and (max-width: 500px) {
        padding: 5px;
        .title_element {
          font-size: 1rem;
        }
      }
    }

    .accordion_container {
      margin-left: 73px;
      margin-bottom: 20px;
      display: none;
      color: #343434;
      font-size: 1.25rem;
      line-height: 1.4;
      font-weight: 400;

      &.active {
        display: block;
      }

      @media only screen and (max-width: 768px) {
        margin-left: 53px;
        font-size: 1rem;
        margin-bottom: 12px;
      }

      @media only screen and (max-width: 500px) {
        margin-left: 48px;
        font-size: 0.85rem;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  .changing_force_footer {

    .changing_force_footer-title {
      font-size: 24px;
      font-weight: 400;
      color: #013087;
      margin-bottom: 30px;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    .changing_force_footer-list {
      margin-left: 70px;
      margin-bottom: 30px;

      .changing_force_footer-list_element {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .list_element-img {
          width: 30px;
          height: 30px;
          margin-right: 40px;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }

        .list_element-text {
          color: #013087;
          font-size: 24px;
          font-weight: 300;
        }
      }

      @media only screen and (max-width: 768px) {
        margin-left: 20px;
        margin-bottom: 15px;

        .changing_force_footer-list_element {
          .list_element-img {
            margin-right: 20px;
          }

          .list_element-text {
            font-size: 20px;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        margin-left: 0;

        .changing_force_footer-list_element {
          .list_element-img {
            margin-right: 15px;
          }

          .list_element-text {
            font-size: 20px;
          }
        }
      }
    }

    .changing_force_footer-link {
      margin-left: 70px;
      max-width: fit-content;
      border-bottom: 2px solid transparent;
      transition: all 0.3s ease-in-out;

      .link_element {
        color: #10a7e3;
        font-size: 26px;
        font-weight: 400;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        border-bottom-color: #013087;

        .link_element {
          color: #013087;
        }
      }

      @media only screen and (max-width: 768px) {
        margin-left: 20px;
        .link_element {
          font-size: 22px;
        }
      }

      @media only screen and (max-width: 500px) {
        margin-left: 0;
      }
    }
  }
}

