.card {
  &-category {
    &-img {
      padding-right:285px;
    }
    &-body {
      color: $resolution-blue;
      .text-hidden {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &-1 {
          -webkit-line-clamp: 1;
        }
        &-2 {
          -webkit-line-clamp: 2;
        }
        &-4 {
          -webkit-line-clamp: 4;
        }
      }
    }
    &-link, &-link:visited {
      font-weight: $semi-bold;
      color: $link-color;
    }
  }
}