.files_to_download {
  &,
  & > * {
    box-sizing: border-box;
  }

  .accordion_section {
    .accordion_section-title {
      color: #013088;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 25px;

      @media only screen and (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 15px;
      }
    }

    .accordion_elements {
      .accordion_element {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 15px 20px;
        border: 1px solid #87d4f2;
        border-radius: 15px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .icon_element {
          display: flex;
          align-items: center;
          margin-right: 10px;

          .plus {
            display: block;
          }

          .minus {
            display: none;
          }
        }

        .title_element {
          font-size: 24px;
          line-height: 1.2;
          font-weight: 700;
        }

        &.active {
          border-color: #f5f5f5;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
          margin-bottom: 40px;

          .icon_element {
            .plus {
              display: none;
            }

            .minus {
              display: block;
            }
          }
        }

        @media only screen and (max-width: 768px) {
          padding: 6px 10px;
          border-radius: 8px;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          .icon_element {
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }

          .title_element {
            font-size: 20px;
          }
        }

        @media only screen and (max-width: 500px) {
          padding: 5px;
          .title_element {
            font-size: 16px;
          }
        }
      }

      .accordion_container {
        margin-left: 43px;
        margin-bottom: 40px;
        display: none;
        color: #343434;
        font-size: 20px;
        line-height: 1.4;

        &.active {
          display: block;
        }

        .files_section {
          margin-bottom: 25px;

          .files_section-title {
            color: #1e1c27;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 15px;

            @media only screen and (max-width: 768px) {
              font-size: 20px;
              margin-left: 0;
            }

            @media only screen and (max-width: 500px) {
              font-size: 16px;
            }
          }

          .files_section-links {
            .files_section-link {
              max-width: fit-content;
              display: flex;
              align-items: center;


              &:not(:last-child) {
                margin-bottom: 8px;
              }

              .files_section-link-img {
                width: 30px;
                height: auto;
                margin-right: 15px;
              }

              .files_section-link-title {
                color: #4c4e51;
                font-size: 20px;
                font-weight: 400;
                transition: all 0.3s ease-in-out;
                border-bottom: 2px solid #ffffff;
              }

              &:hover {
                .files_section-link-title {
                  color: #00a3e0;
                  border-bottom: 2px solid #00a3e0;
                }
              }
            }

            @media only screen and (max-width: 768px) {
              .files_section-link {

                .files_section-link-img {
                  width: 20px;
                  margin-right: 10px;
                }

                .files_section-link-title {
                  font-size: 16px;
                }
              }
            }
          }

          @media only screen and (max-width: 768px) {
            margin-bottom: 15px;
          }
        }

        @media only screen and (max-width: 768px) {
          margin-left: 25px;
          font-size: 16px;
          margin-bottom: 20px;
        }

        @media only screen and (max-width: 500px) {
          font-size: 13.6px;
        }
      }
    }
  }
}