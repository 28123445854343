@import "global";
.list-link{
    color: $dark-text!important;
}
.slider-title,.slider-content{
    p{
        color: $white-text!important;
        span{
            color: $white-text!important;
        }

    }
    font{
        color: $white-text!important;
    }
    h1,h2,h3,h4{
        color: $white-text!important;
        span{
            color: $white-text!important;
        }
    }
}
.dark-text{
    .slider-title,.slider-content{
        p{
            color: $dark-contrast-blue!important;
            span{
                color: $dark-contrast-blue!important;
            }

        }
        font{
            color: $dark-contrast-blue!important;
        }
        h1,h2,h3,h4{
            color: $dark-contrast-blue!important;
            span{
                color: $dark-contrast-blue!important;
            }
        }
    }
}
.order-1{
    .widget-1{
        h2{
            span{
                color: $white-text!important;
            }
        }
    }
}
