$light-text: #fbff00;
$dark-text: #003087;
$white-text: #fff;
$contrast: #000000;
$dark-contrast: #191919;
$dark-contrast-blue: #28293d;
$dark-contrast-hover: #585858;
$light-blue: #005E80;
$dark-blue: #003087;
$white-color: #ffffff;

header{
    &.shadow {
        box-shadow: 0 12px 10px -15px white !important;
    }
    .headerlogo{
        .image{
            display: none;
            &.contrast{
                display: block;
            }
        }
    }
}
.img-contrast{
    img{
        filter: invert(100%) grayscale(1);
    }
}
footer{
    background-color: $dark-contrast;
}
h1,h2,h3,h4{
    color: $white-text!important;
}
p{
    color: $white-text!important;
    a{
        color: $light-blue;
        &:visited {
            color: $light-blue;
        }
    }
    u{
        a{
            color: $light-blue;
            &:visited {
                color: $light-blue;
            }
        }
    }
}
a{
    color: $light-blue;
    &:visited {
        color: $light-blue;
    }
    &.download_files_link {
        color: $light-blue;
        span p{
            color: $light-blue!important;
        }
    }
    span{
        color: $light-blue!important;
    }
}
.footer-menu-link{
    color: $white-text!important;
}
span{
    color: $white-text!important;
    &.form-error-message{
        color: red!important;
    }
}
ul,ol{
    li{
        color: $white-text!important;
        a{
            color: $light-blue!important;
        }
    }
}
.description-bg-light, .description-bg-blue{
    background-color: $dark-contrast;
}
.cookies {
    background-color: $dark-contrast!important;
}
.success-checkmark {
    &:before {
        filter: invert(100%) grayscale(1);
    }
}
.btn-red{
    a{
        color: $white-text;
        &:visited {
            color: $white-text;
        }
    }
}