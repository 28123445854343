.success-checkmark {
  position: relative;
  margin: 10px 10px 15px;

  & ul {
    list-style-type: none;
    padding-left: 0 !important;
  }

  &:before {
    content: url("../images/ptaszek.png");
    position: absolute;
    display: inline-block;
    left: 0;
    top: -5px;
  }
}

.formbuilder input[type="checkbox"]:checked + label::before {
  content: "";
  background: url("../images/icons/checkmark.svg") center center no-repeat;
  background-size: cover;
}

.big-circle {
  position: relative;
  margin: 7px 10px;
  padding-bottom: 10px;
}

.big-circle:before {
  content: '';
  text-align: center;
  padding: 3px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: #000;
  left: 0;
  top: 8px;
}