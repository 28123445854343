.national_investments {
  margin: 40px auto;
  &, & > * {
    box-sizing: border-box;
  }

  .national_investments-title {
    font-size: 2.25rem;
    color: #2a2e70;
    font-weight: 700;
  }

  .national_investments_header {
    margin: 20px auto;
    max-width: 1100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .national_investments_header-img {
      max-width: 350px;
      height: auto;
    }
    .national_investments_header-title {
      color: #09603d;
      font-size: 5rem;
      font-weight: 300;
    }

    @media only screen and (max-width: 1100px) {
      .national_investments_header-img {
        max-width: 200px;
      }
      .national_investments_header-title {
        font-size: 3.125rem;
      }
    }

    @media only screen and (max-width: 768px) {
      .national_investments_header-img {
        max-width: 150px;
      }
      .national_investments_header-title {
        font-size: 2.25rem;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      .national_investments_header-title {
        text-align: center;
      }
    }
  }

  .national_investments-text {
    font-size: 1.125rem;
  }
}