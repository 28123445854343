a:hover {
  color: #005E80;
}
a:hover span {
  color: #005E80 !important;
}
.form-control {
  border: 1px solid #005E80;
  margin-bottom: 15px;
    min-height: 50px;
}

.pimcore_area_entry .pimcore_area_edit_button, .pimcore_tag_area .pimcore_area_edit_button {
  z-index: 1000;
}
.title-download 
{
  h2, h3, h4{
      color: #005E80 !important;
  }
}


// optymalizacja
.navyText {
  color: #32b3e4;
}

.btn-navy {
  color: #fff !important;
  background-color: #32b3e4;
}

.subsidiary-block {
  box-shadow: -1px 1px 1px rgba(0, 0, 0 , 0.1);
  border-radius: 10px;
  padding-bottom: 1.7rem;

  &:hover {
    box-shadow: -4px 5px 6px rgba(0, 0, 0 , 0.15);
  }
}
@media (min-width: 1200px) {
  .ps-xl-6 {
    margin-left: 2.5rem !important;
  }
  .ps-xl-7 {
    margin-left: 3rem !important;
  }
  .ps-xl-8 {
    margin-left: 3.5rem !important;
  }
  .ps-xl-9 {
    margin-left: 4rem !important;
  }
  .ps-xl-10 {
    margin-left: 4.5rem !important;
  }
}

@media (min-width: 576px) {
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 2.5rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 3.5rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 4.5rem !important;
  }
  .ms-md-11 {
    margin-left: 5rem !important;
  }
  .ms-md-12 {
    margin-left: 5.5rem !important;
  }
  .ms-md-13 {
    margin-left: 6rem !important;
  }
  .ms-md-14 {
    margin-left: 6.5rem !important;
  }
  .ms-md-15 {
    margin-left: 7rem !important;
  }
}

.mapdescription {
  line-height: 1.5;
  width: 530px;
  height: 320px;
  padding-top: 40px;
}

.description-bg-blue{
  background-color: #14a3e6;
}
.description-bg-light{
  background-color: #eaebf0;
}
.only-simple-img{
    .img-simple-desktop{ margin: 0 }
    .img-simple-mobile{ margin: 0 }
    picture{
        padding: 0;
        margin-bottom: -7px;
        img{ width: inherit; height: auto; }
    }
}
.only-simple-img.border-top-left-radius img{ border-top-left-radius: 20px; }
.only-simple-img.border-bottom-left-radius img{ border-bottom-left-radius: 20px; }
.only-simple-img.border-top-right-radius img{ border-top-right-radius: 20px; }
.only-simple-img.border-bottom-right-radius img{ border-bottom-right-radius: 20px; }
.my-slider {
    background-size: cover;
    background-repeat: no-repeat;
}

.rounded-4 {
  border-radius: .4rem !important;
}
.rounded-5 {
  border-radius: .5rem !important;
}
.rounded-6 {
  border-radius: .6rem !important;
}
.rounded-7 {
  border-radius: .7rem !important;
}
.rounded-8 {
  border-radius: .8rem !important;
}
.rounded-9 {
  border-radius: .9rem !important;
}
.rounded-10 {
  border-radius: 1rem !important;
}
.get-css{
    display:none;
}