#widget-5 {
  background-color: $white;
  height: auto;
  overflow: hidden;
  @include box-shadow(4);
  border-radius: 16px;
  @include above(md) {
    height: 504px;
  }

  .list-title {
    padding: 16px 24px 0 24px;
    color: $resolution-blue;
    font-size: size(32, false);
    @include above(md) {
      padding: 16px 40px 0 40px;
    }
    @include below(lg) {
      font-size: size(24);
    }
  }
  .list {
    display: inline-grid;
    height: 100%;
    width: 100%;
    align-items: center;
    @include above(md) {
      max-height: 454px;
    }
    .list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 56px;
      height: 100%;
      .list-link {
        width: 100%;
        height: 100%;
        padding: 0 56px 6px 24px;
        display: flex;
        align-items: center;
        min-height: inherit;
        &:hover {
          .list-arrow {
            right: 26px;
          }
        }

        .list-img {
          display: none;
        }
        .list-text,
        .list-arrow {
          display: block;
        }
        .list-text {
          @include link($steel-gray);
          white-space: nowrap;
          font-weight: $regular;
        }
        .list-icon,
        .list-arrow {
          font-size: size(16, false);
          margin-right: 12px;
        }
        .list-arrow {
          transition: right $default-transition-time;
          position: absolute;
          right: 16px;
          display: block;
          @include above(md) {
            display: none;
          }
        }
        @include above(md) {
          .list-img,
          .list-arrow {
            display: block;
          }
          .list-text {
            display: none;
          }
        }
      }
      .list-text,
      .list-arrow {
        display: none;
      }
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          background-color: $arc-light;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

.contrast {
  #widget-5 {
    background-color: $ebony-clay;
    color: $white;
    h2 {
      color: $white;
    }
    .list {
      .list-item {
        &:not(:last-child) {
          &:after {
            background-color: $black;
          }
        }
        .list-link {
          .list-text {
            @include link($white);
          }
          .list-arrow {
            color: $white;
          }
        }
      }
    }
  }
}
