.bok-gmap {
  .bok__search-panel {
    display: flex;

    .bok__search-input {
      width: 30%;
      margin-right: 30px;
      position: relative;

      @media all and (max-width: 1280px) {
        width: calc(50% - 50px);
        margin-right: 10px;
      }

      @media all and (max-width: 600px) {
        width: calc(100% - 150px);
      }
    }

    input.search {
      width: 100%;
      padding: 5px;
      padding-right: 36px;
      height: 50px;
    }

    .bok__search-button {
      position: absolute;
      content: "";
      // @include bok(icon-magnifying-glass); // /static/ev2/images/bok/icon-magnifying-glass.png
      // width: bok-width(icon-magnifying-glass);
      // height: bok-height(icon-magnifying-glass);
      // @include bok(icon-magnifying-glass); // /static/ev2/images/bok/icon-magnifying-glass.png
      // width: bok-width(icon-magnifying-glass);
      // height: bok-height(icon-magnifying-glass);
      top: 14px;
      right: 14px;
      border: 0px;
      text-indent: -9999px;

      &:hover {
        // @include bok(icon-magnifying-glass-active);
        // width: bok-width(icon-magnifying-glass-active);
        // height: bok-height(icon-magnifying-glass-active);
      }

      &:after {
        // @include bok(icon-magnifying-glass);
        display: none;
      }
    }

    .button {
      max-width: 240px;

      border: 2px solid #e9283c;
      color: #e9283c;
      text-align: center;
      padding: 0 20px 0 20px;
      height: 50px;
      line-height: 46px;
      display: block;
      text-decoration: none;
      border-radius: 6px;
      position: relative;
      font-weight: 600;
      margin-bottom: 8px;
      cursor: pointer;
      background-color: transparent;

      @media all and (max-width: 1280px) {
        min-width: 90px;
      }

      &:hover {
        background-color: #d82626;
        color: #fff !important;

        &:after {
          width: 6px;
          height: 12px;
          margin-left: 14px;
          display: inline-block;
        }

        i {
          // filter: brightness(0) invert(1);
          background-position: 0 0;
          background-image: url("../images/bok/ico-locate-white.png");
        }
      }

      &#localization-button {
        margin-right: 10px;

        @media all and (max-width: 1280px) {
          padding: 0;
          max-width: 50px;
          min-width: 50px;
        }

        &:hover {
          background-color: #d82626;
          color: #fff !important;

          span {
            color: #fff !important;
          }
        }
      }

      span {
        padding-left: 10px;

        @media all and (max-width: 1280px) {
          display: none;
        }
      }
    }
  }

  .panel-outer-div {
    display: none;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #d4d7da;
    }

    &.visible {
      display: block;
      z-index: 3;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 290px;
      background: white;
      padding: 2px;
      padding-bottom: 42px;

      @media all and (max-width: 800px) {
        width: calc(100% + 40px);
        background: none;
        top: 10%;
        padding: 0;
        margin: 0 -20px;
      }

      @media all and (max-width: 600px) {
        top: 7%;
      }

      .panel-div {
        font-size: 0.8em;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        @media all and (max-width: 800px) {
          overflow-y: hidden;
        }

        .bok-location {
          min-height: 400px;
          width: 100%;
          font-size: 12px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media all and (max-width: 800px) {
            margin: 10px;
            height: 400px;
            background: white;
          }

          @media all and (max-width: 600px) {
            min-height: 200px;
            margin: 10px 2px;
          }

          > div {
            flex-grow: 1;

            @media all and (max-width: 800px) {
              flex-grow: 0;
              display: flex;
              flex-wrap: wrap;
            }
          }

          &:after {
            content: "";
            width: 100%;
            margin: 0 auto;
            height: 1px;
            left: 0;
            display: block;
            clear: both;
            background-color: #a6aaad;

            @media all and (max-width: 800px) {
              display: none;
            }
          }

          &:last-of-type {
            &:after {
              display: none;
            }
          }

          a {
            font-weight: 800;
          }

          i {
            margin-right: 10px;
            width: 20px;
          }

          p {
            margin: 10px 0;
            font-weight: 600;

            @media all and (max-width: 800px) {
              width: 50%;
            }

            @media all and (max-width: 600px) {
              width: 100%;
              margin: 3px 0;
            }

            &:empty {
              margin: 0;
            }

            &.bok-name {
              font-size: 14px;
              margin: 0;
              font-weight: 600;
              width: 100%;
            }

            &.bok-city {
              font-size: 20px;
              margin-top: 0;
              font-weight: 600;
              width: 100%;
            }
          }

          .bok-location-item {
            padding: 10px 0 10px 30px;

            @media all and (max-width: 800px) {
              width: 50%;
            }

            @media all and (max-width: 600px) {
              width: 100%;
              padding: 3px 0 3px 30px;
            }

            p {
              margin: 0 0 0 -30px;
              width: 100%;
            }

            a {
              color: #359cde;
            }
          }

          .button {
            height: 36px;
            line-height: 32px;
            margin-bottom: 4px;

            @media all and (max-width: 800px) {
              width: 200px;
              margin: 0 auto;
            }
          }
        }

        .icon {
          margin-right: 10px;
          min-height: 20px;
          min-width: 20px;
          display: inline-block;

          &.cursor {
            background: url("../images/bok/ico-cursor.png") no-repeat;
          }

          &.client-service-small {
            background: url("../images/bok/ico-client-service-small.png") no-repeat;
          }

          &.stars-small {
            background: url("../images/bok/ico-stars-small.png") no-repeat;
          }

          &.parking-small {
            background: url("../images/bok/ico-parking-small.png") no-repeat;
          }

          &.clock-blue-small {
            background: url("../images/bok/ico-clock-blue-small.png") no-repeat;
          }

          &.payments-small {
            background: url("../images/bok/ico-payments-small.png") no-repeat;
          }

          &.open-star-small {
            background: url("../images/bok/ico-open-star-small.png") no-repeat;
          }

          &.teddy-small {
            background: url("../images/bok/ico-teddy-small.png") no-repeat;
          }

          &.map-localisation-marker {
            background: url("../images/bok/map-localisation-marker.png") no-repeat;

            &:hover {
              background: url("../images/bok/map-localisation-marker-hover.png") no-repeat;
            }
          }

          &.map-marker {
            background: url("../images/bok/map-marker.png") no-repeat;

            &:hover {
              background: url("../images/bok/map-marker-hover.png") no-repeat;
            }
          }

          &.map-bok-marker {
            background: url("../images/bok/map-bok-marker.png") no-repeat;

            &:hover {
              background: url("../images/bok/map-bok-marker-hover.png") no-repeat;
            }
          }

          &.map-bok-marker-new {
            background: url("../images/bok/map-bok-marker-new.png") no-repeat;

            &:hover {
              background: url("../images/bok/map-bok-marker-new-hover.png") no-repeat;
            }
          }

          &.map-region-marker-new {
            background: url("../images/bok/map-region-marker.png") no-repeat;

            &:hover {
              background: url("../images/bok/map-region-marker-hover.png") no-repeat;
            }
          }


          // 'weekends': '<i class="icon open-star-small align-middle"></i>',
          // 'kids_place': '<i class="icon teddy-small align-middle"></i>',

        }
      }

      .panel-div-close {
        width: 100%;
        background: #f9fafc;
        position: absolute;
        bottom: 0;
        padding: 10px;
        text-align: center;
        left: 0;

        @media all and (max-width: 800px) {
          bottom: auto;
          top: -30px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          right: 51px;
          left: auto;
          background: white;

          &:hover {
            color: red;
            text-decoration: none;
          }
        }

        @media all and (max-width: 600px) {
          right: 31px;
        }

        .close-ico {
          display: none;

          @media all and (max-width: 800px) {
            display: block;
            position: absolute;
            top: 6px;
            left: 9px;
          }
        }

        span:not(.close-ico) {
          &:after {
            content: "";
            display: inline-block;
            // @include arrows-sprite(
            //     arrow-bottom-blue
            // );
            // width: arrows-sprite-width(
            //     arrow-bottom-blue
            // );
            // height: arrows-sprite-height(
            //     arrow-bottom-blue
            // );
            margin-left: 15px;
          }

          @media all and (max-width: 800px) {
            display: none;
          }
        }
      }
    }

    &.bok-list-hidden {
      height: 0;
      padding: 0;

      .panel-div-close {
        bottom: -640px;

        @media all and (max-width: 800px) {
          display: none;
        }
      }
    }
  }
}
