.report_indicators_interruptions {
  .operator_navigation_container {
    .operator_navigation_nav {
      margin-bottom: 50px;

      .operator_navigation-button {
        font-size: 22px;
        max-width: 200px;
        line-height: 1.2;
        min-height: 60px;
        min-width: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media only screen and (max-width: 1200px) {
        .operator_navigation-line {
          display: none;
        }

        .operator_navigation-button {
          min-width: unset;
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        .operator_navigation-button {
          max-width: 300px;
          width: 100%;
          margin-right: 0;
          font-size: 20px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      @media only screen and (max-width: 400px) {
        .operator_navigation-button {
          max-width: 250px;
        }
      }
    }

    .operator_navigation_pages {
      .operator_navigation_page {
        .files_to_download {
          border-bottom: 1px solid #d6d6d7;
          padding-bottom: 30px;
          margin-bottom: 30px;

          .files_to_download-title {
            color: #4c4e51;
            font-size: 28px;
            margin-bottom: 20px;
            font-weight: 400;
          }

          .files_to_download-link_pdf {
            display: block;
            color: #10a9e5;
            font-size: 20px;
            margin-left: 50px;
            transition: 0.3s all ease-in-out;

            &:hover {
              color: #013087;
              text-decoration: underline;
            }
          }

          &.remove_border {
            border-bottom: none;
          }

          @media only screen and (max-width: 768px) {
            .files_to_download-title {
              font-size: 22px;
            }

            .files_to_download-link_pdf {
              font-size: 18px;
              margin-left: 25px;
            }
          }

          @media only screen and (max-width: 400px) {
            .files_to_download-link_pdf {
              margin-left: 0;
            }
          }
        }

        .content_page {
          .content_page-title {
            font-size: 29px;
            color: #4c4e51;
            margin-bottom: 20px;
          }

          .content_page-text {
            font-weight: 300;
            font-size: 23px;
            color: #4c4e51;
            margin-left: 50px;
          }

          @media only screen and (max-width: 768px) {
            .content_page-title {
              font-size: 24px;
            }

            .content_page-text {
              font-size: 18px;
              margin-left: 25px;
            }
          }

          @media only screen and (max-width: 400px) {
            .content_page-text {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

