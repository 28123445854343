.letter-spacing {
  letter-spacing: 2px;
}
.btn-red{
  background-color: #d82626;
  border-radius: .5rem;
  color:white;
}
.btn-red a, .btn-red a {
  color:white;
}