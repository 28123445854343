.retailer_list {

  &,
  & > * {
    box-sizing: border-box;
  }

  .retailer_list_header {
    display: flex;
    align-items: center;
    margin: 20px 0 30px;
    .retailer_list_header-img {
      max-width: 90px;
      width: 100%;
      height: auto;
      margin-right: 40px;
    }

    .retailer_list_header-text {
      font-size: 2.25rem;
      line-height: 1.3;
      color: #013088;
      font-weight: 700;
    }

    @media only screen and (max-width: 768px) {
      .retailer_list_header-img {
        max-width: 80px;
        margin-right: 20px;
      }

      .retailer_list_header-text {
        font-size: 1.75rem;
      }
    }

    @media only screen and (max-width: 500px) {
      .retailer_list_header-img {
        max-width: 60px;
        margin-right: 10px;
      }

      .retailer_list_header-text {
        font-size: 1.25rem;
      }
    }
  }

  .operator_navigation_page {
    .onp-title {
      font-size: 2.25rem;
      line-height: 1.3;
      color: #013088;
      font-weight: 400;
      margin-bottom: 15px;
    }

    .list {
      margin-bottom: 40px;
      .list_element {
        .list_element_title {
          font-size: 1.5rem;
          line-height: 1.3;
          color: #3d3d3d;
          font-weight: 600;
          margin-bottom: 25px;
        }

        .cards {
          .card {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
            .card-img {
              max-width: 30px;
              width: 100%;
              margin-right: 40px;
            }

            .card_body {
              .card_body-title {
                font-size: 1.5rem;
                line-height: 1.3;
                color: #3d3d3d;
                font-weight: 600;
                margin-bottom: 15px;
              }

              .card_body-subtitle {
                font-size: 1.5rem;
                line-height: 1.3;
                color: #3d3d3d;
                font-weight: 300;
                margin-bottom: 20px;
              }

              .card_body-text {
                font-size: 1.125rem;
                line-height: 1.3;
                color: #3d3d3d;
                font-weight: 300;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .onp-title {
        font-size: 1.75rem;
      }
    }

    @media only screen and (max-width: 500px) {
      .onp-title {
        font-size: 1.25rem;
      }
    }
  }

  .operator_navigation_container {
    .operator_navigation_nav {
      margin-bottom: 40px;
    }
  }
}