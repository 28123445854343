.top-bar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;

  @include below(md) {
    display: none;
  }
}
