.my-menu-navbar{
    justify-content: space-around;
    width: 100%;
    li.my-nav-item.nav-item a.my-nav-link{
        color: #003087;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-decoration: none;
    }
    .my-nav-link.show, .my-nav-link:hover {
        color: #005E80!important;
    }
}
.level-first::after {
    border-bottom: 0;
    border-left: 0em solid transparent;
    border-right: 0 solid transparent;

}

.general-main-menu{
    .menu-item {
        white-space: unset;
    }
    a.menu-item{
        text-decoration: none;
        width: auto;
        height: fit-content;
    }
    .title-main-menu .my-menu-item a,
    .headline .submenu .link span {
        background-image: linear-gradient(currentColor,currentColor);
        background-position: 0 95%;
        background-repeat: no-repeat;
        background-size: 0 1px;
        transition: background-size .3s cubic-bezier(0,.5,0,1);
    }
    .title-main-menu .my-menu-item a:hover,
    .headline .submenu .link span:hover{
        text-decoration: none;
        color: #005E80!important;
        background-size: 100% 1px;
    }
    .my-menu{
        min-height: inherit;
        align-items: stretch;

        li.my-menu-item{
            display: initial;
            a:hover, a:visited {
                text-decoration: none;
            }
        }
        .title-main-menu{
            color: #003087;
            .my-menu-item {
                color: #003087;
                font-size: 18px;
                background-color: transparent;
            }
            .icon{
                margin-right: 0;
                min-width: 30px;
            }
            ul{
                display: -webkit-box;
                -webkit-box-align: center;
            }
        }
    }



    .submenu{

        .menu-item{
            font-weight: 300 ;
        }
        .menu-item:hover{
            background-color: transparent;
        }
        .menu-item:before{
            content:"▪";
            display:inline-block;
            margin-right:8px;
        }
        .dropdown-item.active, .dropdown-item:active {
            background-color: transparent;
            color: unset;
            text-decoration: none;
        }
        .link {
            display: block;
            text-indent: -0.5em;
        }

    }

}



.general-main-menu .submenu .dropdown-item.active,.general-main-menu .submenu .dropdown-item:active{
    background-color: transparent;
    color: unset;
    text-decoration: none;
}
.general-main-menu .submenu
.general-main-menu a.menu-item{
    text-decoration: none;
    width: auto;
    height: fit-content;
}