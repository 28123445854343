$navybluecolor: #003087;
$lightbluecolor: #87d4f2;
$secondlightbluecolor: #005E80;
$grey: #797b7f;

.form-search-jobs-container {
	#form-search-jobs {
		position: relative;

		.form-group {
			&.greyArea {
				background-color: #E4E5E5;
				color: #fff;
				input.form-control {
					background-color: #fff;
				}
			}
		}



		#form-search-jobs-results,
		#form-search-job-offers-not-found,
		#form-search-reset {
			display: none;

			&.visible {
				display: grid;
			}
		}

		// prosta wyszukiwarka
		&.simple {
			#form-search-jobs-results {
				&.visible {
					display: none !important;
					#form-search-jobs-results-sorting {
						display: none !important;
					}
				}
			}
		}
	}

	#form-search-loader {
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid #3498db;
		width: 120px;
		height: 120px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
		position: absolute;
		top: 35%;
		left: 45%;
		z-index: 111;
		display: none;
		&.visible {
			display: block;
		}
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	#form-search-loader-bg {
		-webkit-filter: blur(4px);
		filter: blur(5px);
		position: absolute;
		width: 101%;
		height: 102%;
		z-index: 100;
		background: rgba(170, 180, 195, 0.55);
		left: -5px;
		top: -5px;
		left: -5px;
		top: -5px;
		display: none;
		&.visible {
			display: block;
		}
	}


	.job-offer-wrapper {
		margin: 30px auto;
		padding: 0 12px;
		font-family: inherit;
		max-width: 1212px;
		width: 100%;

		.job-offer-icon {
			background: url("../images/oferta-pracy-icon.png") no-repeat;
            width: 100px;
			height: 100px;
		}

		.job-offer-item {
			background-color: unset;
			border: 0;
			// border-bottom: 1px solid $secondlightbluecolor;
			position: relative;
			border: 2px solid #87d4f2;
			box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0);
			border-radius: 15px !important;
			margin-bottom: 1rem;

			.job-offer-info {
				position: absolute;
				z-index: 11111;
				right: 49px;
				top: -15px;
				background-color: white;
				padding: 0 5px;
			}

			.job-offer-title {
				// text-indent: 2rem;
				font-size: 1.8rem;
				font-weight: 300;
				line-height: 2rem;
				color: #4c4e51;

				.job-item-button {
					margin-bottom: 1rem;
					border: none;
					width: 100%;
					background-color: transparent;
					text-align: left;

					&::before {
						content: false;
						display: none;
					}
				}
			}

			.job-offer-title::after,
			.job-offer-title::before,
			.job-offer-title.collapsed::after,
			.job-offer-title.collapsed::before {
				content: false;
				display: block;
			}

			.job-offer-date {
				font-weight: bold;
				margin-left: 10px;
				color: #66C8EE;
			}
		}

		.job-offer-simple-item {
			background-color: unset;
			border: 0;
			// border-bottom: 1px solid $secondlightbluecolor;
			position: relative;
			border-bottom: 1px solid #87d4f2;
			box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0);
			margin-bottom: 1rem;

			.job-offer-info {

				background-color: white;
				padding: 0 5px;
			}

			.job-offer-title {
				// text-indent: 2rem;
				font-size: 1.8rem;
				font-weight: 300;
				line-height: 2rem;
				color: #4c4e51;

				.job-item-button {
					margin-bottom: 1rem;
					border: none;
					width: 100%;
					background-color: transparent;
					text-align: left;

					&::before {
						content: false;
						display: none;
					}
				}
			}

			.job-offer-title::after,
			.job-offer-title::before,
			.job-offer-title.collapsed::after,
			.job-offer-title.collapsed::before {
				content: false;
				display: none;
			}



			.job-offer-date {
				font-weight: bold;
				margin-left: 10px;
				color: #66C8EE;
			}
		}
	}
}

.greyArea .form-control  {
	border:0px;
}
