.popup {
  border-radius: 8px;
  display: none;
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 900px;
  text-align: center;
  color: $white;
  background-color: $resolution-blue;
  z-index: 9999;
  @include box-shadow(3);
  &.active {
    display: flex;
  }
  &-img {
    width: 100%;
    height: auto;
  }

  &-text {
    padding: 40px 30px 30px 30px;
    em {
      font-style: italic;
    }
    strong {
      font-weight: $semi-bold;
    }
    a {
      @include link($white);
      @include link-underline();
    }
  }

  &-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: size(10);
    text-align: center;
    span {
      display: none;
    }
    &:before,
    &:after {
      position: absolute;
      content: ' ';
      height: 16px;
      width: 2px;
      background-color: $white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
