.editmode_container {
  border: 2px solid #2a2e70;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;

  &.option-second {
    border-color: #9A94BC;
  }

  &.option-third {
    border-color: #8FB8DE;
  }

  .editmode_element {
    margin-bottom: 20px;
    &.editmode_element-margin {
      margin-bottom: 0;
    }
    .editmode_element-label {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}
