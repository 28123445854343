.cardsWithRoundedImgBlock {
  &,
  & > * {
    box-sizing: border-box;
  }
  .pimcore_block_entry {
    max-width: 370px;
    width: 100%;
    margin-right: 1rem !important;
  }
}