.grid-container {
  display: grid;
  grid-template-columns:
    minmax(200px, 384px)
    minmax(200px, 384px)
    minmax(200px, 384px);
  grid-template-rows: 340px 288px 192px 446px;
  gap: ($grid-gutter * 2) ($grid-gutter * 2);
  grid-template-areas:
    'widget-placeholder-1 widget-placeholder-1 widget-placeholder-1'
    'widget-placeholder-2 widget-placeholder-3 widget-placeholder-5'
    'widget-placeholder-2 widget-placeholder-4 widget-placeholder-5'
    'widget-placeholder-6 widget-placeholder-7 widget-placeholder-7';

  @include below(md) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto !important;
    grid-template-areas:
      'widget-placeholder-1 widget-placeholder-1 widget-placeholder-1'
      'widget-placeholder-2 widget-placeholder-2 widget-placeholder-2'
      'widget-placeholder-3 widget-placeholder-3 widget-placeholder-3'
      'widget-placeholder-4 widget-placeholder-4 widget-placeholder-4'
      'widget-placeholder-5 widget-placeholder-5 widget-placeholder-5'
      'widget-placeholder-6 widget-placeholder-6 widget-placeholder-6'
      'widget-placeholder-7 widget-placeholder-7 widget-placeholder-7';
  }
}

.widget-placeholder-2 {
  grid-area: widget-placeholder-2;
}

.widget-placeholder-3 {
  grid-area: widget-placeholder-3;
}

.widget-placeholder-4 {
  grid-area: widget-placeholder-4;
}

.widget-placeholder-5 {
  grid-area: widget-placeholder-5;
}

.widget-placeholder-6 {
  grid-area: widget-placeholder-6;
}

.widget-placeholder-7 {
  grid-area: widget-placeholder-7;
}

.widget-placeholder-1 {
  grid-area: widget-placeholder-1;
}
