.publication__attachment {
  &-apla {
    display: none;
  }
  &-img {
    flex-grow:1;
    display:flex;
    justify-content:center;
  }
  &-icon-wrapper{
    position:absolute;
    z-index:1;
    top:calc(50% - 80px / 2);
    opacity:0;
    transition:opacity .3s;

    a:hover{
      text-decoration:underline;
      color:#fff;
    }
  }
}

.svg-icon {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  outline-color: rgba(0,0,0,.1);
  position: relative;
}

@media print,screen and (min-width:64em){
  .publication__attachment-apla{
    display:inherit;
    z-index:1;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:rgba(42,46,112,.8);
    opacity:0;
    transition:opacity .3s
  }
  .publication__attachment-wrapper:hover .publication__attachment-apla,
  .publication__attachment-wrapper:hover .publication__attachment-icon-wrapper {
    opacity:1
  }
}

.white-fill {
  fill: #fff;
}

@media screen and (max-width: 64em) {
  .show-for-large {
    display: none !important;
  }
}