.top-phones-list {
  display: flex;
  height: 24px;
  max-width: 640px;
  white-space: nowrap;
  width: 100%;
  margin-right: 2.5rem;

  .item {
    &:first-of-type {
      margin-left: auto;
    }

    align-items: center;
    color: $steel-gray;
    display: flex;
    font-size: size(14);
    font-weight: 600;

    & + .item {
      margin-left: 40px;
    }

    .link {
      margin-left: 4px;
    }

    i {
      margin-right: 15px;
    }
  }
}

.contrast {
  .top-phones-list {
    .item {
      color: $white;
    }
  }
}
