.instrukcja-wspolpracy-ruchowej {

  margin-left: auto;
  margin-right: auto;

  .editable {
    margin: 10px;
    border: 1px solid blue;
    padding: 10px;

    &:focus {
      background-color: burlywood;
    }
  }

  .editable-img {
    width: 100px;
    height: 100px;
    margin: 10px;
    border: 1px solid blue;
  }

  .flex-instrukcja-wspolpracy-ruchowej {
    .omega-header-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      color: #003087;
      font-family: "Signika";
    }

    .container {
      &__image {
        width: 398px;
        margin: 0 30px 30px 30px;
        height: 368px;
        float: right;
      }

      &__text {
        display: inline;
      }
    }

    .pdf {
      display: inline-flex;
      font-size: large;
      align-items: center;
      margin-top: 1rem;

      font-size: 24px;
      line-height: 23px;
      color: #161521;
      font-weight: 400;
      font-family: "Signika";

      a.downloadPDF {
        background-color: #ff0000;
        border-radius: 10px;
        padding: 10px 20px;
        text-align: center;
        border: 1px solid #ff0000;
        color: white;
        flex-wrap: wrap;
        margin: 1rem;
        white-space: nowrap;
        margin: 2rem 4rem;
        font-size: 24px;
        line-height: 23px;
        color: #ffffff;
        font-weight: 300;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .flex-instrukcja-wspolpracy-ruchowej {
      flex-direction: column;
      text-align: center;

      .container {
        &__text {
          p {
            margin: 0.5rem .75rem;
          }
        }

        &__image {
          width: 95%;
          margin: 0.75rem auto;
          float: none;

          img {
            width: 95%;
            float: none;
          }
        }
      }

      .pdf {
        margin: 0 1rem 1rem 1rem;

        a.downloadPDF {
          margin: 2rem 0.75rem;
        }
      }

    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1300px) {
    .flex-instrukcja-wspolpracy-ruchowej {
      .omega-header-title {
        margin: 0 1rem 1rem 1rem;
      }

      .container {
        &__text {
          p {
            margin: 0.75rem 1rem;
          }
        }
      }

      .pdf {
        margin: 0 1rem 1rem 1rem;

        a.downloadPDF {
          margin: 2rem 2.5rem;
        }
      }
    }
  }
}