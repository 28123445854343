.side-menu {
  @include box-shadow(4);
  border-radius: 8px;
  top: 16px;
  overflow: hidden;

  @include above(md) {
    position: sticky;
  }

  .side-menu-item {
    padding: 12px 24px;

    > .side-menu-back {
      padding: 0;

      @include above(md) {
        display: none;
      }
    }
  }

  .side-menu-link {
    transition: all $default-transition-time;
    display: block;

    @include below(md) {
      margin-left: 32px;
    }

    &:hover,
    &.active {
      font-weight: $semi-bold;
      color: $link-color;
    }

    &.hide {
      display: none;
    }
  }

  .side-menu-show {
    display: none;
    color: $cerulean;
    text-align: center;
    cursor: pointer;
    padding: 12px 0;
    transition: all $default-transition-time;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include below(md) {
      background-color: $cerulean;
      color: $white;
      margin-top: 16px;
    }

    &:hover {
      padding: 16px 0 8px;
    }

    &.active {
      display: block;
    }

    &.opened {
      i {
        transform: rotate(180deg);
      }
    }

    i {
      display: block;
    }
  }

  .side-menu-back,
  .side-menu-more {
    display: flex;
    align-items: center;
    font-weight: $semi-bold;
    color: $link-color;
    padding: 24px;

    &:hover {
      i {
        margin-left: 0;
        margin-right: 16px;
      }
    }

    i {
      transition: margin $default-transition-time;
      display: inline-flex;
      transform: rotate(90deg) scale(0.8);
      margin: 0 8px;
    }
  }

  .side-menu-more {
    color: $white;
    background-color: $cerulean;

    &:hover {
      i {
        margin-right: 0;
        margin-left: 16px;
      }
    }

    i {
      transform: rotate(-90deg) scale(0.8);
    }
  }
}