.video_area_block {
  .pimcore_editable {
    &.pimcore_tag_block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .pimcore_block_entry {
        width: 49%;
      }
    }
  }

  .pimcore_tag_video {
    iframe {
      @media only screen and (min-width: 576px) {
        border-radius: 10px;
      }
    }
  }
    .my-video {
        margin-bottom: -6px;
    }
}